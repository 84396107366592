import { createContext, useCallback, useContext, useState } from 'react'

// Models
import { OrdersFacetsV2 } from '@obeta/schema'
import { OrderForListPage } from '@obeta/models/lib/schema-models/order-list'
import { DEFAULT_ORDER_LIST_QUERY_PARAMS } from '@obeta/models/lib/models/Orders'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'

const initialEmptyFunction = () => {
  //
}

interface OrderListContextValue {
  facets: OrdersFacetsV2 | null
  isLoading: boolean
  orders: OrderForListPage[]
  queryParams: QueryParams
  search: string
  totalOrderCount: number
  resetOrders: () => void
  setFacets: (facets: OrdersFacetsV2 | null) => void
  setIsLoading: (isLoading: boolean) => void
  setOrders: (orders: OrderForListPage[]) => void
  setQueryParams: (queryParams: QueryParams) => void
  setSearch: (search: string) => void
  setTotalOrderCount: (totalOrderCount: number) => void
}

const OrderListContext = createContext<OrderListContextValue>({
  facets: null,
  isLoading: true,
  orders: [],
  queryParams: DEFAULT_ORDER_LIST_QUERY_PARAMS,
  search: '',
  totalOrderCount: 0,
  resetOrders: initialEmptyFunction,
  setFacets: initialEmptyFunction,
  setIsLoading: initialEmptyFunction,
  setOrders: initialEmptyFunction,
  setQueryParams: initialEmptyFunction,
  setSearch: initialEmptyFunction,
  setTotalOrderCount: initialEmptyFunction,
})

export const OrderListProvider = ({ children }) => {
  const [facets, setFacets] = useState<OrdersFacetsV2 | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [orders, setOrders] = useState<OrderForListPage[]>([])
  const [queryParams, setQueryParams] = useState<QueryParams>(DEFAULT_ORDER_LIST_QUERY_PARAMS)
  const [search, setSearch] = useState<string>('')
  const [totalOrderCount, setTotalOrderCount] = useState<number>(0)

  /**
   * Reset order list state on nav section menu button click.
   */
  const resetOrders = useCallback(() => {
    setOrders([])
    setQueryParams(DEFAULT_ORDER_LIST_QUERY_PARAMS)
  }, [])

  return (
    <OrderListContext.Provider
      value={{
        facets,
        isLoading,
        orders,
        queryParams,
        search,
        totalOrderCount,
        resetOrders,
        setFacets,
        setIsLoading,
        setOrders,
        setQueryParams,
        setSearch,
        setTotalOrderCount,
      }}
    >
      {children}
    </OrderListContext.Provider>
  )
}

export const useOrderListContext = () => {
  return useContext(OrderListContext)
}
