import { useState, useEffect, useCallback, useRef } from 'react'
import { useRxDB } from 'rxdb-hooks'
import { Customer, Permissions } from '@obeta/models/lib/models/CustomerData'
import React from 'react'
import { Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { useAppActions } from './useAppActions'
import { RxLocalDocumentData } from 'rxdb/dist/types/types/plugins/local-documents'

export interface CustomerMetaData {
  isFetching: boolean
  mustRefetch: boolean
  isLoggedIn: boolean
  lastUpdated: number | null
  companyId: string | null
  userId?: string | null
}

type Nullable<T> = T | null

export enum AvailablePermissions {
  changeAdditionalText = 'changeAdditionalText',
  changeAddresses = 'changeAddresses',
  changeCommission = 'changeCommission',
  changeDeliveryAddress = 'changeDeliveryAddress',
  commissionIsObligatory = 'commissionIsObligatory',
  showAccountInfo = 'showAccountInfo',
  showPendingItems = 'showPendingItems',
  orderShoppingCart = 'orderShoppingCart',
  showCatalogPrices = 'showCatalogPrices',
  showListenPrices = 'showListenPrices',
  showPurchasePrices = 'showPurchasePrices',
  showOffers = 'showOffers',
  showOrders = 'showOrders',
  showProjects = 'showProjects',
  showSelectedProject = 'showSelectedProject',
  useMyProject = 'useMyProject',
  notifyShoppingCart = 'notifyShoppingCart',
}

export interface UserData {
  permissions?: Permissions
  user?: Nullable<Customer>
  isLoggedIn: boolean
  companyId?: string | null
  userId?: string | null
  hasPermission: (perm: AvailablePermissions) => boolean
  singleCartMode: boolean
  tokens: Nullable<Tokens>
  allTokensExist: boolean
  metaDataReady: boolean
}

export const UserContext = React.createContext<UserData>({
  isLoggedIn: false,
  hasPermission: (perm: string) => false,
  singleCartMode: false,
  tokens: null,
  allTokensExist: false,
  metaDataReady: false,
})

export const useUserData = (): UserData => {
  const db = useRxDB()
  const [latestRevCustomerMetaData, setLatestRevCustomerMetaData] =
    useState<Nullable<CustomerMetaData>>(null)
  const [tokens, setTokens] = useState<Nullable<Tokens>>(null)
  const [documentsFetched, setDocumentsFetched] = useState(false)
  const [latestRevCustomer, setLatestRevCustomer] = useState<Nullable<Customer>>(null)
  const latestRevCustomerPermissionsRef = useRef<Nullable<Permissions>>(null)
  const isComponentUnmounting = useRef(false)

  const appActions = useAppActions()

  const hasPermission = useCallback((perm: AvailablePermissions) => {
    if (!latestRevCustomerPermissionsRef.current?.subUser) {
      return false
    }

    return latestRevCustomerPermissionsRef.current?.subUser.indexOf(perm) !== -1
  }, [])

  useEffect(() => {
    const usermetaSubscription = db.getLocal$<CustomerMetaData>('usermeta').subscribe((doc) => {
      const data: RxLocalDocumentData<CustomerMetaData> | undefined = doc?.toMutableJSON()
      if (data) {
        setLatestRevCustomerMetaData(data.data)
      }
    })

    const userSubscription = db.getLocal$<Customer>('user').subscribe((doc) => {
      const data: RxLocalDocumentData<Customer> | undefined = doc?.toMutableJSON()
      if (data) {
        latestRevCustomerPermissionsRef.current = data.data?.general?.permissions || null
        setLatestRevCustomer(data.data)
      }
    })

    setDocumentsFetched(true)
    return () => {
      usermetaSubscription.unsubscribe()
      userSubscription.unsubscribe()
    }
  }, [db])

  useEffect(() => {
    appActions.tokens$.subscribe((tkns) => {
      // When logging out, we update appActions, triggering a rerender and unmount.
      // So, to prevent updating the state during unmount and avoid memory leaks, we check it here
      if (isComponentUnmounting.current) return
      setTokens(tkns)
    })
  }, [appActions.tokens$])

  useEffect(() => {
    isComponentUnmounting.current = false

    return () => {
      isComponentUnmounting.current = true
    }
  }, [])

  const isMainUser = latestRevCustomer?.general?.isSubUser !== true
  const permissions = latestRevCustomer?.general?.permissions

  const canShowProjects = permissions?.subUser.indexOf(AvailablePermissions.showProjects) !== -1
  const hasPreselectedProject =
    permissions?.subUser.indexOf(AvailablePermissions.showSelectedProject) !== -1
  const hasOnlyOwnProject = permissions?.subUser.indexOf(AvailablePermissions.useMyProject) !== -1

  const singleShoppingcartMode =
    !isMainUser && (hasPreselectedProject || hasOnlyOwnProject || !canShowProjects)

  /**
   * @deprecated a user can have a preselected project or missing the permission canShowProjects... both will lead
   * to having only one cart available thus enabling singleCartMode
   */
  if (singleShoppingcartMode) {
    if (!hasPreselectedProject) {
      //ToDo what are we supposed to do here?
      // eslint-disable-next-line no-console
      console.info('unknown combination of user rights')
    }
  }

  const allTokensExist =
    typeof tokens?.accessToken === 'string' && typeof tokens?.refreshToken === 'string'

  // for the migration it is important to check, that we either are a guest user or
  // already have tokens
  // when coming from 2.2.1 and installing 2.3.0 isLoggedIn will be true, although we don't
  // have tokens due to the introduction of the new login system, so we need the additional
  // check to log the user out
  const isLoggedIn = (allTokensExist && latestRevCustomerMetaData?.isLoggedIn) || false

  /**
   *
   */
  return {
    user: latestRevCustomer,
    companyId: latestRevCustomerMetaData?.companyId,
    userId: latestRevCustomerMetaData?.userId,
    permissions: latestRevCustomer?.general?.permissions,
    isLoggedIn,
    singleCartMode: singleShoppingcartMode,
    hasPermission,
    tokens,
    allTokensExist,
    metaDataReady: documentsFetched,
  }
}
