import {
  ShoppingCart,
  SimpleShoppingCart,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { CollectionsOfDatabase, RxCollection, RxDatabase, RxDocument } from 'rxdb'

interface GetCartReturnType {
  cart: RxDocument<ShoppingCart> | RxDocument<SimpleShoppingCart> | null
  collection: RxCollection
}

export const getCartDocument = async (
  db: RxDatabase<CollectionsOfDatabase>,
  id: string
): Promise<GetCartReturnType> => {
  const carts: RxCollection<ShoppingCart> = db.carts
  const simpleCarts: RxCollection<ShoppingCart> = db.simplecarts

  let cart = await carts.findOne({ selector: { id } }).exec()
  if (cart) {
    return { cart, collection: carts }
  }

  cart = await simpleCarts.findOne({ selector: { id } }).exec()
  return {
    cart,
    collection: simpleCarts,
  }
}

export const isSimpleCart = (
  cartDoc: RxDocument<ShoppingCart> | RxDocument<SimpleShoppingCart>
): cartDoc is RxDocument<SimpleShoppingCart> => {
  return cartDoc.collection.name === 'simplecarts'
}
