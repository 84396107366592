import React from 'react'
import styles from './CheckoutEditAddressOverlay.module.scss'
import { useForm, Controller } from 'react-hook-form'
import {
  Grid,
  Box,
  SvgIcon,
  Typography,
  TextField,
  Modal,
  Divider,
  useTheme,
  IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { Confirm } from '../alert-and-confirm/Confirm'
import { ConfirmWithUndo } from '../alert-and-confirm/ConfirmWithUndo'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { checkSplittAddress, splittAddress } from '@obeta/utils/lib/splitt-address'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  showEditAddressOverlay: boolean
  setShowEditAddressOverlay: (i: boolean) => void
  addressData: UserAddressV2
  createUserAddress: (address: AddressV2, customName: string) => void
  updateUserAddress: (addressId: string, address: AddressV2, customName: string) => void
}

export const CheckoutEditAddressOverlay: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { mobile, tabletAll, desktop } = useBreakpoints()

  const theme = useTheme()
  const {
    showEditAddressOverlay,
    setShowEditAddressOverlay,
    addressData,
    createUserAddress,
    updateUserAddress,
  } = props
  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      customName: addressData.customName,
      name1: addressData.address.name1,
      name2: addressData.address.name2,
      address: addressData.address.street + ' ' + addressData.address.houseNumber,
      city: addressData.address.city,
      zipCode: addressData.address.zipCode,
    },
  })

  const [showUnsavedChangesConfirm, setShowUnsavedChangesConfirm] = React.useState(false)
  const [showInvalidDeliveryOptionConfirm, setShowInvalidDeliveryOptionConfirm] =
    React.useState(false)

  const onSubmit = (data, event) => {
    const addressSplit = splittAddress(data.address)

    const customName = data.customName ?? ''
    const editedAddress = {
      name1: data.name1,
      name2: data.name2 ?? '', // Note: Whilst not a required user input field, it's a non-optional AddressV2 property
      street: addressSplit?.[1],
      houseNumber: addressSplit?.[2],
      zipCode: data.zipCode,
      city: data.city,
    } as unknown as AddressV2

    if (event.nativeEvent.submitter.id === 'update') {
      const addressDataEdited = { ...addressData, address: editedAddress, customName }
      updateUserAddress(
        addressDataEdited.addressId,
        addressDataEdited.address,
        addressDataEdited.customName
      )
    }

    if (event.nativeEvent.submitter.id === 'create') {
      createUserAddress(editedAddress, customName)
    }

    showEditAddressOverlay && setShowEditAddressOverlay(false)
  }

  const handleConfirmCloseDespiteUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
    // close overlay
    showEditAddressOverlay && setShowEditAddressOverlay(false)
  }
  const handleCancelCloseBecauseOfUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
    // continue showing overlay
  }
  const handleConfirmInvalidDeliveryOption = () => {
    // close alert
    showInvalidDeliveryOptionConfirm && setShowInvalidDeliveryOptionConfirm(false)
    // continue showing overlay
  }
  const handleUndoInvalidDeliveryOption = () => {
    // close confirm
    showInvalidDeliveryOptionConfirm && setShowInvalidDeliveryOptionConfirm(false)
    // TODO display address shown before user started editing
  }

  // triggered by back button, escapeKeyDown or backdropClick
  const handleClose = () => {
    // show confirm
    setShowUnsavedChangesConfirm(true)
    // continue showing overlay
  }

  let errorTextCompany = ''
  if (errors.name1) {
    if (errors.name1.type === 'required') {
      errorTextCompany = t('ADDRESSES.ERROR.COMPANY_REQUIRED')
    } else if (errors.name1.type === 'maxLength') {
      errorTextCompany = t('ADDRESSES.ERROR.COMPANY_MAX_LENGTH')
    }
  }

  let errorTextAddress = ''
  if (errors.address) {
    if (errors.address.type === 'required') {
      errorTextAddress = t('ADDRESSES.ERROR.ADDRESS_REQUIRED')
    } else if (errors.address.type === 'maxLength') {
      errorTextAddress = t('ADDRESSES.ERROR.STREET_NUMBER_MAX_LENGTH')
    } else {
      errorTextAddress = t('ADDRESSES.ERROR.ADDRESS_INVALID')
    }
  }

  let errorTextCity = ''
  if (errors.city) {
    if (errors.city.type === 'required') {
      errorTextCity = t('ADDRESSES.ERROR.CITY_REQUIRED')
    } else if (errors.city.type === 'maxLength') {
      errorTextCity = t('ADDRESSES.ERROR.CITY_INVALID')
    }
  }

  return (
    <>
      <Modal
        open={showEditAddressOverlay || false}
        onClose={handleClose}
        aria-labelledby="modal-modal-checkout-edit-address"
        aria-describedby="modal-modal-description"
      >
        <Box
          flexDirection={'row'}
          maxWidth={'45.625rem'}
          padding={desktop ? '2.5rem' : '1.5rem'}
          className={styles.checkoutEditAddressOverlay}
        >
          <Grid item xs={12}>
            <Box display={'flex'}>
              <IconButton
                disableFocusRipple
                disableRipple
                onClick={handleClose}
                className={styles.buttonAddressLocationCard}
              >
                <SvgIcon
                  component={ChevronLeftIcon}
                  fontSize={'large'}
                  htmlColor={theme.palette.grayVariant.dark}
                />
              </IconButton>
              <Typography variant={'h4'}>
                {mobile
                  ? t('ADDRESSES.ADDRESS_MANAGEMENT')
                  : t('ADDRESSES.YOUR_ADDRESS_MANAGEMENT')}
              </Typography>
            </Box>
          </Grid>
          <Box className={styles.typographyWrapper}>
            <Typography variant={'bodyBold'}>{t('ADDRESSES.EDIT_DELIVERY_ADDRESS')}</Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid item xs={12} className={styles.typographyWrapper}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.EDIT_ADDRESS_WONT_SAVE')}
              </Typography>
              <Controller
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="customName"
                    name="customName"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.customName ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.customName.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.CUSTOM_NAME_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="customName"
                control={control}
                defaultValue={addressData.customName}
                rules={{
                  maxLength: 23,
                }}
              />
            </Grid>
            <Grid item xs={12} className={styles.typographyWrapper}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.COMPANY_NAME')}*
              </Typography>
              <Controller
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    error={!!errors.name1}
                    className={styles.text_input}
                    variant="outlined"
                    id="name1"
                    name="name1"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={
                      errors.name1 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errorTextCompany}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name1"
                control={control}
                rules={{
                  required: true,
                  maxLength: 35,
                }}
              />
            </Grid>
            <Grid item xs={12} className={styles.typographyWrapper}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.CUSTOMER_NAME')}
              </Typography>
              <Controller
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="name2"
                    name="name2"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    helperText={
                      errors.name2 ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errors.name2.type === 'maxLength'
                              ? t('ADDRESSES.ERROR.NAME_MAX_LENGTH')
                              : ''}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="name2"
                control={control}
                rules={{
                  maxLength: 35,
                }}
              />
            </Grid>
            <Grid item xs={12} className={styles.typographyWrapper}>
              <Typography variant={'body'}>
                {t('SHOPPING_CART.CHECKOUT.ADDRESS.ADDRESS')}*
              </Typography>
              <Controller
                render={({ onChange, onBlur, value }) => (
                  <TextField
                    className={styles.text_input}
                    variant="outlined"
                    id="address"
                    name="address"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    helperText={
                      errors.address ? (
                        <Box display={'flex'}>
                          <SvgIcon
                            className={styles.helperTextIcon}
                            component={WarningIcon}
                            fontSize={'small'}
                            htmlColor={theme.palette.primary.main}
                          />
                          <Typography variant={'smallText'} color={theme.palette.primary.main}>
                            {errorTextAddress}
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  />
                )}
                name="address"
                control={control}
                rules={{
                  required: true,
                  maxLength: 60,
                  validate: checkSplittAddress,
                }}
              />
            </Grid>

            <Grid item container xs={12} direction="row" spacing={1}>
              <Grid item xs={6} className={styles.typographyWrapper}>
                <Typography variant={'body'}>
                  {t('SHOPPING_CART.CHECKOUT.ADDRESS.ZIP_CODE')}*
                </Typography>
                <Controller
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      error={!!errors.zipCode}
                      className={styles.text_input}
                      variant="outlined"
                      id="zipCode"
                      name="zipCode"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      helperText={
                        errors.zipCode ? (
                          <Box display={'flex'}>
                            <SvgIcon
                              className={styles.helperTextIcon}
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errors.zipCode.type === 'required'
                                ? t('ADDRESSES.ERROR.ZIP_CODE_REQUIRED')
                                : t('ADDRESSES.ERROR.ZIP_CODE_INVALID')}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                    />
                  )}
                  control={control}
                  name="zipCode"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^\d{5}$/i,
                      message: t('ADDRESSES.ERROR.ZIP_CODE_INVALID'),
                    },
                  }}
                />
              </Grid>
              <Grid className={styles.typographyWrapper} item xs={6}>
                <Typography variant={'body'}>
                  {t('SHOPPING_CART.CHECKOUT.ADDRESS.CITY')}*
                </Typography>
                <Controller
                  render={({ onChange, onBlur, value }) => (
                    <TextField
                      error={!!errors.city}
                      className={styles.text_input}
                      variant="outlined"
                      id="city"
                      name="city"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      helperText={
                        errors.city ? (
                          <Box display={'flex'}>
                            <SvgIcon
                              className={styles.helperTextIcon}
                              component={WarningIcon}
                              fontSize={'small'}
                              htmlColor={theme.palette.primary.main}
                            />
                            <Typography variant={'smallText'} color={theme.palette.primary.main}>
                              {errorTextCity}
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )
                      }
                    />
                  )}
                  name="city"
                  control={control}
                  defaultValue={addressData.address.city}
                  rules={{
                    required: true,
                    maxLength: 30,
                  }}
                />
              </Grid>
            </Grid>
            <Divider className={styles.divider} />
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={mobile ? 'column' : 'row'}
              justifyContent={'flex-end'}
              alignItems={'stretch'}
              gap={'1rem'}
              className={styles.buttonWrapper}
            >
              <TertiaryButton size="large" type="submit" disabled={!isValid} id="create" fullWidth>
                {!tabletAll && t('SHOPPING_CART.BUTTON_ACTIONS.ADD_AS_NEW_DELIVERY_ADDRESS')}
                {tabletAll && t('SHOPPING_CART.BUTTON_ACTIONS.ADD_DELIVERY_ADDRESS')}
              </TertiaryButton>
              <DarkButton type="submit" disabled={!isValid} id="update" fullWidth>
                {!tabletAll && t('SHOPPING_CART.BUTTON_ACTIONS.SAVE_DELIVERY_ADDRESS_CHANGES')}
                {tabletAll && t('SHOPPING_CART.BUTTON_ACTIONS.SAVE_CHANGES')}
              </DarkButton>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Confirm
        heading={t('ADDRESSES.UNSAVED_CHANGES')}
        body={t('ADDRESSES.CONFIRM.CONTINUE_DESPITE_UNSAVED_CHANGES')}
        handleConfirm={handleConfirmCloseDespiteUnsavedChanges}
        handleCancel={handleCancelCloseBecauseOfUnsavedChanges}
        openConfirmDialog={showUnsavedChangesConfirm}
      />
      <ConfirmWithUndo
        heading={t('ADDRESSES.INVALID_DELIVERY_OPTION')}
        body={t('ADDRESSES.CONFIRM.ADDRESS_OUTSIDE_DELIVERY_AREA')}
        handleConfirm={handleConfirmInvalidDeliveryOption}
        handleUndo={handleUndoInvalidDeliveryOption}
        openConfirmDialog={showInvalidDeliveryOptionConfirm}
      />
    </>
  )
}
