import { useState, useEffect, useCallback } from 'react'
import { switchMap } from 'rxjs'
import { MangoQuery, RxDocument } from 'rxdb'
import { empty, of } from 'rxjs'
import { useDbUtils } from './useDbUtils'
import { MetaData } from '@obeta/models/lib/models/Meta/Meta'
import { useRxDB } from 'rxdb-hooks'
import { RemoteSortedItem } from '@obeta/models/lib/models/Db'

const remoteSortedCollections = [
  'orders',
  'returnshipments',
  'remainingorders',
  'simplecarts',
  'offers',
]

const defaultQuery: MangoQuery = {
  selector: {
    id: {
      $exists: true,
    },
  },
  sort: [{ sortOrder: 'asc' }],
}

export type EntityChange<T> = (prevEntities: T[], nextEntities: T[]) => void
export type MapEntities<T> = <T1>(data: T[]) => T1[] | T[]

const isRemoteSortedItems = <T>(
  data: unknown[],
  entityName: string
): data is RemoteSortedItem<T>[] => {
  return remoteSortedCollections.indexOf(entityName) !== -1
}

function useBaseEntities<T>(p: {
  entityName: string
  mangoQuery?: MangoQuery
  entityChange?: EntityChange<T>
  mapEntities?: MapEntities<T>
}): T[] {
  const { entityName, mangoQuery, entityChange, mapEntities } = p

  const [entities, setEntities] = useState<T[]>([])
  const { getCollection$ } = useDbUtils()

  useEffect(() => {
    const collBS = getCollection$(entityName) //collection behaviour subject
    const collPipe = collBS?.pipe(
      switchMap((coll) => {
        if (coll) {
          let queryToUse: MangoQuery | undefined = undefined
          if (mangoQuery) {
            queryToUse = mangoQuery
          } else if (remoteSortedCollections.indexOf(entityName) !== -1) {
            queryToUse = defaultQuery
          }
          const qry = coll.find(queryToUse)
          return qry.$
        }
        return empty()
      })
    )

    const collSub = collPipe
      ?.pipe(
        switchMap((docs) => {
          let data = docs.map((doc: RxDocument<T>) => doc.toMutableJSON())
          if (mapEntities) {
            data = mapEntities(data)
          }

          return of(data)
        })
      )
      .subscribe((data: T[]) => {
        setEntities((oldData) => {
          entityChange && entityChange(oldData, data)
          return data
        })
      })

    return () => {
      collSub?.unsubscribe()
    }
  }, [entityName, mangoQuery, getCollection$, entityChange, mapEntities])

  return entities
}

/**
 * TODO: This hook won't work as expected
 * Reason: most local documents are upserted.
 * When doc is upserted rxdb doesn't establish new
 * connection. You must search for this doc again.
 */
export function useEntities<T>(
  entityName: string,
  mangoQuery?: MangoQuery,
  entityChange?: EntityChange<T>
): T[] {
  const mapEntities: MapEntities<T | RemoteSortedItem<T>> = useCallback(
    (data) => {
      if (isRemoteSortedItems<T>(data, entityName)) {
        return data.map((dt) => {
          if (entityName === 'carttemplates') {
            // console.log('data in mapEntities IF: ', data)
          }

          return dt.item
        })
      } else {
        if (entityName === 'carttemplates') {
          // console.log('data in mapEntities ELSE: ', data)
        }
        return data
      }
    },
    [entityName]
  )

  return useBaseEntities<T>({
    entityName,
    mangoQuery,
    entityChange,
    mapEntities,
  })
}

export const useEntitiesWithOffsets = <T>(p: {
  entityName: string
  mangoQuery?: MangoQuery
  entityChange?: EntityChange<T>
}) => {
  const { entityName } = p

  const mapEntities: MapEntities<T | RemoteSortedItem<T>> = useCallback(
    (data) => {
      if (isRemoteSortedItems<T>(data, entityName)) {
        const withOffsets: T[] = []

        for (let i = 0; i < data.length; i += 1) {
          const sortedItem = data[i]
          withOffsets[sortedItem.sortOrder] = sortedItem.item
        }

        return withOffsets
      } else {
        return data
      }
    },
    [entityName]
  )

  return useBaseEntities<T>({
    ...p,
    mapEntities,
  })
}

/**
 * TODO: we store a lot of data inside metaData.
 * This means that modules that use meta data will be updated unnessesary.
 * Just because data that they do not neeed was updated. Only because this data is part of
 * meta data. We need better solution. Subscribe only to fields that you need from meta data
 *
 * TODO: I cannot use useEntities for metaData.
 * Reason: getCollection$ returns undefined for entitymeta collection.
 * I cannot make it part of entitiesToManage array. I will break other parts of the app
 */
export const useMetadata = (metaCol: string) => {
  const db = useRxDB()
  const [meta, setMeta] = useState<MetaData | null>(null)

  useEffect(() => {
    const sub = db.entitymeta
      .findOne({
        selector: {
          id: metaCol,
        },
      })
      .$.subscribe((doc: RxDocument<MetaData>) => {
        const meta = doc.toMutableJSON()
        setMeta(() => meta)
      })

    return () => {
      sub.unsubscribe()
    }
  }, [db, metaCol])

  return meta
}

export const useLocal = <DocType>(localName: string) => {
  const db = useRxDB()
  const [doc, setDoc] = useState<DocType | null>(null)

  useEffect(() => {
    const sub = db.getLocal$<DocType>(localName).subscribe((doc) => {
      const data = doc?.toMutableJSON().data
      setDoc(data || null)
    })

    return () => {
      sub.unsubscribe()
    }
  }, [db, localName])

  return doc
}
