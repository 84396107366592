import { Offer } from '@obeta/models/lib/models'
import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'

export const offersEntity: EntityDescriptor<RemoteSortedItem<Offer>> = {
  name: 'offers',
  migrationStrategies: {
    1: function (oldDoc) {
      oldDoc.filtered = 0
      return oldDoc
    },
    2: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    3: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'offers',
    version: 3,
    description: 'a document representing an offer',
    type: 'object',
    indexes: ['sortOrder', 'filtered'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      filtered: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          offerNo: {
            type: 'string',
          },
          details: {
            type: 'string',
          },
          isNew: {
            type: ['boolean', 'null'],
          },
          isValid: {
            type: 'boolean',
          },
          validFrom: {
            type: 'string',
          },
          validTo: {
            type: 'string',
          },
          sumPrice: {
            type: 'string',
          },
          positions: {
            type: 'array',
          },
        },
      },
    },
  },
  subscription: null,
}
