import { OrderFailureReason } from '@obeta/models/lib/models/Notification/Notification'
import { TFunction } from 'react-i18next'

export const getOrderFailureNotificationProps = (
  reason: OrderFailureReason,
  t: TFunction
): { heading: string; body: string; contactInfo: string } => {
  switch (reason) {
    // Wird nur im Warenkorb Details oder Checkout angezeigt
    case OrderFailureReason.CreditLimitExceeded: {
      return {
        heading: 'Achtung!',
        body: 'Ihr Auftrag kann nicht ausgeführt werden. Ihr Kreditlimit ist überschritten.',
        contactInfo: t('OBETA.BUCHHALTUNG_INT_TEL'),
      }
    }
    // Wird zuerst im Warenkorb Checkout angezeigt
    case OrderFailureReason.OrderingBlocked: {
      return {
        heading: 'Achtung!',
        body: 'Ihr Kundenkonto ist aktuell mit einer Auftragssperre belegt. Der Warenkorb kann nicht abgesendet werden.',
        contactInfo: t('OBETA.SERVICE_CENTER_INT_TEL'),
      }
    }
    // Wird bis zum Schliessen via Button oder (TODO bis zum Seitenwechsel angezeigt COD-10732)
    case OrderFailureReason.OrderSubmitFailure: {
      return {
        heading: 'Leider ist ein Problem aufgetreten',
        body: 'Ihre Bestellung wurde an unseren Support übermittelt und wird dort weiter bearbeitet.',
        contactInfo: t('OBETA.ECOM_SUPPORT_INT_TEL'),
      }
    }
    // Wird zuerst im Warenkorb Checkout angezeigt
    case OrderFailureReason.ShippingBlocked: {
      return {
        heading: 'Achtung!',
        body: 'Ihr Kundenkonto ist aktuell mit einer Liefersperre belegt. Der Warenkorb kann nicht abgesendet werden.',
        contactInfo: t('OBETA.SERVICE_CENTER_INT_TEL'),
      }
    }
    // Wird zuerst im Warenkorb Checkout angezeigt
    case OrderFailureReason.NoPermissionGranted: {
      return {
        heading: '',
        body: 'Ihr Benutzerkonto ist nicht berechtigt Warenkörbe zu bestellen oder zu melden. Ihr Administrator kann die Berechtigung verwalten.',
        contactInfo: '',
      }
    }
    case OrderFailureReason.Custom:
    default: {
      return {
        heading: 'Leider ist ein Problem aufgetreten',
        body: '', // comes via reason in notification
        contactInfo: t('OBETA.ECOM_SUPPORT_INT_TEL'),
      }
    }
  }
}
