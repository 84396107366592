import { useCallback, useContext, useEffect, useState, createContext, useMemo } from 'react'
import { useHistory, useLocation } from './useHistoryApi'
import { SearchResult, useArticleSearch } from './useArticleSearch'
import { ArticleSearchParams, ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { withMd5Id } from '@obeta/utils/lib/genMd5Id'

interface Pagination {
  itemsPerPage?: number
  page?: number
}

interface IArticlesSearchProvider
  extends Pick<
    SearchResult,
    'searchResult' | 'maxPage' | 'hits' | 'fetchMore' | 'dataUpdated$' | 'queryId'
  > {
  searchText: string
  searchParams: ArticleSearchParamsWithId | undefined
  isFetching: boolean
  itemsPerPage: number
  currentPage: number
  changePagination(pagination: Pagination)
  changeSearchParams(p: Params)
}

interface Props {
  /**
   * configures, if searchstate is managed in global history or local state
   */
  mode?: 'local' | 'global'
}

interface Params {
  searchParams: ArticleSearchParams
  currentPage?: number
  route: 'push' | 'replace' | 'none'
}

const isArticleSearchParams = (
  data: unknown
): data is { searchParams: ArticleSearchParamsWithId } => {
  return Boolean(data && typeof data === 'object' && 'searchParams' in data)
}

const ArticlesSearchContext = createContext<IArticlesSearchProvider | null>(null)

/*
// all components should share the same observables
const paginationSubject = new BehaviorSubject(false)
const isFetching$ = isFetchingSubject.pipe(distinctUntilChanged())

const searchSubject = new BehaviorSubject()
const isFetching$ = isFetchingSubject.pipe(distinctUntilChanged())

interface SearchRequest {
  isFetching: boolean
  searchParams: ArticleSearchParamsWithId
  currentPage: number
}

interface SearchResult {
  hitCount: number
  products: ProductAggregate[]
}

interface Pagination {
  itemsPerPage: number
}*/

export const ArticlesSearchProvider: React.FC<Props> = ({ children, mode = 'global' }) => {
  const location = useLocation()
  const history = useHistory()
  const [searchParams, setSearchParams] = useState<ArticleSearchParamsWithId | undefined>(undefined)
  const [itemsPerPage, setItemsPerPage] = useState(16)
  const [currentPage, setCurrentPage] = useState(0)
  useEffect(() => {
    if (
      isArticleSearchParams(location.state) &&
      location.state.searchParams?.id !== searchParams?.id
    ) {
      setSearchParams(location.state.searchParams)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- location is not fully typed
    const paginationState = (location.state as any)?.pagination
    if (paginationState) {
      if (typeof paginationState.currentPage === 'number') {
        setCurrentPage(paginationState.currentPage)
      }
    }
  }, [location.state, searchParams])

  const searchRequest = useMemo(() => {
    return {
      searchParams,
      itemsPerPage: itemsPerPage,
      page: currentPage,
    }
  }, [currentPage, itemsPerPage, searchParams])
  const searchResult = useArticleSearch(searchRequest, () => setCurrentPage(0))

  const changeSearchParams = useCallback(
    (p: Params) => {
      const searchParamsWithId = withMd5Id(p.searchParams as ArticleSearchParamsWithId)
      if (mode === 'local') {
        setSearchParams(searchParamsWithId)
        if (p.currentPage) {
          setCurrentPage(p.currentPage)
        }
      } else if (p.route === 'replace') {
        history.replace('/search', {
          searchParams: searchParamsWithId,
          pagination: { currentPage: p.currentPage || 0 },
        })
      } else if (p.route === 'push') {
        history.push('/search', {
          searchParams: searchParamsWithId,
          pagination: { currentPage: p.currentPage || 0 },
        })
      }
    },
    [history, mode]
  )

  const changePagination = useCallback(
    (paging: Pagination) => {
      if (paging.itemsPerPage) {
        setItemsPerPage(paging.itemsPerPage)
      }
      if ('page' in paging && typeof paging.page === 'number') {
        if (mode === 'local') {
          setCurrentPage(paging.page)
        } else {
          history.replace('/search', { searchParams, pagination: { currentPage: paging.page } })
        }
      }
    },
    [searchParams, history, mode]
  )

  const searchText = searchParams?.searchString || ''

  return (
    <ArticlesSearchContext.Provider
      value={{
        searchText,
        searchParams,
        isFetching: !!searchResult.loading,
        searchResult: searchResult.searchResult,
        itemsPerPage,
        currentPage,
        hits: searchResult.hits,
        maxPage: searchResult.maxPage,
        fetchMore: searchResult.fetchMore,
        changePagination,
        changeSearchParams,
        dataUpdated$: searchResult.dataUpdated$,
        queryId: searchResult.queryId,
      }}
    >
      {children}
    </ArticlesSearchContext.Provider>
  )
}

export const useArticlesSearchProvider = () => {
  const searchData = useContext(ArticlesSearchContext)

  if (!searchData) {
    throw new Error(
      'searchData is not defined. make sure you call useArticlesSearchProvider inside one of ArticlesSearchProvider child'
    )
  }

  return searchData
}
