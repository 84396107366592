import {
  BarcodeScanner as CapacitorBarcodeScanner,
  CameraDirection,
} from '@capacitor-community/barcode-scanner/dist/plugin.cjs'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { BarcodeContext } from './useBarcodeState'
import { useSearchArticles } from './useSearchArticles'
import { updateCard } from '../actions/customer-actions'
import { ScreenOrientation } from '@capacitor/screen-orientation'

export const useBarcodeScanner = () => {
  const { scan, setScan } = useContext(BarcodeContext)
  const [back, setBack] = useState(false)
  const searchScanner = useSearchArticles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const tabsComponent = document.getElementById('appMainContent') as HTMLElement
  const customerCardComponent = document.getElementById('customercard') as HTMLElement

  const stopScan = useCallback(() => {
    CapacitorBarcodeScanner.stopScan()
    tabsComponent.style.display = 'unset'
    if (customerCardComponent) customerCardComponent.style.display = 'unset'

    document.documentElement.style.setProperty('--background-color', '#f6f6f6')
    document.body.style.background = '#fff'
    ScreenOrientation.unlock()

    if (setScan) setScan(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setScan, tabsComponent, customerCardComponent])

  const toggleTorch = CapacitorBarcodeScanner.toggleTorch.bind(CapacitorBarcodeScanner)

  const prepareScannerLayout = () => {
    if (setScan) setScan(true)

    setBack(true)
    tabsComponent.style.display = 'none'
    if (customerCardComponent) customerCardComponent.style.display = 'none'
    document.body.style.background = 'transparent'
    document.documentElement.style.setProperty('--background-color', 'transparent')
  }

  const performScan = async (callback) => {
    if (isPlatform('web')) return

    const approved = await checkPermision()

    if (approved) {
      // Workaround to fix Capacitor camera appearance problem before prepare scanning from landscape mode, and the camera is shifted to the left.
      // issue link: https://github.com/capacitor-community/barcode-scanner/issues/298
      await ScreenOrientation.lock({ orientation: 'portrait' })

      CapacitorBarcodeScanner.prepare()

      prepareScannerLayout()

      const results = await CapacitorBarcodeScanner.startScan({
        cameraDirection: CameraDirection.BACK,
      })

      if (results.hasContent) {
        callback(results.content)
        setBack(false)
        stopScan()
      }
    } else {
      const notificationMessage = t('ALERTS.CAMERA_PERMISSION_BARCODE_SCANNER')
      getEventSubscription().next({
        type: EventType.Toast,
        notificationType: NotificationType.Toast,
        id: 'toast',
        options: {
          message: notificationMessage,
        },
      })
    }
  }

  const startTransferCardScan = async () => {
    performScan((content) => {
      dispatch(updateCard(content))
    })
  }

  const startSearchScan = async () => {
    performScan((content) => {
      searchScanner.setSearchText(content)
      searchScanner.handleSubmit()
    })
  }

  const checkPermision = async () => {
    const status = await CapacitorBarcodeScanner.checkPermission({ force: true })
    return !!status.granted
  }

  useEffect(() => {
    history &&
      history.block(() => {
        if (back) {
          stopScan()
          setBack(false)
          return false
        }
      })
  }, [back, history, stopScan])

  return {
    startSearchScan,
    stopScan,
    toggleTorch,
    scan,
    startTransferCardScan,
  }
}
