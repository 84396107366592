import { SimpleOffer } from '@obeta/models/lib/models'
import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'

export const simpleOffersEntity: EntityDescriptor<Omit<RemoteSortedItem<SimpleOffer>, 'filtered'>> =
  {
    name: 'simpleoffers',
    migrationStrategies: {
      1: function (oldDoc) {
        // migration to rxdb 12
        return oldDoc
      },
      2: function (oldDoc) {
        // RxDB v12 -> v13 migration
        return oldDoc
      },
    },
    schema: {
      title: 'simpleoffers',
      version: 2,
      description: 'a document representing a simpleOffer',
      type: 'object',
      indexes: ['sortOrder'],
      primaryKey: 'id',
      properties: {
        id: {
          type: 'string',
          maxLength: 100,
        },
        sortOrder: {
          type: 'number',
          multipleOf: 1,
          minimum: 0,
          maximum: 100000,
        },
        item: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            offerNo: {
              type: 'string',
            },
            details: {
              type: 'string',
            },
            isValid: {
              type: 'boolean',
            },
          },
        },
      },
    },
    subscription: null,
  }
