import { useState, useContext } from 'react'
import { ArticleRing } from '@obeta/models/lib/models/Article/ArticleListItem'
import { SimpleShoppingCart } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import {
  addArticleToCart as addArticleToCartFunc,
  saveCustomerSettings,
  CustomerActionTypes,
  SaveCustomerSettingsAction,
} from '../actions'
import { useActionNotification } from '../hooks/useActionNotification'
import { UserContext } from '../hooks/useUserData'
import { useDispatch } from 'react-redux'
import { useActiveCartId } from './useActiveCartId'

interface ArticleMetaData {
  id: string
  ring: ArticleRing
  amount: number
  title: string
}

interface LogicProps {
  addArticleToCart: (id: string, ring: ArticleRing, amount: number, title: string) => void
  closeCartSelectionMenu: (cart?: SimpleShoppingCart) => void
  showCartPickerFor: ArticleMetaData | undefined
}

export const useAddToCartLogic = (): LogicProps => {
  const { user, isLoggedIn } = useContext(UserContext)
  const dispatch = useDispatch()
  const activeCartId = useActiveCartId()

  const [showCartPickerFor, setShowCartPicker] = useState<ArticleMetaData | undefined>(undefined)
  const waitForAction = useActionNotification(CustomerActionTypes.SaveCustomerSettingsResult)
  function closeCartSelectionMenu(cart?: SimpleShoppingCart) {
    if (cart && showCartPickerFor && isLoggedIn) {
      dispatch(
        saveCustomerSettings({
          creditLimitWarning: false,
          orderConfirmation: false,
          ...user?.user.customerSettings,
          defaultProjectId: cart.id,
        })
      )
      // TODO: should we wait for the backend operation to finish or just assume, it will become active anyway
      waitForAction((action: SaveCustomerSettingsAction) => {
        const payload = {
          articleId: showCartPickerFor.id,
          articleName: showCartPickerFor.title,
          cart,
          ring: showCartPickerFor.ring,
          amount: showCartPickerFor.amount,
        }
        dispatch(addArticleToCartFunc(payload))
      })
    }
    setShowCartPicker(undefined)
  }

  const addArticleToCart = (id: string, ring: ArticleRing, amount: number, title: string) => {
    if (activeCartId) {
      // TODO is it to hacky to construct this dummy SimpleShoppingCart like this?
      const payload = {
        articleId: id,
        articleName: title,
        cart: {
          id: activeCartId,
          name: '',
        },
        ring: ring,
        amount: amount,
      }
      dispatch(addArticleToCartFunc(payload))
    } else {
      setShowCartPicker({ id, ring, amount, title })
    }
  }

  return {
    addArticleToCart,
    closeCartSelectionMenu,
    showCartPickerFor,
  }
}
