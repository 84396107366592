import { BehaviorSubject, defer, firstValueFrom, Observable, of, Subject } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  exhaustMap,
  filter,
  map,
  retry,
  retryWhen,
  share,
} from 'rxjs'
import Axios from 'axios-observable'
import { OAuth2Client } from '@byteowls/capacitor-oauth2'
import { SecureStoragePlugin } from '@atroo/capacitor-secure-storage-plugin'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { RxCollectionCreator, RxDatabase } from 'rxdb'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { AppActions, Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import AxiosPromise, { AxiosError } from 'axios'
import type { CustomerMetaData } from '@obeta/data/lib/hooks/useUserData'
import { API_URL, APP_ID, AUTH_BASE_URL } from '@obeta/utils/lib/config'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { bootstrapInitialMetaDataDocs } from '@obeta/utils/lib/epics-helpers'
import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel'
import { resync } from './resync'
import { CapacitorHttp, HttpResponse } from '@capacitor/core'
import { deleteUserToken } from '@obeta/data/lib/actions/customer-actions'
import { datadogRum } from '@datadog/browser-rum'
import { syncEntities } from '@obeta/data/lib/entities'
import { EntityNames, ObetaModels } from '@obeta/models/lib/models/Db/index'
import { setCollection } from './bootstrap'
import { Store } from 'redux'
import { setLogoutInProgress } from '@obeta/utils/lib/isLogoutInProgressUtils'
import { clearSessionContext, getSessionContext } from '@obeta/utils/lib/session-context'

const sessionRefreshSubject$ = new Subject()
const tokenRefreshSubject$ = new Subject()
const logout$ = new Subject<boolean>()
export const tokens$ = new BehaviorSubject<Tokens | null>(null)

let accessToken: string | undefined, refreshToken: string | undefined

interface MessageTokenRefresh {
  action: 'refresh_token'
}

interface MessageTokenReceived {
  action: 'token_received'
  tokens?: Tokens
}

interface MessageShopUpdate {
  action: 'version_change'
  storageKey: string
  storageValue: string
}

class HydraNotReachableError extends Error {
  constructor(message) {
    super(message)
    this.name = 'HydraNotReachableError'
  }
}

interface AppActionsConfig {
  /** we need this in the print service as there is only one tab anyhow, and there is no need to fire up the nodejs filesystem based broadcast channel */
  disableMultiTabRefresh?: boolean
}

export const initAppActions = (
  db: RxDatabase,
  tokens: Tokens | null = null,
  store?: Store,
  config?: AppActionsConfig
): AppActions => {
  if (tokens) {
    tokens$.next(tokens)
  }
  tokens$.subscribe((tokens) => {
    if (tokens) {
      accessToken = tokens.accessToken
      refreshToken = tokens.refreshToken
    } else {
      accessToken = undefined
      refreshToken = undefined
    }
  })

  const channelTokenRefresh = new BroadcastChannel<MessageTokenRefresh>('token_refresh')
  const channelTokenReceive = new BroadcastChannel<MessageTokenReceived>('token_receive')
  const channelShopVersion = new BroadcastChannel<MessageShopUpdate>('shop_version')

  let elector: LeaderElector | undefined

  if (config?.disableMultiTabRefresh !== true) {
    elector = createLeaderElection(channelTokenRefresh)

    elector.awaitLeadership().then(() => {
      // eslint-disable-next-line no-console
      console.log('this tab is now leader')
      datadogRum.addAction('Leadership acquired')
    })

    channelTokenRefresh.onmessage = (data) => {
      if (data.action === 'refresh_token' && (elector as LeaderElector).isLeader) {
        tokenRefreshSubject$.next(true)
      }
    }
  }

  channelTokenReceive.onmessage = (data) => {
    if (data.action === 'token_received' && data.tokens) tokens$.next(data.tokens)
  }

  channelShopVersion.onmessage = (data) => {
    if (typeof window === 'undefined' || !localStorage) return
    if (data.action === 'version_change') {
      localStorage.setItem(data.storageKey, data.storageValue)
      // eslint-disable-next-line no-console
      console.log(`reload tab and store new shop version: ${data.storageValue}`)
      window.location.reload()
    }
  }

  db.getLocal$<CustomerMetaData>('usermeta')
    .pipe(
      concatMap((doc) =>
        defer(async () => {
          // we listen for changes of the isLoggedIn flag of the usermeta
          // if this changed and is true, we fetch credentials from securestorage
          // and provide them to the token observable, so all connected parts of the app
          // get notified, that loggedIn state has changed
          // (most ui components use useUserDataV2, which is subscribed)
          if (doc) {
            const isLoggedIn = doc.get('isLoggedIn')
            if (isLoggedIn === true) {
              try {
                const { value } = await SecureStoragePlugin.get({ key: 'auth' })
                if (value) {
                  const authObj: Tokens = JSON.parse(value)
                  tokens$.next(authObj)
                }
              } catch (err) {
                tokens$.next(null)
                clearSessionContext()
              }
            } else if (isLoggedIn === false) {
              tokens$.next(null)
              clearSessionContext()
            }
          }
        })
      )
    )
    .subscribe((doc) => {
      // empty
    })

  tokens$
    .pipe(
      filter((tokens) => !!tokens),
      exhaustMap(() =>
        defer(async () => {
          if (!isPlatform('web')) {
            const result = await FirebaseMessaging.checkPermissions()
            if (result.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              try {
                await FirebaseMessaging.getToken()
              } catch (err) {
                // Sentry.captureException(err) TODO replace former Sentry-Code with DataDog
              }
            }
          }
        }).pipe(
          catchError((error: Error) => {
            /*Sentry.addBreadcrumb({
              category: 'push',
              message: 'Error registering for push notifications',
            })
            Sentry.captureException(error) */ // TODO replace former Sentry-Code with DataDog
            return of(null)
          })
        )
      ),
      share()
    )
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .subscribe(() => {})

  const logoutFinished$ = logout$.pipe(
    exhaustMap((bool: boolean) =>
      defer(async () => {
        datadogRum.addAction('logging user out')
        await setLogoutInProgress(true)

        const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
        const isLoggedIn = userMeta?.get('isLoggedIn')
        if (isLoggedIn) {
          // Clear push notification tokens before clearing auth tokens.
          if (!isPlatform('web')) {
            try {
              await FirebaseMessaging.deleteToken()
              if (store) await store.dispatch(deleteUserToken())
            } catch (err) {
              datadogRum.addAction('Failed to unregister from firebase')
              datadogRum.addError(err)
            }
          }
          // Clear auth tokens.
          // Otherwise these tokens will be used to authenticate user (check useLogin)
          try {
            clearSessionContext()
            await SecureStoragePlugin.clear()
          } catch (err) {
            datadogRum.addAction('Failed to clear secure storage')
            datadogRum.addError(err)
          }

          try {
            await OAuth2Client.logout({ appId: APP_ID })
          } catch (err) {
            datadogRum.addAction('failed to clear oauth2 session')
            datadogRum.addError(err)
          }
          tokens$.next(null)
          clearSessionContext()
        }

        try {
          datadogRum.addAction('resetting local state')

          await db.upsertLocal<CustomerMetaData>('usermeta', {
            isLoggedIn: false,
            companyId: null,
            userId: null,
            isFetching: false,
            mustRefetch: false,
            lastUpdated: null,
          })
          await db.upsertLocal('user', {})
          await db.upsertLocal('userv2', {})

          await db.upsertLocal('searchHistory', {
            history: [],
          })

          await db.upsertLocal('pushToken', { token: null })
        } catch (err) {
          datadogRum.addAction('Failed to reset local state')
          datadogRum.addError(err)
        }

        resync.cancelReplications()

        try {
          datadogRum.addAction('truncating collections')

          const collectionNames: EntityNames[] = []

          await Promise.all(
            Object.keys(db.collections).map(async (collName) => {
              // If the accessToken exists, it means that we should stop clearing the collection because the user has logged in again,
              // and the data in the collections will be updated using the reSync feature (see bootstrap.tsx)
              if (collName === 'entitymeta' || accessToken) return

              collectionNames.push(collName as EntityNames)
              const coll = db.collections[collName]
              await coll.remove().catch((err) => {
                console.error(`failed to remove data from ${collName}`, err)
                datadogRum.addError(err)
              })
            })
          )

          // reset entityMetadata with force flag
          await bootstrapInitialMetaDataDocs(db, collectionNames, true)

          /**
           * after removal of the collections, we will recreate them directly
           * to remain in a consistent state
           */
          const data: Record<string, RxCollectionCreator<ObetaModels>> = {}
          syncEntities.forEach((ent) => {
            data[ent.name] = {
              schema: ent.schema,
              migrationStrategies: ent.migrationStrategies,
              localDocuments: true,
            }
          })
          await db.addCollections(data)
          syncEntities.forEach((ent) => {
            setCollection(ent.name, db[ent.name])
          })
        } catch (err) {
          datadogRum.addAction('Failed to truncate collections')
          datadogRum.addError(err)
        }

        await setLogoutInProgress(false)
        return isLoggedIn
      }).pipe(
        catchError((error) => {
          datadogRum.addAction('Failed to reset local state')
          datadogRum.addError(error)

          return defer(async () => {
            const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
            return !!userMeta?.get('isLoggedIn')
          })
        })
      )
    ),
    exhaustMap((isLoggedIn) => {
      if (!isLoggedIn) return of(null)

      // this is a workaround until we can fully remove all calls to the old app.obeta.de api
      // only the native is in production and uses them, so we can detect it by checking for the platform
      // not nice, but good enough
      if (!isPlatform('web')) {
        return Axios.request({
          method: 'POST',
          url: '/user/logout',
          data: null,
          headers: {
            'Content-Type': 'application/json',
          },
        }).pipe(
          catchError((error: Error) => {
            datadogRum.addAction('Logout failed')
            datadogRum.addError(error)

            return of(null)
          })
        )
      }
      return of(null)
    }),
    share()
  )

  logoutFinished$.subscribe()

  const obs2: Observable<boolean> = sessionRefreshSubject$.pipe(
    filter(() => !!accessToken),
    exhaustMap(() => {
      return defer(async () => {
        // we must use axios with promise api here, otherwise the obvserable won't be
        // recreated and will always use an outdated accesstoken, resulting in an endless loop
        AxiosPromise.defaults.withCredentials = true
        AxiosPromise.defaults.baseURL = API_URL
        await AxiosPromise({
          url: `user/validation?token=${accessToken}`,
        })
      }).pipe(
        map(() => true),
        retryWhen((error$) => {
          return error$.pipe(
            concatMap((error: AxiosError) =>
              defer(async () => {
                if (error.response?.status === 424) {
                  const userMeta = await db.getLocal<CustomerMetaData>('usermeta')
                  const isGuest = !userMeta?.get('isLoggedIn')
                  if (isGuest) {
                    throw error
                  }
                  await requestNewToken()
                  const tokens = await firstValueFrom(tokens$)
                  if (tokens?.accessToken) {
                    // this triggers a retry of the request
                    return true
                  }
                }
                throw error
              })
            )
          )
        }),
        catchError((err: AxiosError) => {
          /*if (err.response) {
            Sentry.withScope((scope) => {
              scope.setTags({
                type: 'auth',
                'auth.operation': 'refreshSession',
                'auth.status': err.response?.status,
              })
              scope.setExtra('responseBody', err.response?.data)
            })
          }
          Sentry.captureException(err)*/ // TODO replace former Sentry-Code with DataDog
          return of(false)
        })
      )
    }),
    share()
  )
  obs2.subscribe(() => {
    //
  })

  const requestTokens$: Observable<boolean> = tokenRefreshSubject$.pipe(
    debounceTime(1000),
    exhaustMap(() =>
      defer(async () => {
        let tokens: Tokens | null = null
        const isElectorHasLeader = await elector?.hasLeader()

        datadogRum.addAction('before tab election ', {
          isLeader: elector?.isLeader || true,
          hasLeader: isElectorHasLeader || true,
        })

        // we need a new token, but are not the leading tab
        // inform the leader to refresh the token and wait for the result
        if (!elector?.isLeader && isElectorHasLeader && isPlatform('web')) {
          channelTokenRefresh.postMessage({ action: 'refresh_token' })
          return false
        }

        datadogRum.addAction('initiate token refresh ', {
          hasRefreshToken: !!refreshToken,
        })

        if (isPlatform('web')) {
          const sessionContext = getSessionContext()
          if (sessionContext) return false

          const form = new FormData()
          form.append('refresh_token', refreshToken as string)
          form.append('grant_type', 'refresh_token')
          form.append('client_id', APP_ID as string)

          let tokenSet
          try {
            const response = await fetch(AUTH_BASE_URL + '/oauth2/token', {
              method: 'POST',
              body: form,
            })
            tokenSet = await response.json()
          } catch (err) {
            // if fetch throws an error, it is considered a network error
            // service is down or no network connectivity
            // in this case, we should not log the user out
            throw new HydraNotReachableError(err.message)
          }
          tokens = {
            accessToken: tokenSet['access_token'],
            refreshToken: tokenSet['refresh_token'],
          }
        } else {
          const sessionContext = getSessionContext()
          if (sessionContext) return false
          // temporary solution until Capacitor Http can process FormData
          // @see https://github.com/ionic-team/capacitor/pull/6206
          const formData = {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
            client_id: APP_ID as string,
          }
          const formBody: string[] = []
          for (const prop in formData) {
            const encodedKey = encodeURIComponent(prop)
            const encodedValue = encodeURIComponent(formData[prop])
            formBody.push(encodedKey + '=' + encodedValue)
          }
          const form = formBody.join('&')

          const options = {
            url: AUTH_BASE_URL + '/oauth2/token',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: form,
          }

          let tokenSet
          try {
            const response: HttpResponse = await CapacitorHttp.post(options)
            tokenSet = response.data

            datadogRum.addAction('token refresh response', {
              statusCode: response.status,
              hasAccessToken: !!tokenSet['access_token'],
              hasRefreshToken: !!tokenSet['refresh_token'],
              response: response.status !== 200 ? response : undefined,
            })
          } catch (err) {
            // if fetch throws an error, it is considered a network error
            // service is down or no network connectivity
            // in this case, we should not log the user out
            throw new HydraNotReachableError(err.message)
          }

          tokens = {
            accessToken: tokenSet['access_token'],
            refreshToken: tokenSet['refresh_token'],
          }
        }

        if (tokens.refreshToken && tokens.accessToken) {
          datadogRum.addAction('received new tokens')

          // inform components about new tokens
          tokens$.next(tokens)

          // inform other tabs about new tokens
          channelTokenReceive.postMessage({
            action: 'token_received',
            tokens: tokens,
          })
          try {
            await SecureStoragePlugin.set({
              key: 'auth',
              value: JSON.stringify(tokens),
              accessibility: 'afterFirstUnlock',
            })
            datadogRum.addAction('stored new tokens')
          } catch (err) {
            datadogRum.addError(err)

            // if we cannot store the token, we should indicate that
            return false
          }

          return true
        } else {
          throw new Error('token refresh failed')
        }
      }).pipe(
        retry(1),
        catchError((err) => {
          datadogRum.addError(err)

          // exit early and do not log the user out as we have connection problems or
          // hydra is down, but the token may still be valid
          // with this check we want the user to stay logged in in offline scenarios
          if (err instanceof HydraNotReachableError) {
            return of(false)
          }

          // authentication error, log the user out and delete local data
          logout$.next(true)

          getEventSubscription().next({
            type: EventType.Alert,
            notificationType: NotificationType.Alert,
            id: 'session_invalidated',
            options: {
              message: 'Ihre Sitzung ist abgelaufen und Sie müssen sich neu einloggen',
              title: 'Sitzung',
              id: 'session_invalidated',
            },
          })

          return of(false)
        })
      )
    ),
    share()
  )
  requestTokens$.subscribe()

  const requestNewToken = async () => {
    datadogRum.addAction('triggered request token ')
    const prom = firstValueFrom(requestTokens$)
    tokenRefreshSubject$.next(true)
    return prom
  }

  const refreshSession = async () => {
    const prom = firstValueFrom(obs2)
    sessionRefreshSubject$.next(true)
    return prom
  }

  return {
    tokenRefreshSubject$,
    tokens$,
    logout$,
    logoutFinished$,
    refreshSession,
    requestNewToken,
  }
}
