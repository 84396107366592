import React, { useEffect, useState } from 'react'
import { Box, Divider, Link, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SmsIcon } from 'assets/icon/designsystem/sms.svg'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail.svg'
import { ReactComponent as InstagramLogo } from 'assets/icon/designsystem/instagram.svg'
import { ReactComponent as YoutubeLogo } from 'assets/icon/designsystem/youtube-logotype.svg'
import { ReactComponent as AppStoreLogo } from 'assets/icon/designsystem/appstore.svg'
import { ReactComponent as GooglePlayLogo } from 'assets/icon/designsystem/googleplay.svg'
import clsx from 'clsx'
import styles from './Footer.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SectionMetalQuotationCopyrightNote } from './SectionMetalQuotationCopyrightNote'
import { gql, useLazyQuery } from '@apollo/client'
import { MetalTypes, MetalV2 } from '@obeta/models/lib/models/Metal/Metal'
import { ConsolidatedHistory } from '@obeta/models/lib/models/History'
import { LogoAndIconsAndContactUsTabletLandscape } from './LogoAndIconsAndContactUsTabletLandscape'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { useShopInfo } from '@obeta/data/lib/hooks/useShopInfo'
import { ListOfLinksForMobileAndTabletPortrait } from './ListOfLinksForMobileAndTabletPortrait'
import { ListOfLinksForDesktopAndTabletLandscape } from './ListOfLinksForDesktopAndTabletLandscape'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'

// Refetch info about metals every POLL_TIME
const POLL_TIME = 10 * 60 * 1000 // 10 min

const loadMetalQuotationsQuery = gql`
  query getMetalQuotations {
    getMetalQuotations {
      metalType
      price
    }
  }
`

export const clickedObetaLinks = (
  e: React.MouseEvent,
  history: ConsolidatedHistory,
  url: string
) => {
  if (!e.getModifierState('Control')) {
    e.preventDefault()
    history.push(url)
  }
}

export const Footer: React.FC = () => {
  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const { t } = useTranslation()
  const [aluminium, setAluminium] = useState<number>(0)
  const [copper, setCopper] = useState<number>(0)
  const { isLoggedIn } = useUserDataV2()
  const [loadMetalData, { data: metalData }] = useLazyQuery(loadMetalQuotationsQuery)

  useEffect(() => {
    const load = async () => {
      if (!isLoggedIn) {
        return
      }

      await loadMetalData()
    }

    let timerId: NodeJS.Timeout
    const startTimer = () => {
      timerId = setTimeout(async () => {
        await load()
        startTimer()
      }, POLL_TIME)
    }

    ;(async () => {
      await load()
      startTimer()
    })()

    return () => {
      clearTimeout(timerId)
    }
  }, [isLoggedIn, loadMetalData])

  useEffect(() => {
    metalData?.getMetalQuotations.forEach((metalData: MetalV2) => {
      metalData.metalType === MetalTypes.Aluminium && setAluminium(metalData.price)
      metalData.metalType === MetalTypes.Copper && setCopper(metalData.price)
    })
  }, [metalData])

  const { redirectToStartPage } = useRedirect()

  const { FullLogo, EmailAddress, CopyRight } = useShopInfo()
  return (
    <div className={styles.outerDiv}>
      <Divider className={styles.dividerGray} variant={'fullWidth'} />
      <div className={styles.wrapper}>
        <div className={styles.footerContainer}>
          <div className={styles.divLogoAndIcons}>
            {!tabletWide && (
              <Box className={styles.boxLogos}>
                <Link
                  underline="none"
                  color="inherit"
                  href={'/'}
                  onClick={(event: React.MouseEvent) => redirectToStartPage(event)}
                >
                  <FullLogo className={styles.obetaLogoColor} data-url="/" />
                </Link>
                {(mobile || tablet) && (
                  <div>
                    <Link
                      rel="noopener"
                      target="_blank"
                      href="https://www.instagram.com/obeta_electro/"
                    >
                      <InstagramLogo className={styles.instagramAndYoutubeLogos} />
                    </Link>
                    <Link
                      rel="noopener"
                      target="_blank"
                      href="https://www.youtube.com/user/obetaelectro"
                    >
                      <YoutubeLogo
                        className={clsx(styles.instagramAndYoutubeLogos, styles.marginLeftHalfRem)}
                      />
                    </Link>
                  </div>
                )}
              </Box>
            )}

            {desktop && (
              <Typography variant={'bodyBold'} className={styles.marginForKontakt}>
                Kontakt
              </Typography>
            )}

            {/* TODO: due to the hybrid mode - will be set back to !tabletWide */}
            {desktop && (
              <Box className={styles.boxIconFollowedByText}>
                {/* TODO: create new page/link for https://www.obeta.de/kontakt and use internal routing */}
                <Link target="_blank" rel="noopener" href={ShopRoutes.Contact}>
                  <SmsIcon className={styles.SmsIcon} />
                </Link>
                <Typography variant={'bodyBold'} className={styles.textAfterIcon}>
                  <Link
                    underline="none"
                    color="inherit"
                    target="_blank"
                    rel="noopener"
                    href={ShopRoutes.Contact}
                  >
                    Ansprechpartner
                  </Link>
                </Typography>
              </Box>
            )}

            {!tabletWide && (
              <Box className={styles.boxIconFollowedByText}>
                <Link target="_blank" href={`mailto: ${EmailAddress}`}>
                  {' '}
                  <MailIcon className={styles.SmsIcon} />{' '}
                </Link>
                <Typography variant={'bodyBold'} className={styles.textAfterIcon}>
                  <Link
                    underline="none"
                    color="inherit"
                    target="_blank"
                    href={`mailto: ${EmailAddress}`}
                  >
                    {t('FOOTER.CONTACT_US')}
                  </Link>
                </Typography>
              </Box>
            )}

            {desktop && (
              <Box>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://www.instagram.com/obeta_electro/"
                  className={styles.verticallyAligned}
                >
                  <InstagramLogo className={styles.instagramAndYoutubeLogos} />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://www.youtube.com/user/obetaelectro"
                >
                  <YoutubeLogo
                    className={clsx(styles.instagramAndYoutubeLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://itunes.apple.com/de/app/obeta-app/id1299005066?mt=8&at=10l6Xd"
                >
                  <AppStoreLogo
                    className={clsx(styles.appStoreAndGooglePlayLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
                <Link
                  rel="noopener"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=de.obeta.shop"
                >
                  <GooglePlayLogo
                    className={clsx(styles.appStoreAndGooglePlayLogos, styles.marginLeftHalfRem)}
                  />
                </Link>
              </Box>
            )}
          </div>
          {tabletWide && <LogoAndIconsAndContactUsTabletLandscape />}

          {(mobile || tablet) && (
            <div className={styles.divLogoAndIcons}>
              <Divider className={styles.divider} />
            </div>
          )}

          <div className={styles.mainDivLinks}>
            {(mobile || tablet) && <ListOfLinksForMobileAndTabletPortrait />}

            {(desktop || tabletWide) && <ListOfLinksForDesktopAndTabletLandscape />}
          </div>
        </div>
        <SectionMetalQuotationCopyrightNote
          aluminiumQuotation={aluminium}
          copperQuotation={copper}
          shopCopyrightByDomain={CopyRight}
        />
      </div>
    </div>
  )
}
