import { RemainingOrder } from '@obeta/models/lib/models'
import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'

export const remainingOrdersEntity: EntityDescriptor<
  Omit<RemoteSortedItem<RemainingOrder>, 'filtered'>
> = {
  name: 'remainingorders',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'remainingorders',
    version: 2,
    description: 'a document representing a remainingOrder',
    type: 'object',
    indexes: ['sortOrder'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          orderNo: {
            type: 'string',
          },
          position: {
            type: 'string',
          },
          articleId: {
            type: 'string',
          },
          ring: {
            type: 'string',
          },
          originRing: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          amount: {
            type: 'object',
          },
          sachNr: {
            type: 'string',
          },
          name: {
            type: 'string',
          },
          phone: {
            type: 'string',
          },
          date: {
            type: 'string',
          },
          commission: {
            type: 'string',
          },
          articleData: {
            type: 'object',
          },
        },
      },
    },
  },
  subscription: null,
}
