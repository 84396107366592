import {
  switchMap,
  catchError,
  map,
  concatMap,
  of,
  from,
  defer,
  timer,
  mergeMap,
  Observable,
} from 'rxjs'
import {
  ArticleActionTypes,
  loadCategoriesResult,
  LoadCategoriesAction,
  ChangeSearchHistoryAction,
  DeleteSearchHistoryItemAction,
  GetOxomiMetadataAction,
  getOxomiMetadataResult,
  GetStockAction,
  getStockResult,
} from '../actions/article-actions'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { ofType } from 'redux-observable'
import { noop } from '../actions'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { SearchHistory } from '../hooks'

import { ArticleSearchParamsWithId } from '@obeta/models/lib/models/Search'
import { MetaData } from '@obeta/models/lib/models/Meta/Meta'
import { SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'

import { chunks } from '@obeta/utils/lib/chunks'
import { requestOxomiMetaData } from '@obeta/utils/lib/requestOxomiMetaData'

export const articleSearchParamsChanged = async (
  db: RxDatabase<CollectionsOfDatabase>,
  newParams: ArticleSearchParamsWithId
) => {
  const paramsDoc = await db.getLocal<ArticleSearchParamsWithId>('lastSearch')
  if (!paramsDoc) {
    return false
  }

  const params = paramsDoc.toJSON()
  return newParams.id !== params.data.id
}

const obetaCategories = gql`
  query getObetaCategories {
    getObetaCategories {
      id
      title
    }
  }
`

const stockQuery = gql`
  query getStockForDetailsPage($sapIds: [String!]!, $storeIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      sapId
      stock(warehouseIds: $storeIds) {
        amount
        unit
        warehouseId
      }
    }
  }
`

export const createGetOxomiMetaDataEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$) =>
    actions$.pipe(
      ofType(ArticleActionTypes.GetOxomiMetaData),
      switchMap((action: GetOxomiMetadataAction) => {
        const obs = defer(async () => {
          const response = await requestOxomiMetaData(apolloClient)
          await db.upsertLocal('oxomi', {
            token: response.oxomiToken,
            portal: response.oxomiPortal,
          })
        })
        if (action.pollInterval) {
          return timer(0, action.pollInterval).pipe(switchMap(() => obs))
        }
        return obs
      }),
      map((_: MetaData) => getOxomiMetadataResult()),
      catchError((error) => {
        error.message = 'error in ' + createGetOxomiMetaDataEffect.name + ' ' + error.message
        handleError(error)
        return of(getOxomiMetadataResult())
      })
    )
}

export const createGetStockEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$) =>
    actions$.pipe(
      ofType(ArticleActionTypes.GetStock),
      switchMap((action: GetStockAction) => {
        const { storeIds, sapIds, batchSize = 50 } = action
        const c = chunks(sapIds, batchSize)
        return from(c).pipe(
          concatMap((chunkSapIds) => {
            return defer(() => {
              return from(
                apolloClient.query({
                  query: stockQuery,
                  variables: {
                    sapIds: chunkSapIds,
                    storeIds: storeIds,
                  },
                })
              )
            })
          }),
          mergeMap((res) => {
            const uniqueStocks: typeof res.data.getProducts = []
            res.data.getProducts.forEach((stockResult) => {
              if (!uniqueStocks.some((uniqueStock) => uniqueStock.sapId === stockResult.sapId)) {
                uniqueStocks.push(stockResult)
              }
            })
            return from(
              db.stocks.bulkUpsert(
                uniqueStocks.map((stock) => {
                  const { __typename, ...cpyStock } = stock
                  return cpyStock
                })
              )
            )
          })
        )
      }),
      map((_: MetaData) => getStockResult()),
      catchError((error) => {
        error.message = 'error in ' + createGetStockEffect.name + ' ' + error.message
        handleError(error)
        return of(getStockResult())
      })
    )
}

export const createLoadCategoriesEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  apolloClient
) => {
  return (actions$: Observable<LoadCategoriesAction>) =>
    actions$.pipe(
      ofType(ArticleActionTypes.LoadCategories),
      concatMap((action: LoadCategoriesAction) =>
        defer(async () => {
          await changeMetaData(db, 'categories', { isFetching: true })
          const categoriesResponse = await apolloClient.query({
            query: obetaCategories,
          })
          const response = categoriesResponse.data.getObetaCategories.map((category) => ({
            id: category.id,
            name: category.title,
          }))

          await syncDataWithDatabase('categories', response, 'id')
          await changeMetaData(db, 'categories', {
            isFetching: false,
          })
          return loadCategoriesResult(response)
        }).pipe(
          catchError((err: Error) => {
            err.message = 'error in ' + createLoadCategoriesEpic.name + ' ' + err.message
            handleError(err)

            return of(loadCategoriesResult(undefined, err))
          })
        )
      )
    )
}

export const createChangeSearchHistoryEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<ChangeSearchHistoryAction>) =>
    actions$.pipe(
      ofType(ArticleActionTypes.ChangeSearchHistory),
      concatMap((action: ChangeSearchHistoryAction) =>
        defer(async () => {
          const doc = await db.getLocal<SearchHistory>('searchHistory')
          await doc?.incrementalModify((data) => {
            if (data.history.includes(action.query)) {
              return data
            }
            data.history.push(action.query)
            if (data.history.length > 15) {
              data.history.shift()
            }
            return data
          })
        })
      ),
      map(() => noop()),
      catchError((err: Error) => {
        err.message = 'error in ' + createChangeSearchHistoryEpic.name + ' ' + err.message
        handleError(err)

        return of(noop())
      })
    )
}

export const createDeleteSearchHistoryEntryEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<DeleteSearchHistoryItemAction>) =>
    actions$.pipe(
      ofType(ArticleActionTypes.DeleteSearchHistoryEnty),
      concatMap((action: DeleteSearchHistoryItemAction) =>
        defer(async () => {
          const doc = await db.getLocal<SearchHistory>('searchHistory')
          await doc?.incrementalModify((data) => {
            data.history = data.history.filter((t) => {
              return t !== action.term
            })
            return data
          })
        })
      ),
      map(() => noop()),
      catchError((err: Error) => {
        err.message = 'error in ' + createDeleteSearchHistoryEntryEpic.name + ' ' + err.message
        handleError(err)

        return of(noop())
      })
    )
}

export const initAllArticleEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createLoadCategoriesEpic(db, syncDataWithDatabase, apolloClient),
    createChangeSearchHistoryEpic(db),
    createDeleteSearchHistoryEntryEpic(db),
    createGetOxomiMetaDataEffect(db, apolloClient),
    createGetStockEffect(db, apolloClient),
  ]
}
