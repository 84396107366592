import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'
import { CartTemplateItem } from '@obeta/schema'

//todo this should not be a remote sorted item or should it?
export const cartTemplateProductsEntity: EntityDescriptor<
  Omit<RemoteSortedItem<CartTemplateItem>, 'filtered'>
> = {
  name: 'carttemplateproducts',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'carttemplateproducts',
    version: 2,
    description: 'a document representing a carttemplateproducts entry',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          sapId: {
            type: 'string',
          },
          title: {
            type: 'string',
          },
          imageData: {
            type: 'object',
          },
          oxomiId: {
            type: 'string',
          },
          supplierId: {
            type: 'string',
          },
        },
      },
    },
  },
}
