import { GraphQLSyncPullOptions, RxCollection, RxDocument, RxJsonSchema } from 'rxdb'
import { BehaviorSubject, Observable } from 'rxjs'
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql'
import { ShoppingCart, SimpleShoppingCart } from '../ShoppingCart'
import {
  CartTemplate,
  Offer as OfferV2,
  OpenPosition,
  Order,
  ProductAggregate,
  ShoppingCart as ShoppingCartV2,
  Store as StoreV2,
  UserNotification,
} from '@obeta/schema'
import { RemainingOrder, ReturnShipment } from '../Order'
import { Metal } from '../Metal'
import { Offer } from '../Offer'
import { Store } from '../Stores'
import { CartTemplateItem } from '../CartTemplates/CartTemplate'
import { UserAddressV2 } from '../Users/UserV2'

export interface RemoteSortedItem<T> {
  sortOrder: number
  id: string
  filtered?: number
  item: T
}

export type SyncDataWithDatabase = (
  collectionName: EntityNames,
  requestData: object[],
  compareKey: string
) => Promise<void>

export type GetCollection$ = (collectionName: string) => Observable<RxCollection>
export type GetCollectionSync = (
  collectionName: string
) => RxGraphQLReplicationState<unknown, unknown> | undefined
export type GetCollectionSync$ = (
  collectionName: string
) => Observable<RxGraphQLReplicationState<unknown, unknown> | null>

export enum Adapter {
  indexeddb = 'indexeddb',
  memory = 'memory',
  capacitorsqlite = 'capacitor-sqlite',
}

export type EntityNames =
  | 'articles'
  | 'carts'
  | 'cartsv2'
  | 'cartsv2prices'
  | 'carttemplates'
  | 'carttemplateproducts'
  | 'categories'
  | 'client'
  | 'entitymeta'
  | 'markedcarts'
  | 'message'
  | 'news'
  | 'offersv2'
  | 'offersv3'
  | 'openpositions'
  | 'products'
  | 'simpleoffers'
  | 'stores'
  | 'storesv2'
  | 'stocks'
  | 'useraddressesv2'
  //old entities from app
  | 'orders'
  | 'returnshipments'
  | 'remainingorders'
  | 'simplecarts'
  | 'offers'

//ToDo: why is this not part of the generated schemas?
export interface ShoppingCartPricesV2 {
  cartId: string
  prices: unknown
}

export type ObetaModels =
  | ProductAggregate
  | ShoppingCart
  | ShoppingCartV2
  | ShoppingCartPricesV2
  | StoreV2
  | Store
  | UserNotification
  | CartTemplate
  | OfferV2
  | (OpenPosition & { initialPosition: number })
  | UserAddressV2
  | { frontendClientId: string }
  | RemoteSortedItem<
      | ProductAggregate
      | Order
      | ReturnShipment
      | RemainingOrder
      | SimpleShoppingCart
      | Metal
      | Offer
      | CartTemplateItem
    >

export interface EntityDescriptor<T = ObetaModels, C = unknown> {
  name: EntityNames
  schema: RxJsonSchema<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  migrationStrategies?: Record<
    number,
    (
      doc: RxDocument<{ [x: string]: unknown }, unknown>
    ) => RxDocument<{ [x: string]: unknown }, unknown> | null
  >
  pullSync?: GraphQLSyncPullOptions<T, C>
  subscription?: unknown
  authentication?: boolean
  localDocuments?: boolean
  liveInterval?: number
}

export interface IDbUtils {
  /**
   * Observable to pass most recent collection instance, needed as long as we still use recreateCollection
   */
  getCollection$: GetCollection$
  /**
   * Subject, that allows us to add effects dynamically
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  epicInit: BehaviorSubject<any>
  /**
   * temporary workaround to truncate a collection until we switch
   * to new RxStorage without pouch restrictions
   */
  syncDataWithDatabase: SyncDataWithDatabase
  /**
   * returns the replication instance of a given collection name if instantiated
   */
  getCollectionSync: GetCollectionSync
  /**
   * returns the replication instance of a given collection name if instantiated as observable
   */
  getCollectionSync$: GetCollectionSync$
}
