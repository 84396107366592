import { ButtonBase, Radio, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { useLocalSearchControlled } from '@obeta/data/lib/hooks/useLocalSearchControlled'
import { StoreV2, StoreV2Types } from '@obeta/models/lib/models/Stores/StoreV2'
import { updateUserSettings } from '@obeta/data/lib/actions/customer-actions'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import styles from './SelectStore.module.scss'
import { Modal, ModalProps } from '../modal/Modal'
import { Scrollbar } from '../scrollbar/Scrollbar'
import { ContactsCol, IStore, StoreAddressCol, StoreStockCol } from '../store/Store'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { IColumn, TableRow } from '../table-row/TableRow'
import { useCurrentProductIds } from '@obeta/data/lib/hooks/useCurrentProductIds'
import { useWarehouse } from '@obeta/data/lib/hooks/useWarehouse'
import { useCheckIsCashCustomer } from '@obeta/data/lib/hooks/useCheckIsCashCustomer'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { DarkButton, TertiaryButton } from '../custom-button/CustomButton'

// Hooks
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'

// UI
import { SearchField } from '../search-field/SearchField'

interface IRenderStoreItemProps {
  store: IStore
  canSelect: boolean | undefined
  render: (store: IStore) => React.ReactChild
  active: boolean
  setSelectedStoreId: React.Dispatch<React.SetStateAction<string | null>>
}

/**
 * this is performance optimization component.
 * it allows to reduce unnecessary re-renders when "setSelectedStoreId" changes (usually)
 * or some other props.
 *
 * Right now initial rendering takes between 97 - 225 ms (dev mode)
 * In case we need to make this modal more performant we need to add virtualization.
 */
const RenderStoreItem = React.memo<IRenderStoreItemProps>(function RenderStoreItem(props) {
  const { store, render, canSelect, active, setSelectedStoreId } = props
  const storeEl = render(store)
  if (canSelect) {
    return (
      <div key={store.name} className={clsx(styles.storeWrapper, styles.storeWithRadio)}>
        <Radio
          className={styles.selectStore}
          value={store.id}
          checked={active}
          onChange={() => {
            setSelectedStoreId(store.id)
          }}
        />
        {storeEl}
      </div>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{storeEl}</>
})

export interface IStoresByRegion {
  region: string
  stores: IStore[]
}

interface IBaseSelectStoreProps extends Omit<ModalProps, 'children'> {
  productTitle: string
  productUnit: string
  stores: StoreV2[]
  filterStores: (store: IStore) => boolean
  renderStore: (store: IStore) => React.ReactChild
  renderFooter: (onClose: () => void, selectedStoreId: string | null) => JSX.Element
  canSelect?: boolean
  myStore?: boolean
}

const searchKeys = [
  'region',
  'address.city',
  'address.zipCode',
  'address.street',
  'address.name1',
  'address.name2',
  'phoneNumber',
]

const BaseSelectStore: React.FC<IBaseSelectStoreProps> = (props) => {
  const {
    productTitle,
    productUnit,
    stores,
    filterStores,
    renderStore,
    canSelect,
    myStore,
    renderFooter,
    ...rest
  } = props
  const { search } = useLocalSearchControlled()
  const { isLoggedIn, user } = useUserDataV2()
  const { mobile, tabletAll } = useBreakpoints()

  const [filteredStores, setFilteredStores] = useState<StoreV2[]>(stores)
  const [searchText, setSearchText] = useState<string>('')

  const stocksIds = useMemo(() => {
    return stores.map((store) => store.id)
  }, [stores])

  const productIds = useCurrentProductIds()
  const stocks = useWarehouse(productIds, stocksIds, isLoggedIn)
  const { isCashCustomer } = useCheckIsCashCustomer()

  const userStoreId = user?.settings?.defaultStoreId || null
  const [selectedStoreId, setSelectedStoreId] = useState<string | null>(userStoreId)
  useEffect(() => {
    setSelectedStoreId(userStoreId)
  }, [userStoreId])

  // TODO: maybe it makes sense to make fuse.js to filter already mapped data (IStoresByRegion)
  // for performance purposes.
  // We have similar logic for etims in one of branches
  const storesData = useMemo<IStoresByRegion[]>(() => {
    return filteredStores.reduce<IStoresByRegion[]>((acc, current) => {
      let storesByRegion = acc.find((storesByRegion) => {
        return storesByRegion.region === current.region
      })

      const stock = stocks.find((s) => {
        return s.warehouseId === current.id
      })

      if (current.type === StoreV2Types.renzbox && isCashCustomer) {
        return acc
      }

      const store: IStore = {
        id: current.id,
        productUnit: productUnit, // productUnit doesn't change much, so it should be a problem to make it part of the store
        name: current.address.name1,
        city: current.address.city,
        postalCode: current.address.zipCode,
        street: current.address.street,
        phone: current.phoneNumber,
        availableItems: stock?.amount || null,
        openingHours: current.openingHours,
        houseNumber: current.address.houseNumber,
        position: {
          longitude: current.longitude,
          latitude: current.latitude,
          distance: current.distance,
        },
      }

      if (!filterStores(store)) {
        return acc
      }

      if (!storesByRegion) {
        storesByRegion = { region: current.region, stores: [] }
        acc.push(storesByRegion)
      }

      storesByRegion.stores.push(store)

      return acc
    }, [])
  }, [filteredStores, stocks, isCashCustomer, productUnit, filterStores])

  // Search stores by search text and  keys
  useDebouncedEffect(
    () => {
      setFilteredStores(search(stores, searchText, searchKeys))
    },
    [searchText, stores, searchKeys],
    500
  )

  const { t } = useTranslation()

  const renderStores = (storesData) => {
    return storesData.map((storesData) => {
      return (
        <div key={storesData.region} className={styles.storesByRegion}>
          <div className={styles.storesHeader}>
            <Typography variant="boldText">{storesData.region}</Typography>
          </div>
          <div className={styles.stores}>
            {storesData.stores.map((store) => {
              return (
                <RenderStoreItem
                  key={store.name}
                  active={store.id === selectedStoreId}
                  store={store}
                  canSelect={canSelect}
                  render={renderStore}
                  setSelectedStoreId={setSelectedStoreId}
                />
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <Modal isFullScreen={mobile} fitContent={!mobile} {...rest}>
      <div
        className={clsx(
          styles.root,
          mobile && styles.rootMobileSpecific,
          tabletAll && styles.rootTabletSpecific
        )}
      >
        <div className={styles.header}>
          <Typography variant="headline4Bold" component="h4">
            {myStore
              ? t<string>('HEADER.CHANGE_MY_STORE')
              : t<string>('ARTICLE_DETAIL.MORE_STORES')}
          </Typography>
          {!mobile && (
            <ButtonBase onClick={rest.onClose}>
              <CloseIcon />
            </ButtonBase>
          )}
        </div>
        <Typography variant="body">
          {myStore
            ? t<string>('HEADER.ARTICLE_STOCK_FOR_STORE')
            : t<string>('ARTICLE_DETAIL.SELECT_STORE_DESCRIPTION', { article: productTitle })}
        </Typography>
        <SearchField
          placeholder={
            myStore ? t('HEADER.NAME_STREET_ZIP_CODE_CITY_ETC') : t('ARTICLE_DETAIL.ZIP_CODE_NAME')
          }
          value={searchText}
          onChange={setSearchText}
          onReset={() => setSearchText('')}
        />
        <Scrollbar className={styles.storesList} ident="xs" size="md">
          {renderStores(storesData)}
        </Scrollbar>
        {!mobile && (
          <>
            <LightGrayDivider />
            {renderFooter(rest.onClose, selectedStoreId)}
          </>
        )}
        {mobile && (
          <div className={styles.mobileFooter}>{renderFooter(rest.onClose, selectedStoreId)}</div>
        )}
      </div>
    </Modal>
  )
}

interface ISelectStoreProps
  extends Pick<
    IBaseSelectStoreProps,
    | 'productTitle'
    | 'productUnit'
    | 'renderStore'
    | 'filterStores'
    | 'canSelect'
    | 'myStore'
    | 'renderFooter'
  > {
  selectStoreOpen: boolean
  setSelectStoreOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const withStocksOnlyStores = (store: IStore) => store.availableItems !== null
const allStores = () => true

const baseStoreRow = (
  store: IStore,
  before: IColumn<keyof IStore, IStore>[] = [],
  after: IColumn<keyof IStore, IStore>[] = []
) => {
  return (
    <TableRow
      className={styles.storeWrapper}
      columns={[
        ...before,
        {
          accessor: 'id',
          flex: 1,
          format: (_, store) => {
            return <StoreAddressCol store={store} />
          },
        },
        {
          flex: 1,
          accessor: 'name',
          format: (_, store) => {
            return <ContactsCol store={store} />
          },
        },
        ...after,
      ]}
      data={store}
    />
  )
}

const withStock = (storeRow: typeof baseStoreRow) => {
  return (
    store: IStore,
    before: IColumn<keyof IStore, IStore>[] = [],
    after: IColumn<keyof IStore, IStore>[] = []
  ) => {
    return storeRow(store, before, [
      {
        flex: 0.6,
        accessor: 'city',
        alignment: 'right',
        format: (_, store) => {
          return <StoreStockCol store={store} />
        },
      },
      ...after,
    ])
  }
}

const BaseFooter: React.FC<{ selectedStoreId: string | null; onClose: () => void }> = (props) => {
  const { selectedStoreId, onClose } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <div className={styles.actions}>
      <TertiaryButton size="large" onClick={onClose}>
        {t<string>('ARTICLE_DETAIL.BACK_TO_SHOP')}
      </TertiaryButton>
      <DarkButton
        onClick={() => {
          if (!selectedStoreId) {
            return
          }

          dispatch(
            updateUserSettings({
              defaultStoreId: selectedStoreId,
            })
          )
          onClose()
        }}
        disabled={!selectedStoreId}
      >
        {t<string>('SHOPPING_CART.CHOOSE_BRANCH')}
      </DarkButton>
    </div>
  )
}

const MetaInfoFooter: React.FC<{ onClose: () => void }> = (props) => {
  const { onClose } = props
  const { t } = useTranslation()

  return (
    <div className={styles['btn-close']}>
      <DarkButton onClick={onClose}>{t<string>('ARTICLE_DETAIL.CLOSE')}</DarkButton>
    </div>
  )
}

const SelectStore: React.FC<ISelectStoreProps> = (props) => {
  const { selectStoreOpen, setSelectStoreOpen, ...restSelectStoreProps } = props
  const stores = useEntities<StoreV2>('storesv2')

  if (!selectStoreOpen) {
    return null
  }

  return (
    <BaseSelectStore
      stores={stores}
      open={selectStoreOpen}
      onClose={() => {
        setSelectStoreOpen(false)
      }}
      {...restSelectStoreProps}
    />
  )
}

type ContextSelectStoreProps = Omit<
  ISelectStoreProps,
  'renderStore' | 'filterStores' | 'canSelect' | 'renderFooter'
>

export const HeaderSelectStore: React.FC<ContextSelectStoreProps> = (props) => {
  return (
    <SelectStore
      filterStores={allStores}
      renderStore={baseStoreRow}
      renderFooter={(onClose, selectedStoreId) => {
        return <BaseFooter onClose={onClose} selectedStoreId={selectedStoreId} />
      }}
      canSelect={true}
      myStore={true}
      {...props}
    />
  )
}

const renderArticleStore = withStock(baseStoreRow)

export const MetaInfoSelectStore: React.FC<ContextSelectStoreProps> = (props) => {
  return (
    <SelectStore
      filterStores={withStocksOnlyStores}
      renderStore={renderArticleStore}
      renderFooter={(onClose) => {
        return <MetaInfoFooter onClose={onClose} />
      }}
      {...props}
    />
  )
}
