import { useHistory } from './useHistoryApi'
import React from 'react'

export const useRedirect = () => {
  const history = useHistory()
  const redirectToStartPage = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault()
    }
    if (
      window.location.pathname + window.location.search === '/' ||
      window.location.pathname + window.location.search === ''
    ) {
      window.location.reload()
    } else {
      history.push('/')
    }
  }
  return { redirectToStartPage }
}
