interface Action {
  type: OfferActionTypes
}

export enum OfferActionTypes {
  LoadSimpleOffers = '[Offers] Load Simple Offers',
  LoadSimpleOffersSuccess = '[Offers] Load Simple Offers Success',
  LoadSimpleOffersError = '[Offers] Load Simple Offers Error',
  LoadOffersSuccess = '[Offers] Load Offers Success',
  LoadOffersError = '[Offers] Load Offers Error',
  LoadSimpleOffersFilterSort = '[Offers] Load Simple Offers then Filter and Sort',
  LoadSimpleOffersFilterSortSuccess = '[Offers] Load Simple Offers, Filter and Sort Success',
  LoadSimpleOffersFilterSortError = '[Offers] Load Simple Offers, Filter and Sort Error',
  FilterOffers = '[Offers] Filter Offers',
  FilterOffersSuccess = '[Offers] Filter Offers Success',
  FilterOffersError = '[Offers] Filter Offers Error',
  ResetFilter = '[Offers] Reset Filter',
  ResetFilterResult = '[Offers] Reset Filter Result',
  CreateDownloadableOffer = '[Offers] Create Downloadable Offer',
  CreateDownloadableOfferResult = '[Offers] Create Downloadable Offer Result',
}

export interface LoadSimpleOffersAction extends Action {
  readonly type: OfferActionTypes.LoadSimpleOffers
}

export const loadSimpleOffers = (): LoadSimpleOffersAction => ({
  type: OfferActionTypes.LoadSimpleOffers,
})

export interface LoadSimpleOffersSuccessAction extends Action {
  readonly type: OfferActionTypes.LoadSimpleOffersSuccess
  offerIds: string[]
}

export const loadSimpleOffersSuccess = (offerIds: string[]): LoadSimpleOffersSuccessAction => ({
  type: OfferActionTypes.LoadSimpleOffersSuccess,
  offerIds: offerIds,
})

export interface LoadSimpleOffersErrorAction extends Action {
  readonly type: OfferActionTypes.LoadSimpleOffersError
  error?: Error
}

export const loadSimpleOffersError = (error?: Error): LoadSimpleOffersErrorAction => ({
  type: OfferActionTypes.LoadSimpleOffersError,
  error,
})

export interface LoadOffersSuccessAction extends Action {
  readonly type: OfferActionTypes.LoadOffersSuccess
}

export const loadOffersSuccess = (): LoadOffersSuccessAction => ({
  type: OfferActionTypes.LoadOffersSuccess,
})

export interface LoadOffersErrorAction extends Action {
  readonly type: OfferActionTypes.LoadOffersError
  error?: Error
}

export const loadOffersError = (error?: Error): LoadOffersErrorAction => ({
  type: OfferActionTypes.LoadOffersError,
  error,
})

export interface FilterOfferAction extends Action {
  readonly type: OfferActionTypes.FilterOffers
  searchString: string
}

export const filterOffers = (searchString: string): FilterOfferAction => ({
  type: OfferActionTypes.FilterOffers,
  searchString,
})

export interface FilterOfferSuccessAction extends Action {
  readonly type: OfferActionTypes.FilterOffersSuccess
}

export const filterOffersSuccess = (): FilterOfferSuccessAction => ({
  type: OfferActionTypes.FilterOffersSuccess,
})

export interface FilterOfferErrorAction extends Action {
  readonly type: OfferActionTypes.FilterOffersError
  error?: Error
}

export const filterOffersError = (error?: Error): FilterOfferErrorAction => ({
  type: OfferActionTypes.FilterOffersError,
  error,
})

export interface ResetFilterAction extends Action {
  readonly type: OfferActionTypes.ResetFilter
}
export const resetFilterAction = (): ResetFilterAction => ({
  type: OfferActionTypes.ResetFilter,
})

export interface ResetFilterResultAction extends Action {
  readonly type: OfferActionTypes.ResetFilterResult
}
export const resetFilterResultAction = (): ResetFilterResultAction => ({
  type: OfferActionTypes.ResetFilterResult,
})

export interface CreateDownloadableOfferAction extends Action {
  readonly type: OfferActionTypes.CreateDownloadableOffer
  offerId: string
}
export const createDownloadableOfferAction = (offerId: string): CreateDownloadableOfferAction => ({
  type: OfferActionTypes.CreateDownloadableOffer,
  offerId,
})
export interface CreateDownloadableOfferResultAction extends Action {
  readonly type: OfferActionTypes.CreateDownloadableOfferResult
  offerLinkCreated: boolean
}
export const getCreateDownloadableOfferResult = (
  offerLinkCreated: boolean
): CreateDownloadableOfferResultAction => ({
  type: OfferActionTypes.CreateDownloadableOfferResult,
  offerLinkCreated,
})

export type OfferActionsUnion =
  | LoadSimpleOffersAction
  | LoadSimpleOffersSuccessAction
  | LoadSimpleOffersErrorAction
  | LoadOffersSuccessAction
  | LoadOffersErrorAction
  | FilterOfferAction
  | FilterOfferSuccessAction
  | FilterOfferErrorAction
  | ResetFilterAction
  | CreateDownloadableOfferAction
