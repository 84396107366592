import {
  map,
  catchError,
  retry,
  concatMap,
  Observable,
  of,
  from,
  defer,
  filter,
  firstValueFrom,
} from 'rxjs'
import { SimpleCartActionTypes, LoadSimpleCartsAction, loadSimpleCartsResult } from '../actions'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import { ofType } from 'redux-observable'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'
import { AvailablePermissions } from '../hooks/useUserData'
import { RxDatabase, RxLocalDocument } from 'rxdb'
import { Customer } from '@obeta/models/lib/models/CustomerData/Customer'
import { RemoteSortedItem, SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'
import { SimpleShoppingCart } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'

export interface ProjectsResponse {
  data: SimpleShoppingCart[]
  messages: [
    {
      type: string
      message: string
    }
  ]
}

export const createLoadSimpleCartsEpic = (
  db: RxDatabase,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadSimpleCartsAction>) =>
    actions$.pipe(
      ofType(SimpleCartActionTypes.LoadSimpleCarts),
      concatMap(async () => {
        /** Before proceed, await for the 'user' doc to be filled with some data */
        const user$ = db
          .getLocal$<Customer>('user')
          .pipe(filter((user) => !!user?.get('general.permissions.subUser')))

        const user = (await firstValueFrom(user$)) as RxLocalDocument<RxDatabase, Customer>
        return user.toJSON().data
      }),
      concatMap((user: Customer) =>
        from(changeMetaData(db, 'simplecarts', { isFetching: true })).pipe(
          concatMap(() =>
            Axios.get('user/projects').pipe(
              retry(1),
              concatMap((result: AxiosResponse<ProjectsResponse>) => {
                return defer(async () => {
                  const simpleCarts = result.data.data

                  const showSelectedProject =
                    user.general.permissions.subUser.indexOf(
                      AvailablePermissions.showSelectedProject
                    ) !== -1
                  if (!showSelectedProject || simpleCarts.length === 0) {
                    const defaultCart = {
                      id: '0',
                      name: 'Warenkorb',
                    }
                    simpleCarts.unshift(defaultCart)
                  }
                  const records: RemoteSortedItem<SimpleShoppingCart>[] = simpleCarts.map(
                    (record, idx) => ({
                      item: record,
                      id: record.id,
                      sortOrder: idx,
                    })
                  )
                  await syncDataWithDatabase('simplecarts', records, 'id')

                  await changeMetaData(db, 'simplecarts', { isFetching: false })
                }).pipe(map(() => loadSimpleCartsResult(result.data.data)))
              }),
              catchError((error) => {
                error.message =
                  'error while processing ' + createLoadSimpleCartsEpic.name + ' ' + error.message
                handleError(error)
                return of(loadSimpleCartsResult(undefined, error))
              })
            )
          )
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createLoadSimpleCartsEpic.name + ' ' + error.message
        handleError(error)
        return of(loadSimpleCartsResult(undefined, error))
      })
    )
}
