import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  retry,
  concatMap,
  Observable,
  of,
  from,
  defer,
  mergeMap,
} from 'rxjs'
import {
  OrderActionTypes,
  LoadAllOrdersAction,
  LoadReturnShipmentsAction,
  LoadRemainingOdersAction,
  loadAllOrdersResult,
  loadReturnShipmentsResult,
  loadRemainingOrdersResult,
  loadAllOrders as loadAllOrdersFunc,
  loadReturnShipmentsAction as loadReturnShipmentsFunc,
  ChangeOrderSortAndFilterAction,
  ChangeReturnShipmentsSortAndFilterAction,
  ChangeRemainingOrdersPaginationAction,
  loadRemainingOrders,
  UpdateOrderMetaDataGraphQLAction,
  UpdateOrderMetaDataGraphQLResult,
  UpdateOrderMetaDataGraphQLResultAction,
} from '../actions/order-actions'

import { AllOrder } from '@obeta/models/lib/models/Order/AllOrder'
import { AllOrdersSuppliersCombined } from '@obeta/models/lib/models/Order/AllOrdersSuppliersCombined'
import { MetaData, Pagination, SearchParams } from '@obeta/models/lib/models/Meta/Meta'
import { RemoteSortedItem, SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'
import {
  RemainingOrdersCountCombined,
  RemainingOrder,
} from '@obeta/models/lib/models/Order/RemainingOrder'
import {
  ReturnShipment,
  ReturnShipmentCountCombined,
} from '@obeta/models/lib/models/Order/ReturnShipment'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData, getMetaData } from '@obeta/utils/lib/epics-helpers'
import { ofType } from 'redux-observable'
import Axios from 'axios-observable'
import { AxiosResponse, AxiosError } from 'axios'
import { initialMeta } from '@obeta/utils/lib/epics-helpers'
import dayjs from 'dayjs'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { noop } from '../actions'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { UPDATE_ORDER_META_DATA_MUTATION } from '../entities/updateOrderMetaDataMutationProps'

export const getDefaultDateSpan = () => ({
  start: dayjs().subtract(6, 'months'),
  end: dayjs(),
  text: 'letztes ½ Jahr',
})

export enum SEARCH_TYPE {
  ALL_ORDERS = '1',
  BACK_ORDERS = '2',
  RETURN_SHIPMENTS = '3',
}

export interface AllOrderResponse {
  data: AllOrdersSuppliersCombined
  messages: string[]
}

interface RemainingOrderResponse {
  data: RemainingOrdersCountCombined
  messages: string[]
}

interface ReturnShipmentResponse {
  data: ReturnShipmentCountCombined
  messages: string[]
}

export const ORDER_SORT_IDS: { [key: string]: number } = {
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERDATE_DESC': 6,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERDATE_ASC': 5,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_ASC': 1,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_DESC': 2,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.COMMISSION_ASC': 3,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.COMMISSION_DESC': 4,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERID_ASC': 7,
  'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERID_DESC': 8,
}

export const RETURNSHIPPMENTS_SORT_IDS: { [key: string]: number } = {
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.RETURN_NO_ASC': 1,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.RETURN_NO_DESC': 2,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.DATE_ASC': 3,
  'ORDER_OVERVIEW.RETURN_SHIPMENTS.RETURN_SHIPMENTS_FILTER.SORT_MODAL.DATE_DESC': 4,
}

export const defaultSortType =
  ORDER_SORT_IDS['ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.REFERENCENO_ASC']

export const buildUrl = (
  params: SearchParams,
  pagination: Pagination,
  searchType: string,
  page: number
): string => {
  return (
    'orders/' +
    (params.startDate ? params.startDate : 0) +
    '/' +
    (params.endDate ? params.endDate : 0) +
    '/' +
    (params.sortOption ? ORDER_SORT_IDS[params.sortOption] : defaultSortType) +
    '/' +
    page +
    '/' +
    pagination.itemsPerPage +
    '/' +
    (params.supplier ? params.supplier.id : '0') +
    '/' +
    searchType
  )
}

export const createChangeOrdersSortAndFilterEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<ChangeOrderSortAndFilterAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.ChangeSortAndFilter),
      concatMap((action: ChangeOrderSortAndFilterAction) =>
        defer(async () => {
          const newSearchParams = {
            ...action.searchParams,
            searchString: action.searchParams.searchString,
          }
          if (action.searchParams.startDate) {
            newSearchParams.startDate = action.searchParams.startDate
          }
          if (action.searchParams.endDate) {
            newSearchParams.endDate = action.searchParams.endDate
          }
          await changeMetaData(db, 'orders', {
            searchParams: newSearchParams,
          })
          return action
        })
      ),
      map((action: ChangeOrderSortAndFilterAction) => loadAllOrdersFunc(1))
    )
}

export const createChangeReturnShipmentsSortAndFilterEpic = (
  db: RxDatabase<CollectionsOfDatabase>
) => {
  return (actions$: Observable<ChangeReturnShipmentsSortAndFilterAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.ChangeReturnShipmentsSortAndFilter),
      concatMap((action: ChangeReturnShipmentsSortAndFilterAction) =>
        defer(async () => {
          await changeMetaData(db, 'returnshipments', {
            searchParams: {
              ...action.searchParams,
              searchString: action.searchParams.searchString,
            },
            pagination: {
              currentPage: action.page,
            },
          })
          return action
        })
      ),
      map((action: ChangeReturnShipmentsSortAndFilterAction) =>
        loadReturnShipmentsFunc(action.page)
      )
    )
}

export const createLoadAllOrdersEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadAllOrdersAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.LoadAllOrders),
      concatMap((action: LoadAllOrdersAction) =>
        defer(async () => {
          await changeMetaData(db, 'orders', {
            isFetching: action.page === 1,
            lastUpdated: new Date().getTime(),
            pagination: {
              currentPage: action.page,
            },
          })
          return await getMetaData(db, 'orders')
        }).pipe(withLatestFrom(of(action)))
      ),
      concatMap(([metaData, action]: [MetaData, LoadAllOrdersAction]) => {
        if (!metaData.searchParams.startDate) {
          metaData.searchParams.startDate = getDefaultDateSpan().start.format('YYYY-MM-DD')
        }
        if (!metaData.searchParams.endDate) {
          metaData.searchParams.endDate = getDefaultDateSpan().end.format('YYYY-MM-DD')
        }
        // add sortOption here aswell, if it was set as a fallback to the ui
        if (!metaData.searchParams.sortOption) {
          metaData.searchParams.sortOption =
            'ORDER_OVERVIEW.ALL.ORDER_FILTER.SORT_MODAL.ORDERDATE_DESC'
        }
        return Axios.request({
          url: buildUrl(
            metaData.searchParams,
            metaData.pagination,
            SEARCH_TYPE.ALL_ORDERS,
            action.page
          ),
          params: {
            orderSearchString:
              metaData.searchParams.searchString === '' ? '*' : metaData.searchParams.searchString,
          },
        }).pipe(
          retry(1),
          concatMap((response: AxiosResponse<AllOrderResponse>) =>
            defer(async () => {
              const data: AllOrdersSuppliersCombined = response.data.data
              const orders = data.orders
              const indexBase = action.page * metaData.pagination.itemsPerPage
              const records: RemoteSortedItem<AllOrder>[] = orders.map((record, idx) => ({
                sortOrder: indexBase + idx,
                id: record.id,
                item: record,
              }))

              if (action.page === 1) {
                await syncDataWithDatabase('orders', records, 'id')
              } else {
                await db.orders.bulkUpsert(records)
              }

              await changeMetaData(db, 'orders', {
                isFetching: false,
                hitCount: response.data.data.hitCount,
                suppliers: response.data.data.suppliers,
              })
              return response
            })
          ),
          map((response: AxiosResponse<AllOrderResponse>) =>
            loadAllOrdersResult(response.data.data)
          ),
          catchError((error: AxiosError) => {
            error.message =
              'error while processing ' + createLoadAllOrdersEpic.name + ' ' + error.message
            handleError(error)

            return defer(async () => {
              await changeMetaData(db, 'orders', {
                isFetching: false,
                hitCount: -1,
                suppliers: [],
              })
              return loadAllOrdersResult(undefined, error)
            })
          })
        )
      }),
      catchError((err: Error) => {
        err.message = 'error in ' + createLoadAllOrdersEpic.name + ' ' + err.message
        handleError(err)

        return of(loadAllOrdersResult(undefined, err))
      })
    )
}

export const createLoadReturnShipments = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadReturnShipmentsAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.LoadReturnShipments),
      concatMap((action: LoadReturnShipmentsAction) =>
        from(changeMetaData(db, 'returnshipments', { isFetching: action.page === 1 })).pipe(
          withLatestFrom(of(action))
        )
      ),
      switchMap(([metaData, action]: [MetaData, LoadReturnShipmentsAction]) =>
        Axios.request({
          url: buildUrl(
            metaData.searchParams,
            metaData.pagination,
            SEARCH_TYPE.RETURN_SHIPMENTS,
            action.page
          ),
          params: {
            orderSearchString:
              metaData.searchParams.searchString === '' ? '*' : metaData.searchParams.searchString,
          },
        }).pipe(
          retry(1),
          concatMap((response: AxiosResponse<ReturnShipmentResponse>) =>
            defer(async () => {
              const data: ReturnShipmentCountCombined = response.data.data
              const indexBase = action.page * metaData.pagination.itemsPerPage
              // api responds with null instead of an empty array, so we will normalize it here
              const orders = data.orders || []
              const records: RemoteSortedItem<ReturnShipment>[] = orders.map((record, idx) => ({
                sortOrder: indexBase + idx,
                id: record.id,
                item: record,
              }))

              if (action.page === 1) {
                await syncDataWithDatabase('returnshipments', records, 'id')
              } else {
                await db.returnshipments.bulkUpsert(records)
              }

              await changeMetaData(db, 'returnshipments', {
                isFetching: false,
                hitCount: response.data.data.hitCount,
              })
              return response
            })
          ),
          map((response: AxiosResponse<ReturnShipmentResponse>) =>
            loadReturnShipmentsResult(response.data.data)
          ),
          catchError((err: Error) => {
            err.message = 'error in ' + createLoadAllOrdersEpic.name + ' ' + err.message
            handleError(err)

            return defer(async () => {
              await changeMetaData(db, 'returnshipments', {
                isFetching: false,
              })
              return loadReturnShipmentsResult(undefined, err)
            })
          })
        )
      )
    )
}

export const createChangeRemainingOrdersPaginationEpic = (
  db: RxDatabase<CollectionsOfDatabase>
) => {
  return (actions$: Observable<ChangeRemainingOrdersPaginationAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.ChangeRemainingOrdersPagination),
      concatMap((action: ChangeRemainingOrdersPaginationAction) =>
        defer(async () => {
          await changeMetaData(db, 'remainingorders', {
            pagination: {
              currentPage: action.page,
              itemsPerPage: 10,
            },
          })
          return action
        })
      ),
      map((action: ChangeRemainingOrdersPaginationAction) => loadRemainingOrders(action.page))
    )
}

export const createLoadRemainingOrdersEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadRemainingOdersAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.LoadRemainingOders),
      concatMap((action: LoadRemainingOdersAction) =>
        from(changeMetaData(db, 'remainingorders', { isFetching: action.page === 1 })).pipe(
          withLatestFrom(of(action))
        )
      ),
      concatMap(([metaData, action]: [MetaData, LoadRemainingOdersAction]) =>
        Axios.request({
          url: buildUrl(
            metaData.searchParams,
            metaData.pagination,
            SEARCH_TYPE.BACK_ORDERS,
            action.page
          ),
          params: {
            orderSearchString: metaData.searchParams.searchString,
          },
        }).pipe(
          retry(1),
          concatMap((response: AxiosResponse<RemainingOrderResponse>) =>
            defer(async () => {
              const indexBase = action.page * metaData.pagination.itemsPerPage
              const data: RemainingOrdersCountCombined = response.data.data
              const orders = data.orders
              const records: RemoteSortedItem<RemainingOrder>[] = orders.map((record, idx) => ({
                sortOrder: indexBase + idx,
                id: record.orderNo + '-' + record.articleId,
                item: record,
              }))
              if (action.page === 1) {
                await syncDataWithDatabase('remainingorders', records, 'id')
              } else {
                await db.remainingorders.bulkUpsert(records)
              }
              await changeMetaData(db, 'remainingorders', {
                isFetching: false,
                hitCount: response.data.data.hitCount,
              })
              return response
            })
          ),
          map((response: AxiosResponse<RemainingOrderResponse>) =>
            loadRemainingOrdersResult(response.data.data)
          ),
          catchError((err: Error) => {
            err.message = 'error in ' + createLoadRemainingOrdersEpic.name + ' ' + err.message
            handleError(err)
            return defer(async () => {
              await changeMetaData(db, 'remainingorders', { isFetching: false })
              return loadRemainingOrdersResult(undefined, err)
            })
          })
        )
      )
    )
}

export const createResetSearchParams = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$) => {
    return actions$.pipe(
      ofType(OrderActionTypes.ResetSearchParams),
      concatMap(() => {
        return defer(async () => {
          await changeMetaData(db, 'orders', {
            searchParams: initialMeta.searchParams,
            lastUpdated: new Date().getTime(),
          })
        })
      }),
      map(() => noop()),
      catchError((err: Error) => {
        err.message = 'error in ' + createResetSearchParams.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const updateOrderMetaDataGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateOrderMetaDataGraphQLAction>) =>
    actions$.pipe(
      ofType(OrderActionTypes.UpdateOrderMetaDataGraphQL),
      concatMap((action: UpdateOrderMetaDataGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate({
            mutation: UPDATE_ORDER_META_DATA_MUTATION,
            variables: {
              input: action.input,
            },
            fetchPolicy: 'no-cache',
          })
          return response.data?.updateOrderMetaData
        }).pipe(
          retry(1),
          mergeMap((result: UpdateOrderMetaDataGraphQLResultAction) =>
            of(
              UpdateOrderMetaDataGraphQLResult(
                result.success,
                result.errorCode,
                result.errorMessage,
                result.orderId
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              updateOrderMetaDataGraphQLEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(UpdateOrderMetaDataGraphQLResult(false, '', '', error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + updateOrderMetaDataGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(UpdateOrderMetaDataGraphQLResult(false, '', '', error))
      })
    )
}

export const initAllOrderEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createLoadAllOrdersEpic(db, syncDataWithDatabase),
    createLoadRemainingOrdersEpic(db, syncDataWithDatabase),
    createLoadReturnShipments(db, syncDataWithDatabase),
    createChangeOrdersSortAndFilterEpic(db),
    createChangeRemainingOrdersPaginationEpic(db),
    createChangeReturnShipmentsSortAndFilterEpic(db),
    createResetSearchParams(db),
    updateOrderMetaDataGraphQLEffect(db, apolloClient),
  ]
}
