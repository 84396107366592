import {
  BehaviorSubject,
  interval,
  map,
  NEVER,
  Observable,
  switchMap,
  tap,
  merge,
  Subscription,
} from 'rxjs'
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql'

import { flatClone } from 'rxdb'
import { syncEntities } from '@obeta/data/lib/entities'
import { EntityDescriptor, EntityNames } from '@obeta/models/lib/models'

interface IResyncState<T, C> {
  replState: RxGraphQLReplicationState<T, C>
  resyncInterval: number | null
}

type TEntityResync<T, C> = BehaviorSubject<IResyncState<T, C> | null>

export class Resync<EntityName = string, T = unknown, C = unknown> {
  private resync = new Map<EntityName, TEntityResync<T, C>>()
  private subscription: Subscription

  constructor() {
    /**
     * Initiates {resync} map with empty values for all possible entities (see syncEntities)
     * resyncState$ BS should be persistent
     * */
    syncEntities.forEach((ent: EntityDescriptor) => {
      this.resync.set(ent.name as unknown as EntityName, new BehaviorSubject(null))
    })

    /** TODO consider unsubscribing. Is it needed? */
    this.subscription = merge(
      ...Array.from(this.resync.values()).map(this.getResyncPipe)
    ).subscribe()
  }

  private getResyncPipe = (resyncState$: TEntityResync<T, C>) => {
    return resyncState$.pipe(
      switchMap((entityResync) => {
        if (!entityResync || !entityResync.resyncInterval) {
          return NEVER
        }

        const { resyncInterval, replState } = entityResync

        return interval(resyncInterval).pipe(tap(() => replState.reSync()))
      })
    )
  }

  private _getEntityResync = (entityName: EntityName) => {
    return this.resync.get(entityName) as TEntityResync<T, C>
  }

  /**
   * Clears the meta data of a replication storage in order to reset previous replications checkpoints
   * We query all documents from the metaInstance (replication meta storage) and remove them,
   * so next replication cycle will start fresh
   **/
  private _cleanReplMeta = async (replState: RxGraphQLReplicationState<T, C>) => {
    if (replState.metaInstance) {
      const metaDocsQuery = replState.collection.database.storage.statics.prepareQuery(
        replState.metaInstance.schema,
        {
          selector: { id: { $exists: true } },
          skip: 0,
          sort: [
            {
              id: 'asc',
            },
          ],
        }
      )

      const metaDocs = await replState.metaInstance.query(metaDocsQuery)

      const docsToDelete = metaDocs.documents
        .filter((doc) => doc.id.includes('replication'))
        .map((doc) => ({
          previous: doc,
          document: { ...flatClone(doc), _deleted: true },
        }))

      if (docsToDelete && docsToDelete.length > 0) {
        await replState.metaInstance.bulkWrite(docsToDelete, 'rx-document-remove')
      }
      await replState.metaInstance.cleanup(0)
    }
  }

  public runResyncLoop = async (
    entityName: EntityName,
    replState: RxGraphQLReplicationState<T, C>,
    initialResyncInterval: number | null
  ) => {
    await this.cancelReplication(entityName)

    const resyncState$ = this._getEntityResync(entityName)

    resyncState$.next({
      replState,
      resyncInterval: initialResyncInterval,
    })

    this.resync.set(entityName, resyncState$)
  }

  public cancelReplication = async (entityName: EntityName, clearReplMeta = false) => {
    const resyncState$ = this._getEntityResync(entityName)
    const replState = resyncState$.value?.replState

    if (!replState || !resyncState$) {
      return Promise.resolve()
    }

    if (clearReplMeta) {
      //await this._cleanReplMeta(replState)
    }

    /** Stops ongoing replication */
    await replState.cancel()

    /** Stops the resync interval */
    resyncState$.next({
      replState,
      resyncInterval: null,
    })

    resyncState$.next(null)
  }

  public getReplState = (entityName: EntityName) => {
    return this._getEntityResync(entityName).value?.replState
  }

  public hasReplState = (entityName: EntityName) => {
    return !!this._getEntityResync(entityName).value?.replState
  }

  public getReplState$ = (
    entityName: EntityName
  ): Observable<RxGraphQLReplicationState<T, C> | null> => {
    const resyncState$ = this._getEntityResync(entityName)

    return resyncState$.pipe(map((resyncState) => resyncState?.replState || null))
  }

  public cancelReplications = async () => {
    const cancellationPromises = Array.from(this.resync.keys()).map((entityName) =>
      this.cancelReplication(entityName, true)
    )

    await Promise.all(cancellationPromises)
  }
}

export const resync = new Resync<EntityNames>()
