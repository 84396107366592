import { AllOrdersSuppliersCombined } from '@obeta/models/lib/models/Order/AllOrdersSuppliersCombined'
import { RemainingOrdersCountCombined } from '@obeta/models/lib/models/Order/RemainingOrder'
import { ReturnShipmentCountCombined } from '@obeta/models/lib/models/Order/ReturnShipment'
import { SearchParams } from '@obeta/models/lib/models/Meta/Meta'
import { UpdateOrderMetaDataInput } from '@obeta/schema'

interface ORderAction {
  type: OrderActionTypes
}

export enum OrderActionTypes {
  ChangeSortAndFilter = '[Order] Change Sort and Filter',
  ChangeReturnShipmentsSortAndFilter = '[Order] Change ReturnShipments Sort and Filter',
  ChangeRemainingOrdersPagination = '[Order] Change Remaining Orders Pagination',
  LoadAllOrders = '[Order] Load All Orders',
  LoadAllOrdersResult = '[Order] Load All Orders Result',
  LoadReturnShipments = '[Order] Load ReturnShipments',
  LoadReturnShipmentsResult = '[Order] Load ReturnShipments Result',
  LoadRemainingOders = '[Order] Load RemainingOrders',
  LoadRemainingOdersResult = '[Order] Load RemainingOrders Result',
  ResetSearchParams = '[Order] Reset Search Params',
  UpdateOrderMetaDataGraphQL = '[Order] Update meta data of order - e.g. the name - via GraphQL',
  UpdateOrderMetaDataGraphQLResult = '[Order] Update meta data of order - e.g. the name - via GraphQL Result',
}

export interface ChangeOrderSortAndFilterAction extends ORderAction {
  readonly type: OrderActionTypes.ChangeSortAndFilter
  searchParams: SearchParams
}

export const changeOrderSortAndFilter = (
  searchParams: SearchParams
): ChangeOrderSortAndFilterAction => ({
  type: OrderActionTypes.ChangeSortAndFilter,
  searchParams,
})

export interface ChangeRemainingOrdersPaginationAction extends ORderAction {
  readonly type: OrderActionTypes.ChangeRemainingOrdersPagination
  page: number
}

export const changeRemainingOrdersPagination = (
  page = 1
): ChangeRemainingOrdersPaginationAction => ({
  type: OrderActionTypes.ChangeRemainingOrdersPagination,
  page,
})

export interface ChangeReturnShipmentsSortAndFilterAction extends ORderAction {
  readonly type: OrderActionTypes.ChangeReturnShipmentsSortAndFilter
  searchParams: SearchParams
  page: number
}

export const changeReturnShipmentsSortAndFilter = (
  searchParams: SearchParams,
  page = 1
): ChangeReturnShipmentsSortAndFilterAction => ({
  type: OrderActionTypes.ChangeReturnShipmentsSortAndFilter,
  searchParams,
  page,
})

export interface LoadAllOrdersAction extends ORderAction {
  readonly type: OrderActionTypes.LoadAllOrders
  page: number
}

export const loadAllOrders = (page = 1): LoadAllOrdersAction => ({
  type: OrderActionTypes.LoadAllOrders,
  page,
})

export interface LoadAllOrdersResultAction extends ORderAction {
  readonly type: OrderActionTypes.LoadAllOrdersResult
  payload?: AllOrdersSuppliersCombined
  error?: Error
}

export const loadAllOrdersResult = (
  payload?: AllOrdersSuppliersCombined,
  error?: Error
): LoadAllOrdersResultAction => ({
  type: OrderActionTypes.LoadAllOrdersResult,
  payload,
  error,
})

export interface LoadReturnShipmentsAction extends ORderAction {
  readonly type: OrderActionTypes.LoadReturnShipments
  page: number
}

export const loadReturnShipmentsAction = (page = 1): LoadReturnShipmentsAction => ({
  type: OrderActionTypes.LoadReturnShipments,
  page,
})

export interface LoadReturnShipmentsResultAction extends ORderAction {
  readonly type: OrderActionTypes.LoadReturnShipmentsResult
  payload?: ReturnShipmentCountCombined
  error?: Error
}

export const loadReturnShipmentsResult = (
  payload?: ReturnShipmentCountCombined,
  error?: Error
): LoadReturnShipmentsResultAction => ({
  type: OrderActionTypes.LoadReturnShipmentsResult,
  payload,
  error,
})

export interface LoadRemainingOdersAction extends ORderAction {
  readonly type: OrderActionTypes.LoadRemainingOders
  page: number
}

export const loadRemainingOrders = (page = 1): LoadRemainingOdersAction => ({
  type: OrderActionTypes.LoadRemainingOders,
  page,
})

export interface LoadRemainingOdersResultAction extends ORderAction {
  readonly type: OrderActionTypes.LoadRemainingOdersResult
  payload?: RemainingOrdersCountCombined
  error?: Error
}

export const loadRemainingOrdersResult = (
  payload?: RemainingOrdersCountCombined,
  error?: Error
): LoadRemainingOdersResultAction => ({
  type: OrderActionTypes.LoadRemainingOdersResult,
  payload,
  error,
})

export interface ResetSearchParamsAction {
  type: OrderActionTypes.ResetSearchParams
}

export const resetSearchParams = () => ({
  type: OrderActionTypes.ResetSearchParams,
})

export interface UpdateOrderMetaDataGraphQLAction extends ORderAction {
  readonly type: OrderActionTypes.UpdateOrderMetaDataGraphQL
  input: UpdateOrderMetaDataInput
}

export const updateOrderMetaDataGraphQL = (
  input: UpdateOrderMetaDataInput
): UpdateOrderMetaDataGraphQLAction => ({
  type: OrderActionTypes.UpdateOrderMetaDataGraphQL,
  input,
})

export interface UpdateOrderMetaDataGraphQLResultAction extends ORderAction {
  readonly type: OrderActionTypes.UpdateOrderMetaDataGraphQLResult
  success: boolean
  errorCode: string
  errorMessage: string
  orderId: string
  error?: Error
}

export const UpdateOrderMetaDataGraphQLResult = (
  success: boolean,
  errorCode: string,
  errorMessage: string,
  orderId: string,
  error?: Error
): UpdateOrderMetaDataGraphQLResultAction => ({
  type: OrderActionTypes.UpdateOrderMetaDataGraphQLResult,
  success,
  errorCode,
  errorMessage,
  orderId,
  error,
})

export type OrderActionsUnion =
  | LoadAllOrdersAction
  | LoadAllOrdersResultAction
  | ChangeOrderSortAndFilterAction
  | ChangeReturnShipmentsSortAndFilterAction
  | LoadReturnShipmentsAction
  | LoadReturnShipmentsResultAction
  | LoadRemainingOdersAction
  | LoadRemainingOdersResultAction
  | ResetSearchParamsAction
