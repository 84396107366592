import React, { useState } from 'react'
import { useIntervalInActiveTab } from '../hooks/useIntervalInActiveTab'

import Wartungsarbeit from '@obeta/assets/img/wartungsarbeit.gif'
import styles from './Maintenance.module.scss'

const Maintenance_API_ROUTE = '/api/maintenance'
const VERSION_CHECK_INTERVAL_IN_MS = 60000 //one minute
export const Maintenance: React.FC = (props) => {
  const [showMaintenance, setShowMaintenance] = useState(false)
  const [forcedRender, setForcedRender] = useState(false)
  const { children } = props
  const storedValue = JSON.parse(localStorage.getItem('storedMaintenanceStatus') as string)

  useIntervalInActiveTab(
    async () => {
      if (
        window?.location?.origin !== 'http://localhost:4200' &&
        window?.location?.origin !== 'https://staging-shop-support.obeta.io' &&
        window?.location?.origin !== 'https://shop-support.obeta.io'
      ) {
        const response = await fetch(Maintenance_API_ROUTE, { cache: 'no-store' })
        if (response.status === 200) {
          localStorage.setItem('storedMaintenanceStatus', JSON.stringify({ value: true }))
          setShowMaintenance(true)
        } else if (response.status === 204) {
          localStorage.setItem('storedMaintenanceStatus', JSON.stringify({ value: false }))
          if (showMaintenance) {
            setShowMaintenance(false)
          } else {
            setForcedRender(!forcedRender)
          }
        }
      }
    },
    { interval: VERSION_CHECK_INTERVAL_IN_MS }
  )

  return window?.location?.origin !== 'http://localhost:4200' &&
    window?.location?.origin !== 'https://staging-shop-support.obeta.io' &&
    window?.location?.origin !== 'https://shop-support.obeta.io' &&
    ((storedValue && storedValue.value) || showMaintenance) ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.textWrapper} style={{ top: 120 + (window.innerHeight - 500) / 2 }}>
        <h1 className={styles.header1}>Sorry</h1>
        <h2 className={styles.header2}>Wir sind gleich wieder erreichbar</h2>
        <span className={styles.spanElement}>Aufgrund von Wartungsarbeiten ist unsere</span>
        <br />
        <span className={styles.spanElement}>Website für kurze Zeit nicht erreichbar.</span>
      </div>
      <img
        src={Wartungsarbeit}
        alt={'wartungsarbeit'}
        className={styles.maintenanceImage}
        style={{ top: (window.innerHeight - 500) / 2 }}
      />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}
