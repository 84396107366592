import React, { useState, useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { IDropdownMenuItemProps, IDropdownMenuTriggerProps, MenuItemColor } from './types'
import { ReactComponent as ArrowDown } from 'assets/icon/designsystem/arrow_down.svg'
import { ReactComponent as ArrowUp } from 'assets/icon/designsystem/arrow_up.svg'
import {
  Menu,
  Typography,
  MenuItem,
  ListItemIcon,
  Collapse,
  useTheme,
  SvgIcon,
} from '@mui/material'
import styles from './Dropdown.module.scss'
import scssVariables from '@obeta/assets/theme/coreVariablesV2.module.scss'
import { IListOption } from '../../types'
import { Category } from '@obeta/models/lib/models/Category'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'

type Clickable = { onClick: (e: React.MouseEvent<HTMLElement>) => void }

export const DropdownMenuBase: React.FC<
  IDropdownMenuItemProps & {
    renderClosed: (onClick: React.MouseEventHandler) => React.ReactNode
    renderOpened: (onClick: React.MouseEventHandler) => React.ReactNode
  }
> = (props) => {
  const {
    header,
    footer,
    children,
    onMenuItemClick,
    options = [],
    subList,
    isMobileDropdown = false,
    additionalOffset = 0,
    withSeparator = true,
    keepDefaultLeftPosition,
    topSpacingToAnchorEl,
    renderClosed,
    renderOpened,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [anchorLeft, setAnchorLeft] = useState<number>(0)
  const { desktop } = useBreakpoints()
  const open = Boolean(anchorEl)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false)
  const mobileHeaderHeight = useRemToPx(parseFloat(scssVariables.mobileHeaderHeight))
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setIsSubMenuOpen(false)
  }
  const handleSubMenuClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen)
  }

  useEffect(() => {
    if (isMobileDropdown) {
      const headerElement = document.getElementById('app-header')
      const headerElHeight = headerElement?.offsetHeight || mobileHeaderHeight
      if (headerElHeight !== headerHeight) {
        setHeaderHeight(headerElHeight)
      }
    }
  }, [anchorEl, headerHeight, isMobileDropdown, mobileHeaderHeight])

  const dropdownWidthInRem = '15'
  const dropdownWidthInPx = useRemToPx(parseFloat(dropdownWidthInRem))
  const { user: userV2 } = useUserDataV2()

  useEffect(() => {
    if (anchorEl)
      setAnchorLeft(
        anchorEl?.offsetLeft - (dropdownWidthInPx - anchorEl.offsetWidth) - additionalOffset || 0
      )
  }, [additionalOffset, anchorEl, desktop, dropdownWidthInPx])

  const PaperProps = {
    sx: {
      overflow: 'visible',
      left: 'unset',
      maxWidth: `${dropdownWidthInRem}rem`,
      width: '100%',
    },
  }

  if (desktop && keepDefaultLeftPosition !== true) {
    PaperProps.sx.left = `${anchorLeft}px !important`
  }

  const PaperPropsMobile = {
    sx: {
      maxWidth: '100%',
      maxHeight: `calc(100% - ${headerHeight}px)`,
      left: '0 !important',
      top: `${headerHeight}px !important`,
      overflow: 'auto',
    },
  }

  const handleMenuItemClick = (toolsListOption: IListOption | Category) => () => {
    onMenuItemClick && onMenuItemClick(toolsListOption)
    handleClose()
  }

  const content = options.length
    ? options.map((toolsListOption) => {
        const hasIcon = 'icon' in toolsListOption
        if (!toolsListOption.permission || userV2?.permissions?.[`${toolsListOption.permission}`]) {
          return (
            <MenuItem
              disabled={toolsListOption?.isDisabled}
              key={toolsListOption.id}
              value={toolsListOption.name}
              onClick={handleMenuItemClick(toolsListOption)}
              className={styles['list-item']}
            >
              {hasIcon && (
                <ListItemIcon className={styles['list-item-icon']}>
                  <SvgIcon>{toolsListOption.icon}</SvgIcon>
                </ListItemIcon>
              )}
              <Typography className={styles['category-menu-item']} variant="body">
                {toolsListOption.name}
              </Typography>
            </MenuItem>
          )
        }
        return undefined
      })
    : children

  const subListContent = subList
    ? subList.options.map((option) => (
        <MenuItem
          key={option.id}
          value={option.name}
          onClick={handleMenuItemClick(option)}
          className={styles['category-menu-sub-item']}
        >
          {option.name}
        </MenuItem>
      ))
    : ''

  return (
    <>
      {open ? renderOpened(handleClick) : renderClosed(handleClick)}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={isMobileDropdown ? PaperPropsMobile : PaperProps}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoFocus={false}
        sx={{ top: topSpacingToAnchorEl ? topSpacingToAnchorEl : '' }}
      >
        {header && (
          <div className={clsx(styles.headerWrapper, withSeparator && styles.separator)}>
            {header}
          </div>
        )}
        {content}
        {subList && (
          <div>
            <MenuItem className={styles['sub-menu']} onClick={handleSubMenuClick}>
              <Typography className={styles['category-menu-item']} variant="body">
                {subList.title}
              </Typography>
              {isSubMenuOpen && <ArrowUp className={styles['arrow-icon']} />}
              {!isSubMenuOpen && <ArrowDown className={styles['arrow-icon']} />}
            </MenuItem>
            <Collapse in={isSubMenuOpen}>{subListContent}</Collapse>
          </div>
        )}
        {footer && <div className={styles.footerWrapper}>{footer}</div>}
      </Menu>
    </>
  )
}

export const DropdownMenu: React.FC<IDropdownMenuTriggerProps & IDropdownMenuItemProps> = (
  props
) => {
  const renderOpened = useMemo(() => {
    if (props.renderOpenedTrigger) return props.renderOpenedTrigger

    return (onClick) => <DefaultTrigger open={true} onClick={onClick} {...props} />
  }, [props])

  const renderClosed = useMemo(() => {
    if (props.renderClosedTrigger) return props.renderClosedTrigger

    return (onClick) => <DefaultTrigger open={false} onClick={onClick} {...props} />
  }, [props])

  return <DropdownMenuBase {...props} renderOpened={renderOpened} renderClosed={renderClosed} />
}

/*
 * ToDo: do we really need all those confiurations to be passable to the DropdownMenu?
 * generally speaking we should not over generalize components
 */
export const DefaultTrigger: React.FC<IDropdownMenuTriggerProps & { open: boolean } & Clickable> = (
  props
) => {
  const theme = useTheme()
  const { mobile } = useBreakpoints()
  const {
    title = 'MENU',
    withBorder = true,
    color = MenuItemColor.White,
    hideArrow = false,
    open,
    onClick,
    triggerComponent,
    classes,
  } = props

  const { titleContainerClassName } = classes || {}

  return (
    <Typography
      component={triggerComponent || 'span'}
      variant="bodyBold"
      className={clsx(
        styles[`${color}Text`],
        {
          [styles.title]: withBorder,
          [styles.focusedTitle]: open && withBorder,
          [styles.buttonAdapted]: triggerComponent === 'button',
        },
        styles.titleContainer,
        titleContainerClassName
      )}
      onClick={(e) => onClick(e)}
    >
      {title}
      {!mobile && !hideArrow && !open && (
        <SvgIcon htmlColor={theme.palette.white.main} component={ArrowDown} />
      )}
      {!mobile && !hideArrow && open && (
        <SvgIcon htmlColor={theme.palette.white.main} component={ArrowUp} />
      )}
    </Typography>
  )
}
