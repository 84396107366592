import { ofType } from 'redux-observable'
import {
  OfferActionTypes,
  loadSimpleOffersSuccess,
  LoadSimpleOffersAction,
  LoadSimpleOffersSuccessAction,
  loadSimpleOffersError,
  loadOffersSuccess,
  FilterOfferAction,
  filterOffersSuccess,
  filterOffersError,
  loadOffersError,
  resetFilterResultAction,
  ResetFilterAction,
  CreateDownloadableOfferAction,
  getCreateDownloadableOfferResult,
} from '../actions'
import { switchMap, catchError, retry, map, concatMap, first, Observable, mergeMap } from 'rxjs'
import { from, of, forkJoin, defer } from 'rxjs'
import { CollectionsOfDatabase, RxDatabase, RxDocument } from 'rxdb'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'
import {
  GetCollection$,
  SyncDataWithDatabase,
  RemoteSortedItem,
} from '@obeta/models/lib/models/Db/index'
import { Offer } from '@obeta/models/lib/models/Offer/Offer'
import { SimpleOffer } from '@obeta/models/lib/models/Offer/SimpleOffer'
import { ApolloClient, NormalizedCacheObject, gql } from '@apollo/client'

export interface SimpleOfferResponse {
  data: SimpleOffer[]
  messages: string[]
}

export interface OfferResponse {
  data: Offer
  messages: string[]
}

// TODO: RE-CHECK HERE IF IT WORKS!!!
export const createLoadOffersEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadSimpleOffersSuccessAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.LoadSimpleOffersSuccess),
      switchMap((action: LoadSimpleOffersSuccessAction) => {
        return from(changeMetaData(db, 'offers', { isFetching: true })).pipe(
          switchMap((_) => {
            const promiseArray = action.offerIds.map((offerId) =>
              from(Axios.get('offer/details/' + offerId)).pipe(retry(1))
            )

            return forkJoin(promiseArray)
          }),
          switchMap((results: AxiosResponse<OfferResponse>[]) => {
            const records: RemoteSortedItem<Offer>[] = results.map((record, idx) => ({
              item: record.data.data,
              id: record.data.data.id,
              sortOrder: idx,
            }))

            return defer(async () => {
              await syncDataWithDatabase('offers', records, 'id')
              await changeMetaData(db, 'offers', { isFetching: false })
            }).pipe(
              map(() => {
                return loadOffersSuccess()
              })
            )
          }),
          catchError((err) => {
            err.message =
              'error while handling the offers collection in ' +
              createLoadOffersEpic.name +
              ' ' +
              err.message
            handleError(err)

            return of(loadOffersError(err))
          })
        )
      })
    )
}

export const createLoadSimpleOffersEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadSimpleOffersAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.LoadSimpleOffers),
      switchMap((action: LoadSimpleOffersAction) => {
        return from(changeMetaData(db, 'simpleoffers', { isFetching: true })).pipe(
          switchMap(() =>
            Axios.request({
              url: '/offers/search/0',
              method: 'POST',
              data: {
                searchString: '*',
              },
            }).pipe(
              retry(1),
              switchMap((result: AxiosResponse<SimpleOfferResponse>) => {
                const records: RemoteSortedItem<SimpleOffer>[] = result.data.data.map(
                  (record, idx) => ({
                    item: record,
                    id: record.id,
                    sortOrder: idx,
                  })
                )

                return defer(async () => {
                  await syncDataWithDatabase('simpleoffers', records, 'id')
                  await changeMetaData(db, 'simpleoffers', { isFetching: false })
                }).pipe(
                  map(() =>
                    loadSimpleOffersSuccess(result.data.data.map((simpleOffer) => simpleOffer.id))
                  )
                )
              }),
              catchError((err) => {
                err.message =
                  'error while processing ' + createLoadSimpleOffersEpic.name + ' ' + err.message
                handleError(err)

                return of(loadSimpleOffersError(err))
              })
            )
          ),
          catchError((err) => {
            err.message =
              'error sending the request to remote in ' +
              createLoadSimpleOffersEpic.name +
              ' ' +
              err.message
            handleError(err)

            return of(loadSimpleOffersError(err))
          })
        )
      }),
      catchError((err) => {
        err.message = 'error in ' + createLoadSimpleOffersEpic.name + ' ' + err.message
        handleError(err)

        return of(loadSimpleOffersError(err))
      })
    )
}

export const createResetFilter = (getCollection$: GetCollection$) => {
  return (actions$: Observable<ResetFilterAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.ResetFilter),
      concatMap(() =>
        getCollection$('offers').pipe(
          first((coll) => coll !== undefined),
          concatMap((coll) =>
            defer(() =>
              from(
                (async () => {
                  const qry = coll.find({
                    selector: {
                      id: {
                        $exists: true,
                      },
                    },
                    sort: [{ sortOrder: 'asc' }],
                  })
                  const docs = await qry.exec()
                  const updates = docs.map((doc: RxDocument<RemoteSortedItem<Offer>>) => {
                    const json = doc.toMutableJSON(true)
                    json.filtered = 0
                    return json
                  })
                  await coll.bulkUpsert(updates)
                })()
              )
            )
          ),
          map(() => resetFilterResultAction()),
          catchError((err) => {
            err.message = 'error in ' + createResetFilter.name + ' ' + err.message
            handleError(err)
            return of(resetFilterResultAction())
          })
        )
      )
    )
}

//fetch alle docs

export const createFilterOffersEpic = (getCollection$: GetCollection$) => {
  return (actions$: Observable<FilterOfferAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.FilterOffers),
      switchMap((action: FilterOfferAction) =>
        Axios.request({
          url: '/offers/search/0',
          method: 'POST',
          data: {
            searchString: action.searchString,
          },
        }).pipe(
          retry(1),
          concatMap((result: AxiosResponse<SimpleOfferResponse>) =>
            getCollection$('offers').pipe(
              first((coll) => coll !== undefined),
              concatMap((coll) =>
                defer(async () => {
                  const rxOffers: RxDocument<RemoteSortedItem<Offer>>[] = await coll.find().exec()
                  const dbOffers = rxOffers.map((offer) => {
                    const json = offer.toMutableJSON(true)
                    json.filtered = 1
                    return json
                  })
                  if (result.data.data.length) {
                    result.data.data.forEach((fetchedOffer) => {
                      const dbOffer = dbOffers.find((offer) => offer.id === fetchedOffer.id)
                      if (dbOffer) {
                        dbOffer.filtered = 0
                      }
                    })
                  }
                  await coll.bulkUpsert(dbOffers)
                })
              ),
              map(() => filterOffersSuccess())
            )
          ),
          catchError((err) => {
            err.message =
              'error while handling the offers collection in ' +
              createFilterOffersEpic.name +
              ' ' +
              err.message
            handleError(err)
            return of(filterOffersError(err))
          })
        )
      ),
      catchError((err) => {
        err.message = 'error in ' + createFilterOffersEpic.name + ' ' + err.message
        handleError(err)
        return of(filterOffersError(err))
      })
    )
}

export const createDownloadableOfferGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<CreateDownloadableOfferAction>) =>
    actions$.pipe(
      ofType(OfferActionTypes.CreateDownloadableOffer),
      switchMap((action: CreateDownloadableOfferAction) =>
        defer(async () => {
          const response = await apolloClient.query({
            query: gql`
              query getOfferPdf($offerId: String!) {
                getOfferPdf(offerId: $offerId)
              }
            `,
            variables: {
              offerId: action.offerId,
            },
          })
          return response.data.getOfferPdf
        }).pipe(
          retry(1),
          mergeMap((result: boolean) => of(getCreateDownloadableOfferResult(result))),
          catchError((error) => {
            error.message =
              'error while creating downloadable link for offer ' +
              action.offerId +
              ' ' +
              error.message
            handleError(error)
            return of(getCreateDownloadableOfferResult(false))
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(getCreateDownloadableOfferResult(false))
      })
    )
}

export const initAllOfferEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  getCollection$: GetCollection$,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    createLoadSimpleOffersEpic(db, syncDataWithDatabase),
    createLoadOffersEpic(db, syncDataWithDatabase),
    createFilterOffersEpic(getCollection$),
    createResetFilter(getCollection$),
    createDownloadableOfferGraphQLEffect(apolloClient),
  ]
}
