import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'
import { ProductAggregate } from '@obeta/schema'

export const articlesEntity: EntityDescriptor<
  Omit<RemoteSortedItem<ProductAggregate>, 'filtered'>
> = {
  name: 'articles',
  migrationStrategies: {
    1: function (oldDoc) {
      // we just discard old documents due to the switch to the new product model
      return null
    },
    2: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    3: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'articles',
    version: 3,
    description: 'a document representing an article',
    type: 'object',
    indexes: ['sortOrder'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          prices: {
            type: ['object', 'null'],
          },
          stock: {
            type: ['array', 'null'],
          },
          eeValue: {
            type: 'string',
          },
          imagePaths: {
            type: 'object',
          },
          minimumAmount: {
            type: 'number',
          },
          details: {
            type: ['object', 'null'],
          },
          oxomi: {
            type: ['object', 'null'],
          },
          shoppingCart: {
            type: ['object', 'null'],
          },
        },
      },
    },
  },
  subscription: null,
}
