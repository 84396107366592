import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { ShoppingCartItem, ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart'

export const filterCustomItems = (items?: ShoppingCartItem[]) =>
  items?.filter(
    (item) => item.product.type === ProductType.custom && !item.product.isCustomTitleReplaced
  )

type UseCustomItemTitlesOptions = {
  action: (cart: ShoppingCartV2, shoppingCartItems: ShoppingCartItem[]) => unknown
  cart?: ShoppingCartV2
  items?: ShoppingCartItem[]
}
type UseCustomItemTitles = (options: UseCustomItemTitlesOptions) => void

export const useCustomItemTitles: UseCustomItemTitles = ({ action, cart, items }) => {
  const dispatch = useDispatch()

  const customItems = filterCustomItems(items)
  const customItemIds = customItems?.map((item) => item.id)
  const customItemIdsAsString = useMemo(() => JSON.stringify(customItemIds), [customItemIds])

  useEffect(() => {
    if (cart && customItems && customItems.length > 0) {
      dispatch(action(cart, customItems))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customItemIdsAsString, cart?.offerId])
}
