import { useFlags } from 'launchdarkly-react-client-sdk'

// Feature toggles with fallback variations
export const featureToggles = {
  UseNewFastArticleEntryPage: false,
  UseShowOrdersOnStartPage: false,
  UseShowModifiedSumsForOffer: false,
  UseOrdersFeedback: false,
  NOTIFICATION_UNDO_BUTTON: false,
  UsePaymentProvider: false,
  useNewWebAds: false,
  ShowDialogForDiscontinuedItemWithLimitedStock: false,
  UseImgProxy: false,
  UseSearchFeedback: false,
  UseNewOrderListPageInApp: false,
  UseNewSupplierOverview: false,
  UseNewOpenInvoicePage: false,
  UseOldOrders: true,
  ShowOxomiCatalogs: false,
  useTecselectIntegration: false,
  usePaypal: false,
  UseNewUserManagement: false,
  UseAvailableDefault: false,
  useOxomiSearchlet: false,
  UsePrintBauDoc: false,
  SHOP_VERSION: '',
  UseIndividualDataSheetToggle: false,
  UseNewWarehouses: false,
} as const

export type Feature = keyof typeof featureToggles

export const useFeatureToggle = (feature: Feature) => {
  const flags = useFlags()

  return flags[feature] ?? false
}
