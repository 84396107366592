import React, { useState } from 'react'
import { ReactComponent as More } from 'assets/icon/designsystem/more_vertical.svg'
import { DefaultTrigger, DropdownMenu } from '../dropdown'
import { MenuItemColor } from '../dropdown/types'
import { getActualMenuItemShop, getToolsList } from '../../constants'
import { MenuItemsMobile } from '../BottomSection/MenuItemsMobile'
import { MenuWrapper } from '../../../menus'
import styles from './ToolsList.module.scss'
import { IListOption } from '../../types'
import { Category } from '@obeta/models/lib/models/Category'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { TertiaryResponsiveIconButton } from '../../../custom-button/CustomButton'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { useTranslation } from 'react-i18next'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

interface IToolsListProps {
  color?: MenuItemColor
  isMobile?: boolean
}

export const ToolsList: React.FC<IToolsListProps> = (props) => {
  const { color = MenuItemColor.Black, isMobile = false } = props
  const isNewWebAds = useFeatureToggle('useNewWebAds')
  const { t } = useTranslation()
  const isNativeUITarget = isUiTarget('app')

  const useNewFastProductEntryPage = useFeatureToggle('UseNewFastArticleEntryPage')
  const { options, title } = getToolsList(useNewFastProductEntryPage)

  const history = useHistory()

  const [isMenuItemsShown, setIsMenuItemsShown] = useState(false)
  const {
    dimensions: { height: headerHeight },
  } = useHeaderDimensions()

  const handleMenuOpen = () => setIsMenuItemsShown(true)

  const handleMenuToggle = () => setIsMenuItemsShown((prevOpen) => !prevOpen)

  const handleMenuClose = () => setIsMenuItemsShown(false)

  const handleMenuItemClick = (option: IListOption | Category) =>
    option.url ? history.push(option.url) : ''

  const handleMobileMenuItemClick = (item) => {
    history.push(item.url)
    handleMenuClose()
  }

  // We show Werkzeugkasten list for mobile "web" platform and list with all needed links for "app" platform
  if (isMobile || isNativeUITarget) {
    return (
      <>
        {isMobile ? (
          <TertiaryResponsiveIconButton icon={<More />} onClick={handleMenuOpen} />
        ) : (
          <DefaultTrigger
            onClick={handleMenuToggle}
            title={t('MAIN.MORE')}
            open={isMenuItemsShown}
          />
        )}
        <MenuWrapper
          topOffset={isNativeUITarget && !isMobile ? headerHeight : 0}
          show={isMenuItemsShown}
          onMenuClosed={handleMenuClose}
          className={isNativeUITarget ? styles.toolsListOverlayApp : styles.toolsListOverlay}
          style={{ width: '100%' }}
        >
          <MenuItemsMobile closeOverlayCb={handleMobileMenuItemClick} />
        </MenuWrapper>
      </>
    )
  }

  return (
    <DropdownMenu
      title={title}
      color={color}
      withBorder={!isMobile}
      options={options}
      subList={getActualMenuItemShop(isNewWebAds)}
      onMenuItemClick={handleMenuItemClick}
    />
  )
}
