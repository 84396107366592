/**
 * Product Data Shop.
 */

import { ArticleRing } from '../ArticleListItem'
import { DeclarationOfPerformance } from '../../../models'
import { ElbridgeConfigurator } from '../../../models/Elbridge/ElbridgeConfigurator'

export enum ProductType {
  custom = 'custom',
  special = 'special',
  normal = 'normal',
  oversize = 'oversize',
}

export const RingToTypeMap = {
  [ArticleRing.Lagerartikel]: ProductType.normal,
  [ArticleRing.Divers]: ProductType.custom,
  [ArticleRing.Sonderbestellung]: ProductType.special || ProductType.oversize,
}

export const TypeToRingMap = {
  [ProductType.normal]: ArticleRing.Lagerartikel,
  [ProductType.custom]: ArticleRing.Divers,
  [ProductType.special || ProductType.oversize]: ArticleRing.Sonderbestellung,
}
export interface Product {
  id: string
  sapId: string
  obetaId: string
  dehaId: string
  title: string
  unit: string
  sapUnit: string
  isoUnit: string
  minimumAmount: number
  supplierArticleId: string
  supplierId: string
  isSendable: boolean
  isCutProduct: boolean
  priceDimension: number
  priceDimensionExponential: number
  bulkPrice: boolean
  bulkPrices?: [
    {
      unit: string
      amount: number
    }
  ]
  type: ProductType
  supplierArticleId2: string
  supplierArticleId3: string
  oxomiId: string
  eans: string[]
  dehaProductGroupId: string
  isPvArticle: boolean
  isDiscontinued: boolean
  isDeleted: boolean
  isForSale: boolean
  isCurrentlyNotAvailable: boolean
  promoCode: string
  replacementArticleId: string
  replacementArticleSapId: string
  articleDescription: string
  catalogInfo?: CatalogInfo
  catalogDescription: string
  isBlackListed: boolean
  packagingUnit: number
  length: number
  width: number
  height: number
  volume: number
}

export interface CatalogInfo {
  year1: number
  year2: number
  page: number
}

export interface ProductImage {
  large: string
}

export interface Category {
  id: string
  title: string
  productCount: number
}

export interface ProductVariant {
  sapId: string
  title: string
  product: ProductAggregate
}

export interface EtimData {
  dehaId: string
  version: string
  classId: string
  classText: string
  type: string
  featureId: string
  featureText: string
  order: number
  value1: string
  value2: string
  unit: string
}

export interface ProductAggregate extends Product {
  imageData: {
    sapId?: string
    images: ProductImage[]
  }
  images?: [
    {
      width?: number
      url: string
    }
  ]
  supplierImageData: {
    sapId?: string
    large: string
  }
  stock?: Stock[]
  prices?: ProductPrices
  relatedProducts?: ProductAggregate[]
  etimData: EtimData[]
  variants?: ProductVariant[]
  categories?: {
    deha: Category[]
    obeta: Category[]
  }
  isTopseller?: boolean
  elbridgeConfigurators: ElbridgeConfigurator[]
  dop: DeclarationOfPerformance[]
  isCustomTitleReplaced?: boolean
  upSelling?: ProductAggregate[]
  crossSelling?: ProductAggregate[]
}

export interface Image {
  url: string
  width?: number
}

export interface ProductPrices {
  sapId: string
  listPrice: number
  bulkPrice1: number
  catalogPrice: number
  metalNeAddition: number
  netPrice: number
  tecSelect: number
  strikeThroughPrice: number
  currency: string
}

export interface PriceLabelOptions {
  amount?: number
  unit?: string
  withMetal?: boolean
}

export interface Stock {
  sapId: string
  warehouseId: string // TODO Filialname und erwartetes Lieferdatum wird benötigt
  amount: number
  unit: string
}

export type StockType = 'online' | 'store'

export type ProductStockStatus =
  | 'available'
  | 'partially-available'
  | 'currently-not-available'
  | 'not-available'
  | 'not-for-sale'
