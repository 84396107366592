import { ofType } from 'redux-observable'
import Axios from 'axios-observable'
import {
  catchError,
  concatAll,
  concatMap,
  timer,
  filter,
  first,
  map,
  mergeMap,
  retry,
  switchMap,
  tap,
  debounce,
} from 'rxjs'
import {
  AddArticleToCartAction,
  addArticleToCartResult,
  AddCartTemplatesToCartGraphQLAction,
  AddMarkForNotificationAction,
  AddOfferToCartAction,
  AddTemplateToCartAction,
  CartsAction,
  CartsActionTypes,
  ChangeProductAmountGraphQLAction,
  changeProductAmountGraphQLResult,
  DeleteCartItemsGraphQLAction,
  DeleteCartItemsGraphQLResult,
  EmptyShoppingCartGraphQLAction,
  EmptyShoppingCartGraphQLResult,
  loadCarts,
  GetShoppingCartPricesGraphQLAction,
  GetShoppingCartPricesGraphQLResult,
  loadCartsResult,
  LoadSingleCartAction,
  loadSingleCartSuccess,
  MoveCartAction,
  MoveCartItemsGraphQLAction,
  moveCartItemsGraphQLResult,
  moveCartResult,
  NotifyCartGraphQLAction,
  notifyCartGraphQLResult,
  SubmitCartGraphQLAction,
  submitCartGraphQLResult,
  queueError,
  queueNext,
  QueueNextAction,
  queueSuccess,
  QueueSuccessAction,
  RemoveArticleFromCartAction,
  RemoveCartAction,
  ResetShoppingCartGraphQLAction,
  ResetShoppingCartGraphQLResult,
  SendCartAction,
  sendCartResult,
  SendCartResultType,
  serviceSuccess,
  ServiceSuccessAction,
  UpdateCartAction,
  UpdateCartArticleAmountAction,
  UpdateCartGraphQLAction,
  UpdateCartGraphQLResult,
  updateCartResult,
  updateStateCart,
  UpdateStateCartAction,
  AddProductToCartGraphQLAction,
  addProductToCartGraphQLResult,
  VerifyCartGraphQLAction,
  VerifyCartGraphQLResult,
  RemoveMarkForNotificationAction,
  AddCartContextEnum,
  UpdateCustomProductsGraphQLAction,
  UpdateShoppingCartPricesGraphQLResult,
  UpdateCustomProductResponse,
  GetVoltimumPointsGraphQLAction,
  GetVoltimumPointsGraphQLResult,
  addCartTemplatesToCartGraphQLResult,
  AddOrReplaceOfferInCartGraphQLAction,
  addOrReplaceOfferInCartGraphQLResult,
  AddCartTemplateItemsToCartGraphQLAction,
  addCartTemplateItemsToCartGraphQLResult,
  GetIdsFormFieldsGraphQLAction,
  getIdsFormFieldsResult,
  getOciFormFieldsResult,
  AddToCartFromIdsXmlAction,
  addToCartFromIdsXmlResult,
  AddOrderItemsToCartGraphQLAction,
  AddOrderItemsToCartGraphQLResult,
  VerifyOfferInCartGraphQLAction,
  verifyCartInOfferGraphQLResult,
  ManuallyUpdateCartsActions,
  manuallyUpdateCartsResult,
  GetOciFormFieldsGraphQLAction,
} from '../actions/cart-actions'

import { ArticleListItem } from '@obeta/models/lib/models/Article/ArticleListItem'
import { Customer } from '@obeta/models/lib/models/CustomerData/Customer'
import { GetCollection$, SyncDataWithDatabase } from '@obeta/models/lib/models'
import { MetaData } from '@obeta/models/lib/models/Meta/Meta'
import {
  CartMoveItemsToAdd,
  MoveCartItemsOfferIdUpdateEnum,
  ShoppingCart,
  ShoppingCartAddItemsInput,
  ShoppingCartItem,
  ShoppingCartUpdateOutput,
  ShoppingCartUpdateResult,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import {
  GetShoppingCartsQuery,
  GetShoppingCartsQueryVariables,
  OciCartFormFieldsResponse,
  ShoppingCartUpdateResult as ShoppingCartUpdateResultFromSchema,
} from '@obeta/schema'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData, getMetaData } from '@obeta/utils/lib/epics-helpers'
import { defer, forkJoin, from, Observable, of } from 'rxjs'
import { AxiosError, AxiosResponse } from 'axios'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { noop } from '../actions'
import { CollectionsOfDatabase, RxDatabase, RxDocument } from 'rxdb'
import { AvailablePermissions } from '../hooks/useUserData'
import { toastNotifier } from '@obeta/utils/lib/toast-notifier'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  moveItemsToCart,
  deleteShoppingCartItems,
  notifyShoppingCart,
  submitShoppingCart,
  updateShoppingCartItems,
  addItemsToCart,
  updateShoppingCartMetaDataMutation,
  verifyOfferInShoppingCart,
} from '../entities/cartsv2QueryProps'
import { getCartDocument, isSimpleCart } from '@obeta/utils/lib/getCartDocument'
import { Haptics } from '@capacitor/haptics'
import { OFFER_ITEM_TITLES } from '../queries/offerItemTitles'
import {
  AddOrderItemsToCartMutation,
  AddOrderItemsToCartMutationVariables,
  AddOrReplaceOfferInCartMutation,
  AddOrReplaceOfferInCartMutationVariables,
  AddToCartFromIdsXmlMutation,
  AddToCartFromIdsXmlMutationVariables,
  GetProductsQuery,
  GetProductsQueryVariables,
  GetVoltimumPointsQuery,
  GetVoltimumPointsQueryVariables,
  IdsFormFieldsResult,
  UpdateShoppingCartMetaDataMutation,
  UpdateShoppingCartMetaDataMutationVariables,
} from '@obeta/schema'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { trackClick } from '@obeta/utils/lib/tracking'
import featureToggleService from '../hooks/feature-toggles/FeatureToggleService'
import { GET_SHOPPING_CARTS } from '../queries/getShoppingCarts'

export interface ShoppingCartResponse {
  data: {
    shoppingCarts: ShoppingCart[]
  }
  messages: [
    {
      type: 'string'
      message: 'string'
    }
  ]
}

interface PermCheckProps {
  notifyShoppingCart: boolean
  orderShoppingCart: boolean
}

let actionQueue = new Array<CartsAction>()
let actionQueueRunning = false

const errorHandling = <T>(error: Error | AxiosError<T>) => {
  handleError(error)

  actionQueue = new Array<CartsAction>()
  actionQueueRunning = false

  return of(queueError(error))
}

// we want to check, if this article is already in this cart
// if this is the case, we also pass the current amount, so
// moving this article to another cart will not remove the article, but
// update the amount to the previous value
const getCurrentArticleAmount = (
  carts: RxDocument<ShoppingCart>[],
  cartId: string,
  articleId: string
) => {
  const shoppingCart = carts.find((s) => s.id === cartId)
  let currentArticleAmount: number | undefined = undefined
  if (shoppingCart) {
    const article = shoppingCart.articleList.find((article) => article.id === articleId)
    if (article) {
      currentArticleAmount = article.shoppingCart?.amount
    }
  }
  return currentArticleAmount
}

export const createLoadSingleCartEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<LoadSingleCartAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.LoadSingleCart),
      switchMap((action: LoadSingleCartAction) =>
        Axios.get<ShoppingCartResponse>('v2/shoppingCart/' + action.cartId).pipe(
          concatMap((res) =>
            defer(async () => {
              const collection = db.carts
              await collection.upsert(res.data.data.shoppingCarts[0])
              return res.data.data.shoppingCarts[0]
            })
          ),
          map((result) => loadSingleCartSuccess(result)),
          catchError((error) => {
            return errorHandling(error)
          })
        )
      ),
      catchError((error) => {
        return errorHandling(error)
      })
    )
}

/*
The naming createChangeProductAmountEffect has been selected to prevent confusion with UpdateCartArticleAmountAction which is currently being used in the App
 */
export const createChangeProductAmountEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<ChangeProductAmountGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.ChangeProductAmountGraphQL),
      debounce(() => timer(450)),
      switchMap((action: ChangeProductAmountGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdating: true, affectedItem: action.itemId }) // show skeleton for item, switch back will take place in getShoppingCartPricesGraphQL() below

          await apolloClient.mutate<ShoppingCartUpdateOutput>({
            mutation: updateShoppingCartItems,
            variables: {
              cartId: action.cartId,
              cartItemPatches: [
                {
                  itemId: action.itemId,
                  patch: { amount: action.amount },
                },
              ],
            },
          })
          if (action.onResult) {
            action.onResult()
          }
          return action$
        }).pipe(retry(1))
      ),
      map(() => changeProductAmountGraphQLResult()),
      catchError((error) => {
        error.message = 'error in ' + createChangeProductAmountEffect.name + ' ' + error.message
        handleError(error)
        return of(changeProductAmountGraphQLResult(error))
      })
    )
}

export const createUpdateCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.UpdateCartGraphQL),
      switchMap((action: UpdateCartGraphQLAction) =>
        defer(async () => {
          const backupCart = await db.cartsv2.findOne(action.cart.id).exec()
          await db.cartsv2.upsert({ ...action.cart, isUpdating: true })
          const input = {
            cartId: action.cart.id,
            cartMetaDataPatch: {
              offerId: action.cart.offerId,
              promotionId: action.cart.promotionId,
              commission: action.cart.commission,
              remark: action.cart.remark,
              phone: action.cart.phone,
              // only add paymentMethod to patch if feature is enabled
              ...(featureToggleService.getFeatureToggleValue('UsePaymentProvider') && {
                paymentMethod: action.cart.paymentMethod,
              }),
            },
          }

          if (action.cart.shippingData) {
            const deliveryAddress = { ...action.cart.shippingData.deliveryAddress }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- __typename doesn't exist in manually created model
            delete (deliveryAddress as any).__typename
            input.cartMetaDataPatch['shippingData'] = {
              isCompleteDelivery: action.cart.shippingData.isCompleteDelivery,
              deliveryAddress: deliveryAddress,
              shippingDate: action.cart.shippingData.shippingDate,
              shippingType: action.cart.shippingData.shippingType,
              storeId: action.cart.shippingData.storeId,
              addressId: action.cart.shippingData.addressId,
            }
          }

          const response = await apolloClient.mutate<
            UpdateShoppingCartMetaDataMutation,
            UpdateShoppingCartMetaDataMutationVariables
          >({
            mutation: updateShoppingCartMetaDataMutation,
            variables: {
              input,
            },
          })

          if (!response.data?.updateShoppingCartMetaData.updateResults?.success) {
            await db.cartsv2.upsert({ ...backupCart.toJSON(), isUpdating: false })
          } else {
            await db.cartsv2.upsert({ ...action.cart, isUpdating: false })
          }
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: UpdateShoppingCartMetaDataMutation) =>
            of(UpdateCartGraphQLResult(result?.updateShoppingCartMetaData.updateResults?.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + createUpdateCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(UpdateCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createUpdateCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(UpdateCartGraphQLResult(false, error))
      })
    )
}

export const moveCartItemsGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<MoveCartItemsGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.MoveCartItemsGraphQL),
      concatMap((action: MoveCartItemsGraphQLAction) =>
        defer(async () => {
          const targetCartId = action.targetCartId
          const targetCart = await db.cartsv2.findOne(action.targetCartId).exec()

          // ----- Step 1 - UPDATE OFFERID FOR TARGET CART if needed ---
          const offerUpdatesRequired = action.MoveCartItemsOfferIdUpdateEnum
          let updateCartResponse
          if (
            offerUpdatesRequired ===
            MoveCartItemsOfferIdUpdateEnum.ResetItemOfferIdsAndUpdateTargetOfferId
          ) {
            updateCartResponse = await apolloClient.mutate<ShoppingCartUpdateOutput>({
              mutation: updateShoppingCartMetaDataMutation,
              variables: {
                input: {
                  cartId: targetCartId,
                  cartMetaDataPatch: {
                    offerId: action.offerId,
                  },
                },
              },
            })
          }

          // ----- Step 2 - ADD ITEMS TO TARGET CART ---
          const sourceCartItemsCount = action.sourceCart.items.length

          const itemsToAddToTargetCart: CartMoveItemsToAdd[] = []
          // We purely need the sapIds to be able to map back to the itemIds of the source cart
          const sapIdsOfCartItemsAdded: string[] = []
          const itemIdsOfCartItemsAddedToTargetCart: string[] = []

          action.itemIds.forEach((itemId) => {
            const cartItem = action.sourceCart.items.find((item) => item.id === itemId)

            if (cartItem) {
              itemsToAddToTargetCart.push({
                sapId: cartItem.sapId,
                amount: cartItem.amount,
                offerId:
                  offerUpdatesRequired === MoveCartItemsOfferIdUpdateEnum.NoUpdates
                    ? action.sourceCart.offerId
                    : '',
                offerItemPosition: '', // TODO Needs to be set. Yet unclear where we get the offerItemPosition from.
              })
            }
          })

          let addItemsResponse
          if (itemsToAddToTargetCart.length > 0) {
            addItemsResponse = await apolloClient.mutate({
              mutation: moveItemsToCart,
              variables: {
                input: {
                  cartId: targetCartId,
                  items: itemsToAddToTargetCart,
                },
              },
            })
            if (addItemsResponse?.data?.addShoppingCartItems?.updateResults.success) {
              itemsToAddToTargetCart.forEach((item) => {
                sapIdsOfCartItemsAdded.push(item.sapId)
              })
            } else {
              addItemsResponse?.data.addShoppingCartItems?.itemResults.forEach((itemResult) => {
                if (itemResult.success) {
                  itemsToAddToTargetCart.forEach((itemResult) => {
                    sapIdsOfCartItemsAdded.push(itemResult.sapId)
                  })
                }
              })
            }
          }

          // ----- Step 3 - DELETE MOVED ITEMS FROM sourceCart
          let sapIdsOfCartItemsMoved: string[] = []
          let itemIdsOfCartItemsMoved: string[] = []

          // Optimistic DB update
          const backupOfSourceCart = await db.cartsv2.findOne(action.sourceCart.id).exec()
          const optimisticallyUpdatedItems: ShoppingCartItem[] = action.sourceCart.items.filter(
            (item) => !sapIdsOfCartItemsAdded.includes(item.sapId)
          )

          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.sourceCart }
          optimisticallyUpdatedCart.items = optimisticallyUpdatedItems
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          // Map from sap Ids back to item ids of the source cart
          const cartItemsAdded = action.sourceCart.items.filter((shoppingCartItem) =>
            sapIdsOfCartItemsAdded.includes(shoppingCartItem.sapId)
          )
          cartItemsAdded.forEach((item) => itemIdsOfCartItemsAddedToTargetCart.push(item.id))

          const deleteItemsResponse = await apolloClient.mutate({
            mutation: deleteShoppingCartItems,
            variables: {
              input: {
                cartId: action.sourceCart.id,
                itemIds: itemIdsOfCartItemsAddedToTargetCart,
              },
            },
          })

          const allDeletesFromSourceCartWereSuccessful =
            deleteItemsResponse.data.deleteShoppingCartItems.updateResults.success
          if (allDeletesFromSourceCartWereSuccessful) {
            itemIdsOfCartItemsMoved = action.itemIds
            sapIdsOfCartItemsMoved = sapIdsOfCartItemsAdded
          }

          // Revert optimistic DB update for items which were not actually deleted from the source cart
          if (!allDeletesFromSourceCartWereSuccessful) {
            deleteItemsResponse?.data?.deleteShoppingCartItems.updateResults.itemResults.forEach(
              (cartItemResult, index) => {
                if (cartItemResult.success) {
                  itemIdsOfCartItemsMoved.push(cartItemResult.id)
                  sapIdsOfCartItemsMoved.push(sapIdsOfCartItemsAdded[index])
                }
              }
            )
            if (sapIdsOfCartItemsAdded.length !== sapIdsOfCartItemsMoved.length) {
              backupOfSourceCart.items = backupOfSourceCart.items.filter(
                (item) => !itemIdsOfCartItemsMoved.includes(item.id)
              )
            }
            await db.cartsv2.upsert(backupOfSourceCart.toJSON())
          }

          // ----- Step 4 - TRIGGER RENDER OF NotificationCartMove
          let sourceCartEmpty = false
          if (sourceCartItemsCount - sapIdsOfCartItemsMoved.length === 0) {
            sourceCartEmpty = true
          }

          if (sapIdsOfCartItemsMoved.length > 0) {
            let notificationId = targetCartId
            sapIdsOfCartItemsMoved.forEach(
              (sapIdOfMovedItem) => (notificationId += `-${sapIdOfMovedItem}`)
            )

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.CartMove,
              id: notificationId,
              options: {
                cartEmpty: sourceCartEmpty,
                cartName: targetCart.name,
                includingOffer:
                  updateCartResponse?.data?.updateShoppingCartMetaData?.updateResults?.success,
                itemCount: sapIdsOfCartItemsMoved.length,
              },
            })
          }
          // Note: Product decided that we don't show any user notice in case none of the items were moved = failure.

          deleteItemsResponse.data.cartEmpty = sourceCartEmpty
          deleteItemsResponse.data.movedItemIds = itemIdsOfCartItemsMoved
          deleteItemsResponse.data.success =
            itemIdsOfCartItemsMoved.length === action.itemIds.length
          return deleteItemsResponse.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(moveCartItemsGraphQLResult(result.cartEmpty, result.movedItemIds, result.success))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + moveCartItemsGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(moveCartItemsGraphQLResult(false, [], true, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + moveCartItemsGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(moveCartItemsGraphQLResult(false, [], false, error))
      })
    )
}

export const notifyCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<NotifyCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.NotifyCartGraphQL),
      concatMap((action: NotifyCartGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdating: true })

          const notifyCartResponse = await apolloClient.mutate({
            mutation: notifyShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })

          await changeMetaData(db, 'cartsv2', { isUpdating: false })

          return notifyCartResponse.data.notifyShoppingCart
        }).pipe(
          retry(1),
          mergeMap((result) => of(notifyCartGraphQLResult(result.notificationEmail))),
          catchError((error) => {
            error.message =
              'error while processing ' + notifyCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(notifyCartGraphQLResult('', error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + notifyCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(notifyCartGraphQLResult('', error))
      })
    )
}

export const submitCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<SubmitCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.SubmitCartGraphQL),
      concatMap((action: SubmitCartGraphQLAction) =>
        defer(async () => {
          const submitCartResponse = await apolloClient.mutate({
            mutation: submitShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
                userAgent: isPlatform('web') ? navigator.userAgent : 'obeta-app',
              },
            },
          })

          return submitCartResponse.data
        }).pipe(
          mergeMap((result) => of(submitCartGraphQLResult(result.success))),
          catchError((error) => {
            error.message =
              'error while processing ' + submitCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(submitCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + submitCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(submitCartGraphQLResult(false, error))
      })
    )
}

export const verifyCartInOfferGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<VerifyOfferInCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.VerifyOfferInCartInOfferGraphQL),
      concatMap((action: VerifyOfferInCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.query({
            query: verifyOfferInShoppingCart,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })
          return response.data.verifyOfferInShoppingCart
        }).pipe(
          mergeMap((result) => of(verifyCartInOfferGraphQLResult(result.success, result.result))),
          catchError((error) => {
            error.message =
              'error while processing ' + verifyCartInOfferGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(
              verifyCartInOfferGraphQLResult(
                false,
                { removedCartItems: [], addedRelations: [], removedRelations: [] },
                error
              )
            )
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + verifyCartInOfferGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(
          verifyCartInOfferGraphQLResult(
            false,
            { removedCartItems: [], addedRelations: [], removedRelations: [] },
            error
          )
        )
      })
    )
}

const singleItemsRemoveStorage = new Set<string>()

export const isSingleCartRemoveInProgress = () => {
  return singleItemsRemoveStorage.size > 0
}

export const deleteCartItemsGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DeleteCartItemsGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.DeleteCartItemsGraphQL),
      concatMap((action: DeleteCartItemsGraphQLAction) =>
        defer(async () => {
          // The variables below are needed for the notification shown to user in case of success
          let deletedItemIds = [] as unknown as string[]
          const failedItemIds = [] as unknown as string[]
          let productTitle = ''
          if (action.singleDelete) {
            const cartItem = action.cart.items.find((item) => {
              return item.id === action.itemIds[0]
            })
            productTitle = (cartItem && cartItem.product.title) ?? ''

            singleItemsRemoveStorage.add(action.itemIds[0])
          }

          // Optimistic DB update to trigger visual feedback for user
          const backupCart = await db.cartsv2.findOne(action.cart.id).exec()
          const optimisticallyUpdatedItems: ShoppingCartItem[] = action.cart.items.filter(
            (item) => !action.itemIds.includes(item.id)
          )
          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.cart }
          optimisticallyUpdatedCart.items = optimisticallyUpdatedItems
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation deleteShoppingCartItemsInline($input: ShoppingCartDeleteItemsInput!) {
                deleteShoppingCartItems(input: $input) {
                  updateResults {
                    success
                    errorMessage
                    itemResults {
                      success
                      errorMessage
                    }
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
                itemIds: action.itemIds,
              },
            },
          })

          // all deletes were successful
          if (response.data.deleteShoppingCartItems.updateResults.success) {
            deletedItemIds = action.itemIds
          }

          // only some deletes were successful
          if (!response.data.deleteShoppingCartItems.updateResults.success) {
            response?.data?.deleteShoppingCartItems.updateResults.itemResults.forEach(
              (itemResult, index) => {
                if (itemResult.success) {
                  deletedItemIds.push(action.itemIds[index])
                } else {
                  failedItemIds.push(action.itemIds[index])
                }
              }
            )

            if (action.itemIds.length !== failedItemIds.length) {
              backupCart.items = backupCart.items.filter(
                (item) => !deletedItemIds.includes(item.id)
              )
            }
            await db.cartsv2.upsert(backupCart.toJSON())
          }

          const syncedCart = await db.cartsv2.findOne(action.cart.id).exec()
          const cartEmpty = syncedCart.items.length === 0 && !action.imminentAddToCartWillFollow

          if (
            response?.data?.deleteShoppingCartItems.updateResults.success &&
            action.singleDelete
          ) {
            singleItemsRemoveStorage.delete(action.itemIds[0])

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteArticleSingle,
              id: action.itemIds[0],
              options: {
                id: action.itemIds[0],
                cartEmpty: cartEmpty,
                cartId: action.cart.id,
                productTitle: productTitle, // shoppingCartItem.product.title,
              },
            })
          }

          // trigger render of NotificationDeleteArticleMultiple
          if (
            response?.data?.deleteShoppingCartItems.updateResults.success &&
            !action.singleDelete
          ) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteArticleMultiple,
              id: action.itemIds[0],
              options: {
                id: action.itemIds[0],
                cartEmpty: cartEmpty,
                cartId: action.cart.id,
                itemCount: deletedItemIds.length,
              },
            })
          }

          response.data.cartEmpty = cartEmpty
          response.data.deletedItemIds = deletedItemIds
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              DeleteCartItemsGraphQLResult(
                result.cartEmpty,
                result.deletedItemIds,
                result?.deleteShoppingCartItems.updateResults?.success
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + deleteCartItemsGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(DeleteCartItemsGraphQLResult(false, [], error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + deleteCartItemsGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(DeleteCartItemsGraphQLResult(false, [], error))
      })
    )
}

export const emptyShoppingCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<EmptyShoppingCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.EmptyShoppingCartGraphQL),
      concatMap((action: EmptyShoppingCartGraphQLAction) =>
        defer(async () => {
          // Optimistic DB update to trigger visual feedback for user
          const backupCart: RxDocument<ShoppingCartV2> = await db.cartsv2
            .findOne(action.cart.id)
            .exec()
          const optimisticallyUpdatedCart: ShoppingCartV2 = { ...action.cart }
          optimisticallyUpdatedCart.items = []
          await db.cartsv2.upsert(optimisticallyUpdatedCart)

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation emptyShoppingCart($input: ShoppingCartEmptyInput!) {
                emptyShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
              },
            },
          })

          // clearing was not successfull
          if (!response.data.emptyShoppingCart.updateResults.success) {
            await db.cartsv2.upsert(backupCart.toJSON())
          }

          if (response?.data?.emptyShoppingCart?.updateResults?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.EmptyCart,
              id: `${action.cart.id}-${NotificationType.EmptyCart}`,
              options: {
                cartId: action.cart.id,
                cartName: backupCart.name,
              },
            })
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap(() => of(EmptyShoppingCartGraphQLResult(true))),
          catchError((error) => {
            error.message =
              'error while processing ' + emptyShoppingCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(EmptyShoppingCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + emptyShoppingCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(EmptyShoppingCartGraphQLResult(false, error))
      })
    )
}

export const getShoppingCartPricesGraphQL = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetShoppingCartPricesGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.GetShoppingCartPricesGraphQL),
      switchMap((action: GetShoppingCartPricesGraphQLAction) => {
        return defer(async () => {
          const response = await apolloClient.query({
            query: gql`
              query getPricesForSingleShoppingCart($cartId: String!) {
                getPricesForSingleShoppingCart(cartId: $cartId) {
                  net
                  metal
                  shipping
                  vat
                  netSum
                  totalSum
                  currency
                  tecselect
                  cartItemPrices {
                    lineItemId
                    strikeThroughPrice
                    netPrice
                    currency
                    tecSelect
                    metalNeAddition
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          const cartItemPrices = response.data.getPricesForSingleShoppingCart.cartItemPrices
          const cartPrices = {
            net: response.data.getPricesForSingleShoppingCart.net,
            netSum: response.data.getPricesForSingleShoppingCart.netSum,
            totalSum: response.data.getPricesForSingleShoppingCart.totalSum,
            currency: response.data.getPricesForSingleShoppingCart.currency,
            metal: response.data.getPricesForSingleShoppingCart.metal,
            vat: response.data.getPricesForSingleShoppingCart.vat,
            shipping: response.data.getPricesForSingleShoppingCart.shipping,
            tecselect: response.data.getPricesForSingleShoppingCart.tecselect,
          }
          await db.cartsv2prices.incrementalUpsert({
            cartId: action.cartId,
            prices: {
              shoppingCartItemPrices: cartItemPrices,
              shoppingCartPrices: cartPrices,
            },
          })

          if (action.onResult) {
            action.onResult()
          }
          await changeMetaData(db, 'cartsv2', { isUpdating: false }) // always switch back after prcices-update
        }).pipe(
          retry(1),
          switchMap(() => of(GetShoppingCartPricesGraphQLResult())),
          catchError((error) => {
            error.message =
              'error while processing ' + getShoppingCartPricesGraphQL.name + ' ' + error.message
            handleError(error)
            return of(GetShoppingCartPricesGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getShoppingCartPricesGraphQL.name + ' ' + error.message
        handleError(error)
        return of(GetShoppingCartPricesGraphQLResult())
      })
    )
}

export const resetShoppingCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<ResetShoppingCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.ResetShoppingCartGraphQL),
      concatMap((action: ResetShoppingCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation resetShoppingCart($input: ShoppingCartResetInput!) {
                resetShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cart.id,
              },
            },
          })

          return response.data
        }).pipe(
          retry(1),
          mergeMap(() => of(ResetShoppingCartGraphQLResult(true))),
          catchError((error) => {
            error.message =
              'error while processing ' + resetShoppingCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(ResetShoppingCartGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + resetShoppingCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(ResetShoppingCartGraphQLResult(false, error))
      })
    )
}

export const verifyCartGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<VerifyCartGraphQLAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.VerifyCartGraphQL),
      switchMap((action: VerifyCartGraphQLAction) => {
        const obs = defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query verifyShoppingCart($input: ShoppingCartInput!) {
                verifyShoppingCart(input: $input) {
                  results {
                    errorCode
                    errorType
                    message
                    lineItemIds
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
              },
            },
          })

          // check & persist creditLimit
          const CreditLimitExceeded = res.data.verifyShoppingCart.results.find((result) => {
            return result.errorCode === 'CreditLimitExceeded'
          })
          const exceeded = CreditLimitExceeded !== undefined
          await db.upsertLocal('creditLimit', {
            exceeded: exceeded,
          })
          return res.data.verifyShoppingCart
        }).pipe(
          retry(1),
          switchMap((result) => of(VerifyCartGraphQLResult(result.results))),
          catchError((error) => {
            error.message =
              'error while processing ' + verifyCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(VerifyCartGraphQLResult([error]))
          })
        )
        if (action.pollInterval) {
          return timer(0, action.pollInterval).pipe(switchMap(() => obs))
        }
        return obs
      }),
      catchError((error) => {
        error.message = 'error in ' + verifyCartGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(VerifyCartGraphQLResult([error]))
      })
    )
}

export const createUpdateCartEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<UpdateCartAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.UpdateCart),
      concatMap((action: UpdateCartAction) =>
        defer(async () => {
          await changeMetaData(db, 'carts', { isUpdating: true })
          return action
        })
      ),
      switchMap((action: UpdateCartAction) =>
        Axios.post(
          'v2/shoppingCart/update/' + action.cart.id,
          { shoppingCart: action.cart },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        ).pipe(
          retry(1),
          map((res: AxiosResponse<ShoppingCartResponse>) => res.data.data.shoppingCarts[0]),
          // we emit a UpdateCartResultAction for components, who must know the result of the transaction in order to perform a task
          // use with care
          concatMap((cart: ShoppingCart) =>
            defer(async () => {
              const collection = db.carts
              await changeMetaData(db, 'carts', { isUpdating: false })
              await collection.upsert(cart)
              return cart
            })
          ),
          mergeMap((result: ShoppingCart) =>
            of(loadSingleCartSuccess(result), updateCartResult(result))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + createUpdateCartEpic.name + ' ' + error.message
            handleError(error)
            return of(updateCartResult(undefined, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createUpdateCartEpic.name + ' ' + error.message
        handleError(error)
        return of(updateCartResult(undefined, error))
      })
    )
}

export const createQueueErrorAction = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$) =>
    actions$.pipe(
      ofType(CartsActionTypes.QueueErrorAction),
      concatMap(() => from(changeMetaData(db, 'carts', { isUpdating: false }))),
      map(() => {
        getEventSubscription().next({
          type: EventType.Alert,
          notificationType: NotificationType.Alert,
          id: 'queue error',
          options: {
            message: 'Die Änderungen am Warenkorb konnten leider nicht vorgenommen werden.',
            title: 'Fehler',
          },
        })
        return loadCarts()
      })
    )
}

export const createAddProductToCartGraphQLAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddProductToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddProductToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate<
            {
              addShoppingCartItems: { updateResults: Omit<ShoppingCartUpdateResult, 'itemResults'> }
            },
            ShoppingCartAddItemsInput
          >({
            mutation: addItemsToCart,
            variables: {
              input: {
                cartId: action.payload.cartId,
                items: action.payload.items.map((payloadItem) => {
                  const discount = payloadItem.discount || { offerId: '', offerItemPosition: '' }
                  trackClick('add-to-cart', {
                    sapId: payloadItem.sapId,
                    amount: payloadItem.amount,
                    offerId: discount.offerId,
                    offerItemPosition: discount.offerItemPosition,
                  })
                  return { sapId: payloadItem.sapId, amount: payloadItem.amount, ...discount }
                }),
              },
            },
          })

          const results = response.data?.addShoppingCartItems.updateResults
          if (results?.success) {
            // Show notifications for each item added
            if (action.payload.context !== AddCartContextEnum.CartTemplate) {
              action.payload.items.forEach((item) => {
                const timestamp = new Date().getTime()
                const id = `added_to_cart ${timestamp}`
                getEventSubscription().next({
                  type: EventType.Data, //Multiple notifications are desired, hence using type 'Data'
                  notificationType: NotificationType.AddToCart,
                  id,
                  options: {
                    title: item.title,
                    id,
                  },
                })
              })
            }
          }
          return addProductToCartGraphQLResult(results || null)
        }).pipe(
          retry(1),
          catchError((error) => {
            error.message =
              'error in ' + createAddProductToCartGraphQLAction.name + ' ' + error.message
            handleError(error)
            return of(addProductToCartGraphQLResult(null))
          })
        )
      })
    )
  }
}

export const addCartTemplatesToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplatesToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddCartTemplatesToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplatesToShoppingCart($input: ShoppingCartAddCartTemplateInput!) {
                addCartTemplatesToShoppingCart(input: $input) {
                  updateResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.payload.cartId,
                cartTemplateIds: action.payload.cartTemplateIds,
              },
            },
          })
          const results = response.data?.addCartTemplatesToShoppingCart?.updateResults

          const timestamp = new Date().getTime()
          const notificationId = `${action.payload.cartId}-${NotificationType.AddCartTemplateToCart}-${timestamp}`
          if (results?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplateToCart,
              id: notificationId,
              options: {
                cartName: action.payload.cartName ?? '',
                cartTemplateName: action.payload.cartTemplateName,
              },
            })
          }
          return addCartTemplatesToCartGraphQLResult(results || null)
        }).pipe(
          retry(1),
          catchError((error) => {
            error.message =
              'error in ' + addCartTemplatesToCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(addCartTemplatesToCartGraphQLResult(null))
          })
        )
      })
    )
  }
}

export const addCartTemplateItemsToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplateItemsToCartGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddCartTemplateItemsToCartGraphQL),
      concatMap((action) => {
        return defer(async () => {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplateItemsToCart($input: AddCartTemplateItemsToCartInput!) {
                addCartTemplateItemsToCart(input: $input) {
                  success
                  successItemCount
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                cartTemplateId: action.cartTemplateId,
                exclude: action.exclude,
                include: action.include,
                search: action.search,
              },
            },
          })
          const results = response.data?.addCartTemplateItemsToCart

          const timestamp = new Date().getTime()
          const notificationId = `${action.cartId}-${NotificationType.AddCartTemplateItemsToCart}-${timestamp}`
          if (results?.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplateItemsToCart,
              id: notificationId,
              options: {
                successItemCount: results?.successItemCount,
                cartName: action.cartName ?? '',
              },
            })
          }
          return addCartTemplateItemsToCartGraphQLResult(
            results.success,
            results.successItemCount,
            results.errorCode,
            results.errorMessage
          )
        }).pipe(
          retry(1),
          mergeMap((results) =>
            of(
              addCartTemplateItemsToCartGraphQLResult(
                results.success,
                results.successItemCount,
                results.errorCode,
                results.errorMessage
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error in ' + addCartTemplateItemsToCartGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(
              addCartTemplateItemsToCartGraphQLResult(false, 0, error.errorMessage, error.message)
            )
          })
        )
      })
    )
  }
}

export const createNextAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  getCollection$: GetCollection$
) => {
  return (actions$) =>
    actions$.pipe(
      ofType(CartsActionTypes.QueueNextAction),
      filter(() => actionQueue.length > 0),
      map(() => {
        return actionQueue.shift()
      }),
      concatMap((action) =>
        forkJoin(
          of(action),
          getCollection$('carts').pipe(
            first((coll) => coll !== undefined),
            concatMap((coll) => from(coll.find().exec())),
            catchError((error) => {
              return errorHandling(error)
            })
          )
        )
      ),
      switchMap(([action, carts]: [CartsAction, RxDocument<ShoppingCart>[]]) => {
        if (action.type === CartsActionTypes.LoadCarts) {
          let updateWasTrue = false
          return defer(async () => {
            const currentMetadata = await getMetaData(db, 'carts')
            //only if there are no other pending actions
            //we change isUpdating to false
            //this means that the refetch is running in the background
            //and should not block customers from sending their cart
            if (actionQueue.length === 0) {
              updateWasTrue = currentMetadata.isUpdating
              await changeMetaData(db, 'carts', { isUpdating: false })
            }
          }).pipe(
            switchMap(() =>
              Axios.request({
                url: 'v2/shoppingCarts',
              }).pipe(
                retry(1),
                switchMap((res: AxiosResponse<ShoppingCartResponse>) => {
                  return defer(async () => {
                    const newCarts = res.data.data.shoppingCarts || []
                    await syncDataWithDatabase('carts', newCarts, 'id')

                    if (updateWasTrue) {
                      await changeMetaData(db, 'carts', { isUpdating: true })
                    }

                    return res.data.data.shoppingCarts || []
                  })
                }),
                switchMap((shoppingCarts: ShoppingCart[]) =>
                  of(serviceSuccess(action), loadCartsResult(shoppingCarts))
                ),
                catchError((error) => {
                  error.message = 'error in ' + createNextAction.name + ' ' + error.message
                  handleError(error)

                  getEventSubscription().next({
                    type: EventType.Alert,
                    notificationType: NotificationType.Alert,
                    id: 'cart_loading_failed',
                    options: {
                      message: 'Die Warenkörbe konnten nicht geladen werden',
                      title: 'Fehler',
                      id: 'cart_loading_failed',
                    },
                  })

                  // since this is a read-only action, we don't need to perform
                  // queue failover and error handling
                  // in fact this will lead to an endless loop of loadCarts attempts
                  // when there is a problem with fetching (i.e. network problems)
                  return of(serviceSuccess(action), loadCartsResult(undefined, error))
                  /*return errorHandling(error).pipe(
                  switchMap((action: QueueErrorAction) =>
                    of(action, loadCartsResult(undefined, error))
                  )
                )*/
                })
              )
            )
          )
        } else if (action.type === CartsActionTypes.UpdateCartArticleAmountAction) {
          const cAction = action as UpdateCartArticleAmountAction
          const shoppingCart = carts.find((s) => s.id === cAction.cartId)
          if (shoppingCart === undefined) {
            return errorHandling(new Error(`Cart ${cAction.cartId} not found locally`))
          }

          return defer(async () => {
            const articleIndex = shoppingCart.articleList.findIndex(
              (a) => a.id === cAction.articleId
            )
            if (shoppingCart.articleList[articleIndex]?.shoppingCart) {
              await shoppingCart.incrementalModify((data: ShoppingCart) => {
                if (data?.articleList?.[articleIndex]?.shoppingCart !== undefined) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  data.articleList[articleIndex].shoppingCart!.amount = cAction.newAmount
                }
                return data
              })
            }
          }).pipe(
            switchMap(() =>
              Axios.post(
                'v2/shoppingCart/update/' + shoppingCart.id,
                { shoppingCart },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              ).pipe(
                retry(1),
                map((response: AxiosResponse<ShoppingCartResponse>) => {
                  return serviceSuccess(action, response.data.data.shoppingCarts[0])
                }),
                catchError((error) => errorHandling(error))
              )
            )
          )
        } else if (action.type === CartsActionTypes.RemoveArticleFromCartAction) {
          const cAction = action as RemoveArticleFromCartAction

          return defer(async () => {
            const shoppingCart = carts.find((s) => s.id === cAction.cartId)
            if (shoppingCart) {
              if (
                shoppingCart.articleList.findIndex((a) => a.id === cAction.articleId) === 0 &&
                shoppingCart.articleList.length === 1
              ) {
                const index = carts.indexOf(shoppingCart)
                await shoppingCart.remove()
                carts.splice(index, 1)
              } else {
                await shoppingCart.incrementalModify((data) => {
                  const articleIndex = data.articleList.findIndex((a) => a.id === cAction.articleId)
                  if (articleIndex !== -1) {
                    data.articleList.splice(articleIndex, 1)
                  }
                  return data
                })
              }
            }
          }).pipe(
            switchMap(() =>
              Axios.post(
                'v2/shoppingCart/removeArticle',
                {
                  shoppingCartId: cAction.cartId,
                  articleId: cAction.articleId,
                },
                { headers: { 'Content-Type': 'application/json' } }
              ).pipe(
                retry(1),
                map((response: AxiosResponse<ShoppingCartResponse>) => {
                  if (response.status === 204) {
                    return serviceSuccess(action)
                  }
                  return serviceSuccess(action, response.data.data.shoppingCarts[0])
                }),
                catchError((error) => errorHandling(error))
              )
            )
          )
        } else if (action.type === CartsActionTypes.AddArticleToCart) {
          const cAction = action as AddArticleToCartAction

          let ring
          if (cAction.ring === 'Lagerartikel') {
            ring = 'O'
          }
          if (cAction.ring === 'Sonderbestellung') {
            ring = 'S'
          }

          //inform the ui that we are adding an element to a sc
          toastNotifier('BEFORE SC ADD', {
            addedAmount: cAction.amount,
            articleId: cAction.articleId,
            articleName: cAction.articleName,
            shoppingCartId: cAction.cart.id,
            shoppingCartName: cAction.cart.name,
            articleRing: cAction.ring,
            currentAmountInCart: getCurrentArticleAmount(carts, cAction.cart.id, cAction.articleId),
          })

          return Axios.post(
            'v2/shoppingCart/addArticle',
            {
              shoppingCartId: cAction.cart.id,
              articleId: cAction.articleId,
              ring: ring,
              amount: cAction.amount,
              checkConflict: true,
            },
            { headers: { 'Content-Type': 'application/json' } }
          ).pipe(
            retry(1),
            tap(async () => {
              await Haptics.vibrate({ duration: 50 })
            }),
            switchMap((response: AxiosResponse<ShoppingCartResponse>) => {
              const cart = response.data.data.shoppingCarts[0]
              return of(serviceSuccess(action, cart), addArticleToCartResult(cart))
            }),
            catchError((error) => errorHandling(error))
          )
        } else if (action.type === CartsActionTypes.RemoveCartAction) {
          const cAction = action as RemoveCartAction
          const cartId = cAction.cartId

          return defer(async () => {
            const collection = db.carts
            const cart = await collection
              .findOne({
                selector: {
                  id: cartId,
                },
              })
              .exec()
            if (cart) {
              await cart.remove()
            }
          }).pipe(
            switchMap(() =>
              Axios.post(
                'shoppingCart/delete/' + cartId,
                {},
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              ).pipe(
                retry(1),
                map((response: AxiosResponse<ShoppingCartResponse>) => serviceSuccess(action)),
                catchError((error) => errorHandling(error))
              )
            )
          )
        } else if (action.type === CartsActionTypes.AddTemplateToCart) {
          const cAction = action as AddTemplateToCartAction
          return Axios.get(
            'v2/templates/shiftTemplatePositionsToShoppingCart/' +
              cAction.templateId +
              '/' +
              cAction.cartId
          ).pipe(
            retry(1),
            map((response: AxiosResponse<ShoppingCartResponse>) => {
              // Let Device vibrate as haptic confirmation
              // TODO vibration
              //this.vibration.vibrate(500)
              return serviceSuccess(action, response.data.data.shoppingCarts[0])
            }),
            catchError((error) => errorHandling(error))
          )
        } else if (action.type === CartsActionTypes.AddOfferToCart) {
          const cAction = action as AddOfferToCartAction
          if (cAction.articleId && cAction.articleName && cAction.ring && cAction.amount) {
            toastNotifier('BEFORE SC ADD', {
              addedAmount: cAction.amount,
              articleId: cAction.articleId,
              articleName: cAction.articleName,
              shoppingCartId: cAction.cart.id,
              shoppingCartName: cAction.cart.name,
              articleRing: cAction.ring,
              currentAmountInCart: getCurrentArticleAmount(
                carts,
                cAction.cart.id,
                cAction.articleId
              ),
            })
          }

          let url = 'v2/offer/addPositionsToShoppingCart/' + cAction.cart.id + '/' + cAction.offerId
          if (cAction.articleId) {
            url = `v2/offer/addOnePositionToShoppingCart/${cAction.cart.id}/${cAction.offerId}/${cAction.articleId}`
          }
          return Axios.request({
            url: url,
            method: 'POST',
            data: {
              amount: cAction.amount,
            },
          }).pipe(
            retry(1),
            map((response: AxiosResponse<ShoppingCartResponse>) => {
              // Let Device vibrate as haptic confirmation
              //this.vibration.vibrate(500)
              return serviceSuccess(action, response.data.data.shoppingCarts[0])
            }),
            catchError((error) => errorHandling(error))
          )
        }

        return of(noop())
      }),
      catchError((error) => errorHandling(error))
    )
}

export const addOrderItemsToCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<AddOrderItemsToCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddOrderItemsToCartGraphQL),
      concatMap((action: AddOrderItemsToCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddOrderItemsToCartMutation,
            AddOrderItemsToCartMutationVariables
          >({
            mutation: gql`
              mutation addOrderItemsToCart($input: AddOrderItemsToCartInput!) {
                addOrderToCart(input: $input) {
                  success
                  errorMessage
                  successItemCount
                }
              }
            `,
            variables: {
              input: {
                orderId: action.input.orderId,
                cartId: action.input.cartId,
                include: action.input.include,
                exclude: action.input.exclude,
                amount: action.input.amount,
                search: action.input.search,
              },
            },
          })

          if (
            response?.data?.addOrderToCart.success &&
            response?.data?.addOrderToCart.successItemCount === action.selectedItemCount
          ) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.input.orderId}-${NotificationType.AddOrderItemsToCart}-${timestamp}`

            getEventSubscription().next({
              options: { successItemCount: response.data.addOrderToCart.successItemCount },
              type: EventType.Toast,
              notificationType: NotificationType.AddOrderItemsToCart,
              id: notificationId,
            })
          } else {
            // Note: We are merely assuming that some (or all) items were not successful due to being diverse products or cancelled offer items.
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.OrderAddDiverseArticlesToCartWarning,
              id: `${action.input.orderId}-${NotificationType.OrderAddDiverseArticlesToCartWarning}`,
              options: {
                body: '',
                heading: '',
              },
            })
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              AddOrderItemsToCartGraphQLResult(
                result?.addOrderToCart.success ?? false,
                '',
                '',
                action.input.orderId
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              AddOrderItemsToCartGraphQLResult.name +
              ' ' +
              error.message
            handleError(error)
            return of(AddOrderItemsToCartGraphQLResult(false, '', '', action.input.orderId))
          })
        )
      )
    )
}
export const addOrReplaceOfferInCartGraphQLEffect = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<AddOrReplaceOfferInCartGraphQLAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddOrReplaceOfferInCartGraphQL),
      concatMap((action: AddOrReplaceOfferInCartGraphQLAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddOrReplaceOfferInCartMutation,
            AddOrReplaceOfferInCartMutationVariables
          >({
            mutation: gql`
              mutation addOrReplaceOfferInCart($input: AddOrReplaceOfferInCartInput!) {
                addOrReplaceOfferInCart(input: $input) {
                  success
                  errorMessage
                  successItemCount
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                offerId: action.offerId,
                overwrite: action.overwrite,
                include: action.include,
                exclude: action.exclude,
                amount: action.amount,
                search: action.search,
              },
            },
          })

          if (
            response?.data?.addOrReplaceOfferInCart.success &&
            response?.data?.addOrReplaceOfferInCart.successItemCount === action.selectedItemCount
          ) {
            const timestamp = new Date().getTime()
            const notificationId = `${action.offerId}-${NotificationType.AddOrderItemsToCart}-${timestamp}`

            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddOrReplaceOfferInCart,
              id: notificationId,
              options: {
                offerName: action.offerName,
              },
            })
          } else {
            // Note: We are merely assuming that some (or all) items were not successful due to being diverse products or cancelled offer items.
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.OfferDiverseProductsOrCancelledItemsCart,
              id: `${action.offerId}-${NotificationType.OfferDiverseProductsOrCancelledItemsCart}`,
              options: {
                body: '',
                heading: '',
              },
            })
          }

          return response.data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              addOrReplaceOfferInCartGraphQLResult(result?.addOrReplaceOfferInCart.success ?? false)
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' +
              addOrReplaceOfferInCartGraphQLEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(addOrReplaceOfferInCartGraphQLResult(false, error))
          })
        )
      )
    )
}

export const createNextActionAfterServiceEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<ServiceSuccessAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.ServiceSuccessAction),
      tap((action: ServiceSuccessAction) => {
        const shoppingCart: ShoppingCart | undefined = action.cart
        const sourceAction: CartsAction = action.action

        // if (sourceAction.type === ActionTypes.AddArticleToCart) {
        //   const cAction = sourceAction as AddArticleToCartAction
        //   let name =
        //     cAction.articleName.length > 30
        //       ? cAction.articleName.substring(0, 30) + '...'
        //       : cAction.articleName
        //   notifier(name + ' wurde in den Warenkorb ' + shoppingCart.name + ' gelegt.')
        // } else
        if (shoppingCart) {
          if (sourceAction.type === CartsActionTypes.AddTemplateToCart) {
            toastNotifier('Das Template wurde in den Warenkorb ' + shoppingCart.name + ' gelegt.')
          } else if (sourceAction.type === CartsActionTypes.AddOfferToCart) {
            toastNotifier(
              'Die Artikel aus dem Angebot wurden dem Warenkorb ' +
                shoppingCart.name +
                ' hinzugefügt.'
            )
          }
        }
      }),
      switchMap(
        (
          action: ServiceSuccessAction
        ): Observable<UpdateStateCartAction | QueueNextAction | QueueSuccessAction> => {
          const shoppingCart: ShoppingCart | undefined = action.cart
          const sourceAction: CartsAction = action.action

          if (actionQueue.length === 0) {
            actionQueueRunning = false
            return defer(async () => {
              const collection = db.carts
              await changeMetaData(db, 'carts', { isUpdating: false })
              if (shoppingCart) {
                await collection.upsert(shoppingCart)
              }
            }).pipe(
              switchMap(() => {
                if (shoppingCart) {
                  return of(updateStateCart(shoppingCart), queueSuccess())
                }
                return of(queueSuccess())
              })
            )
          }

          // in that case we can't update the state, since the new amount
          // of the a later optimistic action would be overwritten
          // !! generally we should update in state only things that changed
          // which should not be a problem with graphql endpoint which gets
          // and re returns already the fields of interest and not always the whole shopping Cart
          // Then optionally after finishing the queue the whole shopping Cart could be synchronized
          if (sourceAction.type === CartsActionTypes.UpdateCartArticleAmountAction) {
            return of(queueNext())
          }

          return defer(async () => {
            if (shoppingCart) {
              const collection = db.carts
              await collection.upsert(shoppingCart)
            }
          }).pipe(
            switchMap(() => {
              if (shoppingCart) {
                return of(updateStateCart(shoppingCart), queueNext())
              }
              return of(queueNext())
            })
          )
        }
      ),
      catchError((error) => errorHandling(error))
    )
}

export const createAddActionToQueueEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<CartsAction>) =>
    actions$.pipe(
      ofType(
        CartsActionTypes.UpdateCartArticleAmountAction,
        CartsActionTypes.RemoveArticleFromCartAction,
        CartsActionTypes.AddArticleToCart,
        CartsActionTypes.RemoveCartAction,
        CartsActionTypes.AddTemplateToCart,
        CartsActionTypes.AddOfferToCart,
        CartsActionTypes.LoadCarts
      ),
      tap((action: CartsAction) => {
        actionQueue.push(action)
      }),
      filter(() => {
        return !actionQueueRunning
      }),
      concatMap(() =>
        defer(async () => {
          actionQueueRunning = true
          const metaData: MetaData = await getMetaData(db, 'carts')
          if (!metaData.isUpdating) {
            await changeMetaData(db, 'carts', { isUpdating: true })
          }
          return queueNext()
        })
      ),
      catchError((error) => errorHandling(error))
    )
}

export const createSendCartEpic = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<SendCartAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.SendCart),
      switchMap((action: SendCartAction) => {
        if (actionQueue.length > 0 || actionQueueRunning) {
          // TODO stop here, if there are pending actions
        }
        return of(action)
      }),
      concatMap((action: SendCartAction) => {
        const obs = defer(async () => {
          await changeMetaData(db, 'carts', { isUpdating: true })
        })

        return obs.pipe(
          concatMap(() => {
            if (action.skipCheck) {
              return of({})
            } else {
              return Axios.request({
                method: 'POST',
                url: 'shoppingCart/check/' + action.cart.id,
                data: {},
                headers: {
                  'Content-Type': 'application/json',
                },
              })
            }
          }),
          concatMap(() =>
            defer(async () => {
              const userDoc = await db.getLocal<Customer>('user')
              const user = userDoc?.toJSON()

              const notifyShoppingCart =
                user?.data.general?.isSubUser &&
                user?.data.general?.permissions?.subUser.indexOf(
                  AvailablePermissions.notifyShoppingCart
                ) !== -1
              const orderShoppingCart =
                !user?.data.general?.isSubUser ||
                (user?.data.general?.isSubUser &&
                  user?.data.general?.permissions?.subUser.indexOf(
                    AvailablePermissions.orderShoppingCart
                  ) !== -1)

              return { notifyShoppingCart, orderShoppingCart }
            })
          ),
          concatMap(({ notifyShoppingCart, orderShoppingCart }: PermCheckProps) => {
            let resultType: SendCartResultType | null = null
            if (orderShoppingCart) {
              resultType = 'submit'
              return Axios.request({
                method: 'POST',
                url: 'shoppingCart/submit/async/' + action.cart.id,
                data: {},
                headers: {
                  'Content-Type': 'application/json',
                },
              }).pipe(map(() => resultType))
            }
            if (notifyShoppingCart) {
              resultType = 'notification'
              return Axios.request({
                method: 'GET',
                url: 'shoppingCart/notify/' + action.cart.id,
                data: {},
              }).pipe(map(() => resultType))
            }

            throw new Error(
              'Illegal State: User does neither have the permission to send nor to notify an order'
            )
          }),
          concatMap((resultType: SendCartResultType) => {
            return defer(async () => {
              const userDoc = await db.getLocal<Customer>('user')
              const user = userDoc?.toJSON()
              const orderShoppingCart =
                user?.data.general.permissions?.subUser.indexOf(
                  AvailablePermissions.orderShoppingCart
                ) !== -1
              const collection = db.carts
              const cart = await collection
                .findOne({
                  selector: {
                    id: action.cart.id,
                  },
                })
                .exec()

              const cartJson: ShoppingCart = cart.toJSON()
              if (orderShoppingCart) {
                // remove cart from entity collection
                if (cart) {
                  await cart.remove()
                }
              }
              await changeMetaData(db, 'carts', { isUpdating: false })

              return of(sendCartResult(true, resultType, cartJson))
            }).pipe(concatAll())
          }),
          catchError((error) => {
            error.message = 'error in ' + createSendCartEpic.name + ' ' + error.message
            handleError(error)
            return defer(async () => {
              await changeMetaData(db, 'carts', { isUpdating: false })
            }).pipe(
              map(() => {
                // status 422 means unprocessable entity
                // this is errorcode is used by the check api, which provides
                // important information, we must show the user to resolve them
                // i.e. credit limit exceeded
                if (error.response && error.response.status === 422) {
                  const messages = error.response.data.messages
                  return sendCartResult(false, undefined, undefined, messages)
                }

                return sendCartResult(false, undefined)
              })
            )
          })
        )
      })
    )
}

export const createMoveCartEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  getCollection$: GetCollection$
) => {
  return (actions$: Observable<MoveCartAction>) =>
    actions$.pipe(
      ofType(CartsActionTypes.MoveCart),
      tap(() => {
        toastNotifier('Warenkorb wird verschoben')
      }),
      concatMap((action: MoveCartAction) =>
        getCollection$('carts').pipe(
          first((coll) => coll !== undefined),
          concatMap((coll) =>
            defer(async () => {
              await changeMetaData(db, 'carts', { isUpdating: true })
              const { cart: sourceCart, collection: sourceCollection } = await getCartDocument(
                db,
                action.sourceCartId
              )
              const { cart: targetCart, collection: targetCollection } = await getCartDocument(
                db,
                action.targetCartId
              )

              if (!sourceCart || !targetCart || isSimpleCart(sourceCart)) {
                return null
              }

              const data = { targetCart, sourceCart, targetCollection, sourceCollection }

              // optimistic update is impossible
              if (isSimpleCart(targetCart)) {
                return data
              }

              const targetCartJson = {
                ...targetCart.toJSON(),
                articleList: [...targetCart.articleList],
              }
              const sourceCartJson = {
                ...sourceCart.toJSON(),
                articleList: [...sourceCart.articleList],
              }

              // TODO: implement roll back
              for (const articleId in sourceCart.articleList) {
                const sourceArticle = sourceCartJson.articleList[articleId]
                const targetArticle = targetCartJson.articleList.find(
                  (article: ArticleListItem) => article.id === sourceArticle.id
                )

                if (targetArticle === undefined) {
                  targetCartJson.articleList.push(sourceArticle)
                } else {
                  if (targetArticle.shoppingCart && sourceArticle.shoppingCart) {
                    targetArticle.shoppingCart.amount += sourceArticle.shoppingCart.amount
                  }
                }
              }
              await coll.upsert(targetCartJson)
              await sourceCart.remove()
              return data
            }).pipe(
              catchError((error) => {
                error.message =
                  'error while moving products from one card into another - in ' +
                  createMoveCartEpic.name +
                  ' ' +
                  error.message
                handleError(error)
                return of(null)
              })
            )
          ),
          switchMap((data) => {
            if (!data) {
              return of(moveCartResult(undefined, new Error('source or target cart is not found')))
            }

            return Axios.post(
              'v2/shoppingCart/shiftPositionsToShoppingcart/' +
                data.sourceCart.id +
                '/' +
                data.targetCart.id,
              {},
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            ).pipe(
              retry(1),
              concatMap((result: AxiosResponse<ShoppingCartResponse>) => {
                return defer(async () => {
                  const targetCart = result.data.data.shoppingCarts[0]
                  if (isSimpleCart(data.targetCart)) {
                    await db.carts.upsert(targetCart)
                    await data.sourceCart.remove()
                  }

                  await changeMetaData(db, 'carts', { isUpdating: false })
                  //we emit a MoveCartResultAction for components, who must know the result of the transaction in order to perform a task
                  //use with care
                  return of(
                    moveCartResult(result.data.data.shoppingCarts[0], undefined, data.sourceCart.id)
                  )
                })
              }),
              catchError((error) => {
                error.message =
                  'error while sending data to remote in ' +
                  createMoveCartEpic.name +
                  ' ' +
                  error.message
                handleError(error)
                let returnError
                let msg = null
                if (
                  (error as AxiosError).response &&
                  Array.isArray(error.response?.data?.messages) &&
                  error.response?.data?.messages.length > 0
                ) {
                  msg = error.response?.data?.messages[0]['message']
                  returnError = error
                } else {
                  returnError = error
                }

                if (msg !== null) {
                  getEventSubscription().next({
                    type: EventType.Alert,
                    notificationType: NotificationType.Alert,
                    id: 'move_cart_error',
                    options: {
                      message: msg,
                      title: 'Fehler',
                    },
                  })
                }
                return of(moveCartResult(undefined, returnError))
              })
            )
          })
        )
      )
    )
}

export const createAddMarkForNotification = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<AddMarkForNotificationAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.AddMarkForNotification),
      concatMap((action: AddMarkForNotificationAction) => {
        return defer(async () => {
          const col = db.markedcarts
          await col.insert({ id: action.cartId })
          return noop()
        })
      }),
      catchError((err) => {
        err.message = 'error in ' + createAddMarkForNotification.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const createRemoveMarkForNotification = (db: RxDatabase<CollectionsOfDatabase>) => {
  return (actions$: Observable<RemoveMarkForNotificationAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.RemoveMarkForNotification),
      concatMap((action: RemoveMarkForNotificationAction) => {
        return defer(async () => {
          const col = db.markedcarts
          const markedCart = await col
            .findOne({
              selector: {
                id: action.cartId,
              },
            })
            .exec()
          if (markedCart) {
            await markedCart.remove()
          }
          return noop()
        })
      }),
      catchError((err) => {
        err.message = 'error in ' + createRemoveMarkForNotification.name + ' ' + err.message
        handleError(err)
        return of(noop())
      })
    )
  }
}

export const fetchAndReplaceCustomTitles = async (
  action: UpdateCustomProductsGraphQLAction,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  db: RxDatabase<CollectionsOfDatabase>
) => {
  const offerId = action.cart.offerId
  const requestItems = action.shoppingCartItems
    .filter((item) => item.offerItemPosition !== undefined && item.offerItemPosition !== null)
    .map(({ sapId, offerItemPosition }) => ({
      sapId,
      offerItemPosition,
    }))

  if (requestItems.length === 0) return

  const response = await apolloClient.query<UpdateCustomProductResponse>({
    query: OFFER_ITEM_TITLES,
    variables: {
      input: {
        offerId,
        offerItems: requestItems,
      },
    },
  })

  const cartToBackup = { ...action.cart }
  const responseItems = response.data.getOfferItemTitles
  if (responseItems.length > 0) {
    cartToBackup.items.forEach((backupCartItem) => {
      const matchingResponseItem = responseItems.find(({ sapId, offerItemPosition }) => {
        return (
          sapId === backupCartItem.sapId && offerItemPosition === backupCartItem.offerItemPosition
        )
      })
      if (!matchingResponseItem) return
      backupCartItem.product.title = matchingResponseItem.title
      backupCartItem.product.isCustomTitleReplaced = true
    })
    await db.cartsv2.upsert(cartToBackup)
  }
}

export const createUpdateCustomProductsGraphQLAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCustomProductsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.UpdateCustomProductsGraphQL),
      switchMap((action: UpdateCustomProductsGraphQLAction) => {
        return defer(async () => {
          await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: true })
          await fetchAndReplaceCustomTitles(action, apolloClient, db)
          await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: false })
        }).pipe(
          retry(1),
          switchMap(() => of(UpdateShoppingCartPricesGraphQLResult())),
          catchError(async (error) => {
            error.message =
              'error while processing ' +
              createUpdateCustomProductsGraphQLAction.name +
              ' ' +
              error.message
            handleError(error)
            await changeMetaData(db, 'cartsv2', { isUpdatingCustomTitles: false })
            return of(UpdateShoppingCartPricesGraphQLResult())
          }),
          catchError((error) => {
            error.message =
              'error in ' + createUpdateCustomProductsGraphQLAction.name + ' ' + error.message
            handleError(error)
            return of(UpdateShoppingCartPricesGraphQLResult())
          })
        )
      })
    )
  }
}

async function getEans(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  action: GetVoltimumPointsGraphQLAction
) {
  const eanResponse = await apolloClient.query<GetProductsQuery, GetProductsQueryVariables>({
    query: gql`
      query getProducts($sapIds: [String!]!) {
        getProducts(sapIds: $sapIds) {
          eans
          sapId
        }
      }
    `,
    variables: {
      sapIds: action.items.map(({ sapId }) => sapId),
    },
  })

  const eanLookup: Array<{ ean: string; sapId: string }> = []
  eanResponse.data.getProducts.forEach((product) => {
    if (product.eans.length > 0) eanLookup.push({ sapId: product.sapId, ean: product.eans[0] })
  })
  return eanLookup
}

async function fetchVoltimumPoints(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  eanLookup: Array<{ ean: string; sapId: string }>,
  action: GetVoltimumPointsGraphQLAction
): Promise<Array<{ sapId: string; points: number }>> {
  const voltimumPointsResponse = await apolloClient.query<
    GetVoltimumPointsQuery,
    GetVoltimumPointsQueryVariables
  >({
    query: gql`
      query getVoltimumPoints($eans: [String!]!) {
        getVoltimumPoints(eans: $eans) {
          points
          multiplier
          ean
        }
      }
    `,
    variables: {
      eans: eanLookup.map(({ ean }) => ean),
    },
  })
  const sapIdsAndPoints: Array<{ sapId: string; points: number }> = []
  voltimumPointsResponse.data.getVoltimumPoints.forEach((pointsElement) => {
    const eanSapIdTuple = eanLookup.find((lookUpElement) => lookUpElement.ean === pointsElement.ean)
    if (!eanSapIdTuple) return
    const foundItem = action.items.find(({ sapId }) => sapId === eanSapIdTuple.sapId)
    if (!foundItem) return
    sapIdsAndPoints.push({
      sapId: foundItem.sapId,
      points: pointsElement.points * pointsElement.multiplier,
    })
  })
  return sapIdsAndPoints
}

export const getVoltimumPointsAction = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetVoltimumPointsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetVoltimumPointsGraphQL),
      switchMap((action: GetVoltimumPointsGraphQLAction) => {
        return defer(async () => {
          const eanLookup = await getEans(apolloClient, action)
          if (eanLookup.length === 0) return
          const sapIdsAndPoints = await fetchVoltimumPoints(apolloClient, eanLookup, action)
          action.onPointsCalculated(sapIdsAndPoints)
        }).pipe(
          retry(1),
          switchMap(() => of(GetVoltimumPointsGraphQLResult())),
          catchError((error) => {
            error.message =
              'error while processing ' + getVoltimumPointsAction.name + ' ' + error.message
            handleError(error)
            action.onPointsCalculated([])
            return of(GetVoltimumPointsGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getVoltimumPointsAction.name + ' ' + error.message
        handleError(error)
        return of(GetVoltimumPointsGraphQLResult())
      })
    )
  }
}

export const getIdsFormFieldsEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetIdsFormFieldsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetIdsFormFieldsGraphQL),
      switchMap((action: GetIdsFormFieldsGraphQLAction) =>
        defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query getIdsShoppingCartFormFields($cartId: String!) {
                getIdsShoppingCartFormFields(cartId: $cartId) {
                  success
                  errorCode
                  errorMessage
                  idsFormFields {
                    version
                    action
                    warenkorb
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          return res.data.getIdsShoppingCartFormFields
        }).pipe(retry(1))
      ),
      map((result: IdsFormFieldsResult) => getIdsFormFieldsResult(result)),
      catchError((error) => {
        error.message = 'error in ' + getIdsFormFieldsEffect.name + ' ' + error.message
        handleError(error)
        return of(getIdsFormFieldsResult(error))
      })
    )
  }
}

export const getOciFormFieldsEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (actions$: Observable<GetOciFormFieldsGraphQLAction>) => {
    return actions$.pipe(
      ofType(CartsActionTypes.GetOciFormFieldsGraphQL),
      switchMap((action: GetOciFormFieldsGraphQLAction) =>
        defer(async () => {
          const res = await apolloClient.query({
            query: gql`
              query getOciFormFieldsForCart($cartId: String!) {
                getOciFormFieldsForCart(cartId: $cartId) {
                  success
                  errorMessage
                  formFields {
                    name
                    value
                  }
                }
              }
            `,
            variables: {
              cartId: action.cartId,
            },
          })
          return res.data.getOciFormFieldsForCart
        }).pipe(retry(1))
      ),
      map((result: OciCartFormFieldsResponse) => getOciFormFieldsResult(result)),
      catchError((error) => {
        error.message = 'error in ' + getOciFormFieldsEffect.name + ' ' + error.message
        handleError(error)
        return of(getOciFormFieldsResult(error))
      })
    )
  }
}

export const addToCartFromIdsXmlEffect = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return (action$: Observable<AddToCartFromIdsXmlAction>) =>
    action$.pipe(
      ofType(CartsActionTypes.AddToCartFromIdsXml),
      concatMap((action: AddToCartFromIdsXmlAction) =>
        defer(async () => {
          const response = await apolloClient.mutate<
            AddToCartFromIdsXmlMutation,
            AddToCartFromIdsXmlMutationVariables
          >({
            mutation: gql`
              mutation addToCartFromIdsXml($input: AddToCartFromIdsXmlInput!) {
                addToCartFromIdsXml(input: $input) {
                  success
                  errorMessage
                  itemResults {
                    success
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: {
                cartId: action.cartId,
                xmlCart: action.xmlCart,
              },
            },
          })
          return response?.data?.addToCartFromIdsXml
        }).pipe(
          retry(1),
          catchError((error) => {
            error.message =
              'error while processing ' + addToCartFromIdsXmlEffect.name + ' ' + error.message
            handleError(error)
            return of(
              addToCartFromIdsXmlResult({
                success: false,
                errorMessage: error.message,
                errorCode: '',
                itemResults: [],
              })
            )
          })
        )
      ),
      map((result: ShoppingCartUpdateResultFromSchema) => addToCartFromIdsXmlResult(result)),
      catchError((error) => {
        error.message =
          'error while processing ' + addToCartFromIdsXmlEffect.name + ' ' + error.message
        handleError(error)
        return of(
          addToCartFromIdsXmlResult({
            success: false,
            errorMessage: error.message,
            errorCode: '',
            itemResults: [],
          })
        )
      })
    )
}

/**
 * This fetches the shopping carts from a user and inserts them in to RxDB cartsv2.
 * This is only required for special cases, as in other cases the feed and the feed updates take care of the cartsv2 collection.
 * @param db
 * @param apolloClient
 */
export const manuallyUpdateCartsEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (action$: Observable<ManuallyUpdateCartsActions>) =>
    action$.pipe(
      ofType(CartsActionTypes.ManuallyUpdateCarts),
      switchMap(() => {
        return defer(async () => {
          const query = GET_SHOPPING_CARTS
          const response = await apolloClient.query<
            GetShoppingCartsQuery,
            GetShoppingCartsQueryVariables
          >({
            query,
          })
          if (response?.data.getShoppingCarts) {
            const carts = response.data.getShoppingCarts
            await db.cartsv2.bulkUpsert(carts)
            return manuallyUpdateCartsResult(carts)
          }
          return manuallyUpdateCartsResult([])
        }).pipe(
          retry(1),
          catchError((error) => {
            handleError(error)
            return of(manuallyUpdateCartsResult([]))
          })
        )
      }),
      catchError((error) => {
        handleError(error)
        return of(manuallyUpdateCartsResult([]))
      })
    )
}

export const initAllCartEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  getCollection$: GetCollection$,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    addOrReplaceOfferInCartGraphQLEffect(apolloClient),
    addOrderItemsToCartGraphQLEffect(apolloClient),
    createLoadSingleCartEpic(db),
    createUpdateCartEpic(db),
    createQueueErrorAction(db),
    createNextAction(db, syncDataWithDatabase, getCollection$),
    createNextActionAfterServiceEpic(db),
    createAddActionToQueueEpic(db),
    createMoveCartEpic(db, getCollection$),
    createSendCartEpic(db),
    createAddMarkForNotification(db),
    createRemoveMarkForNotification(db),
    createUpdateCartGraphQLEffect(db, apolloClient),
    deleteCartItemsGraphQLEffect(db, apolloClient),
    emptyShoppingCartGraphQLEffect(db, apolloClient),
    getShoppingCartPricesGraphQL(db, apolloClient),
    resetShoppingCartGraphQLEffect(db, apolloClient),
    moveCartItemsGraphQLEffect(db, apolloClient),
    notifyCartGraphQLEffect(db, apolloClient),
    submitCartGraphQLEffect(db, apolloClient),
    createChangeProductAmountEffect(db, apolloClient),
    createAddProductToCartGraphQLAction(db, apolloClient),
    verifyCartGraphQLEffect(db, apolloClient),
    addCartTemplatesToCartGraphQLEffect(apolloClient),
    addCartTemplateItemsToCartGraphQLEffect(apolloClient),
    createUpdateCustomProductsGraphQLAction(db, apolloClient),
    getVoltimumPointsAction(db, apolloClient),
    getIdsFormFieldsEffect(apolloClient),
    getOciFormFieldsEffect(apolloClient),
    verifyCartInOfferGraphQLEffect(apolloClient),
    addToCartFromIdsXmlEffect(apolloClient),
    manuallyUpdateCartsEffect(db, apolloClient),
  ]
}
