import { catchError, concatMap, defer, mergeMap, Observable, of, retry, switchMap } from 'rxjs'
import {
  AddArticleToTemplateGraphQLAction,
  AddArticleToTemplateGraphQLResult,
  CartTemplateImagesUpdate,
  CreateNewTemplateGqlAction,
  createNewTemplateGqlResult,
  DeleteCartTemplateGraphQLAction,
  DeleteCartTemplateGraphQLResult,
  DeleteCartTemplateItemsGraphQLAction,
  DeleteCartTemplateItemsGraphQLResult,
  DuplicateCartTemplateGraphQLAction,
  DuplicateCartTemplateGraphQLResult,
  GetCartTemplateItemImagesAction,
  GetCartTemplateItemImagesGraphQLResult,
  GetCartTemplateItemImagesResponse,
  GetCartTemplateItemProductsAction,
  GetCartTemplateItemsAction,
  GetCartTemplateItemsGraphQLResult,
  GetCartTemplateItemsResponse,
  GetShoppingCartPricesGraphQLResult,
  TemplateActionTypes,
  UpdateCartTemplateGraphQLAction,
  UpdateCartTemplateGraphQLResult,
  UpdateCartTemplateItemAmountByIdGraphQLResult,
  UpdateCartTemplateItemAmountByIdGraphQLAction,
  AddCartTemplatesToCartTemplateGraphQLAction,
  addCartTemplatesToCartTemplateGraphQLResult,
  DeleteCartTemplateItemsGraphQLResultAction,
  UpdateCartTemplateGraphQLResultAction,
  DuplicateCartTemplateGraphQLResultAction,
  addOfferToCartTemplateGraphQLResult,
  AddOfferToCartTemplateGraphQLAction,
  DeleteCartTemplateItemsGraphQLActionBatch,
  DeleteCartTemplateItemsGraphQLResultActionBatch,
  DeleteCartTemplateItemsGraphQLResultBatch,
  AddCartTemplateItemsToCartTemplateGraphQLAction,
  addCartTemplateItemsToCartTemplateGraphQLResult,
  AddOrderItemsToCartTemplateGraphQLAction,
} from '../actions'

import {
  CartTemplateForDetailsPage,
  MaybeCompleteCartTemplateDetailsItem,
} from '@obeta/models/lib/schema-models/cart-template-details'
import { isCompleteCartTemplateDetailsItem } from '@obeta/models/lib/schema-models/utils'
import {
  CartTemplate,
  CartTemplateItem,
  GetCartTemplateListItemPricesAndStocksQuery,
  GetCartTemplateListItemPricesAndStocksQueryVariables,
} from '@obeta/schema'
import { GetCollection$, SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'

import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import { ofType } from 'redux-observable'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { createNextAction } from './cart-effects'
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { CREATE_NEW_TEMPLATE } from '../entities/cartTemplatesQueryProps'
import { CART_TEMPLATE_LIST_ITEMS } from '../queries/cartTemplateListItems'

export const updateCartTemplateItemAmountGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCartTemplateItemAmountByIdGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.UpdateCartTemplateItemAmountByIdGraphQL),
      concatMap((action: UpdateCartTemplateItemAmountByIdGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation updateCartTemplateItemAmountById(
                $input: CartTemplateUpdateItemAmountByIdInput!
              ) {
                updateCartTemplateItemAmountById(input: $input) {
                  success
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: {
                templateId: action.templateId,
                id: action.id,
                amount: action.amount,
              },
            },
          })
          const data = response?.data?.updateCartTemplateItemAmountById
          if (!response.data) {
            throw new Error('Response of updateCartTemplateItemAmount call is empty')
          }
          const template = await db.carttemplates.findOne(action.templateId).exec()

          // Update the amount of the template item in the RxDB
          await template.modify((oldTemplate: CartTemplate) => {
            const items = oldTemplate.cartTemplateItems.items.map((item) => {
              if (item.id === action.id) {
                return {
                  ...item,
                  amount: action.amount,
                }
              }
              return item
            })
            return {
              ...oldTemplate,
              cartTemplateItems: {
                ...oldTemplate.cartTemplateItems,
                items: items,
              },
            }
          })
          await changeMetaData(db, 'carttemplates', { isUpdating: false })
          return data
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              UpdateCartTemplateItemAmountByIdGraphQLResult(
                result.success,
                result?.errorCode ?? '',
                result.errorMessage ?? ''
              )
            )
          ),
          catchError((error) => {
            return defer(async () => {
              await changeMetaData(db, 'carttemplates', { isUpdating: false })
              error.message = 'error in updateCartTemplateItemAmount:' + error.message
              handleError(error)
              return of(
                UpdateCartTemplateItemAmountByIdGraphQLResult(false, error.code, error.message)
              )
            })
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in updateCartTemplateItemAmount:' + error.message
        handleError(error)
        return of(UpdateCartTemplateItemAmountByIdGraphQLResult(false, error.code, error.message))
      })
    )
}

export const addCartTemplatesToCartTemplates = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplatesToCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.AddCartTemplatesToCartTemplateGraphQL),
      concatMap((action: AddCartTemplatesToCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplatesToCartTemplate($input: CartTemplateAddToCartTemplateInput!) {
                addCartTemplatesToCartTemplate(input: $input) {
                  success
                  errorCode
                  errorMessage
                  responseModels {
                    __typename
                  }
                  __typename
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (response?.data?.addCartTemplatesToCartTemplate.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplatesToCartTemplate,
              id: `${action.input.targetCartTemplateId}-${NotificationType.AddCartTemplatesToCartTemplate}`,
              options: {
                targetTemplateName: action.targetTemplateName,
                originTemplateNames: action.originTemplateNames,
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.addCartTemplateToCartTemplate
        }).pipe(
          retry(1),
          mergeMap(() => of(addCartTemplatesToCartTemplateGraphQLResult(true))),
          catchError((error) => {
            handleError(error)
            return of(addCartTemplatesToCartTemplateGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(addCartTemplatesToCartTemplateGraphQLResult(false, error))
      })
    )
}

export const addCartTemplateItemsToCartTemplates = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddCartTemplateItemsToCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.AddCartTemplateItemsToCartTemplateGraphQL),
      concatMap((action: AddCartTemplateItemsToCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addCartTemplateItemsToCartTemplate(
                $input: AddCartTemplateItemsToCartTemplateInput!
              ) {
                addCartTemplateItemsToCartTemplate(input: $input) {
                  success
                  successItemCount
                  errorCode
                  errorMessage
                  __typename
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (response?.data?.addCartTemplateItemsToCartTemplate.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddCartTemplateItemsToCartTemplate,
              id: `${action.input.targetCartTemplateId}-${NotificationType.AddCartTemplateItemsToCartTemplate}`,
              options: {
                successItemCount: response.data.addCartTemplateItemsToCartTemplate.successItemCount,
                targetTemplateName: action.targetTemplateName,
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.addCartTemplateItemsToCartTemplate
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              addCartTemplateItemsToCartTemplateGraphQLResult(
                result.success,
                result.successItemCount
              )
            )
          ),
          catchError((error) => {
            handleError(error)
            return of(
              addCartTemplateItemsToCartTemplateGraphQLResult(false, 0, error.message, error.code)
            )
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(
          addCartTemplateItemsToCartTemplateGraphQLResult(false, 0, error.message, error.code)
        )
      })
    )
}
export const addOrderItemsToCartTemplates = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddOrderItemsToCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.AddOrderItemsToCartTemplateGraphQL),
      concatMap((action: AddOrderItemsToCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addOrderItemsToCartTemplate($input: AddOrderItemsToCartTemplateInput!) {
                addOrderItemsToCartTemplate(input: $input) {
                  success
                  successItemCount
                  errorCode
                  errorMessage
                  __typename
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (
            response?.data?.addOrderItemsToCartTemplate.success &&
            response?.data?.addOrderItemsToCartTemplate.successItemCount ===
              action.selectedItemCount
          ) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddOrderItemsToCartTemplate,
              id: `${action.input.cartTemplateId}-${NotificationType.AddOrderItemsToCartTemplate}`,
              options: {
                successItemCount: response.data.addOrderItemsToCartTemplate.successItemCount,
                targetTemplateName: action.cartTemplateName,
              },
            })
          } else {
            // Note: We are merely assuming that some (or all) items were not successful due to being diverse products or cancelled order items
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.OrderAddDiverseArticlesToCartTemplateWarning,
              id: `${action.input.orderId}-${NotificationType.OrderAddDiverseArticlesToCartTemplateWarning}`,
              options: {
                body: '',
                heading: '',
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.addCartTemplateItemsToCartTemplate
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              addCartTemplateItemsToCartTemplateGraphQLResult(
                result.success,
                result.successItemCount
              )
            )
          ),
          catchError((error) => {
            handleError(error)
            return of(
              addCartTemplateItemsToCartTemplateGraphQLResult(false, 0, error.message, error.code)
            )
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(
          addCartTemplateItemsToCartTemplateGraphQLResult(false, 0, error.message, error.code)
        )
      })
    )
}

export const addOfferToCartTemplateGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddOfferToCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.AddOfferToCartTemplateGraphQL),
      concatMap((action: AddOfferToCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addOfferToCartTemplate($input: CartTemplateAddOfferInput!) {
                addOfferToCartTemplate(input: $input) {
                  success
                  errorCode
                  errorMessage
                  successItemCount
                  __typename
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (
            response?.data?.addOfferToCartTemplate.success &&
            response?.data?.addOfferToCartTemplate.successItemCount === action.selectedItemCount
          ) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.AddOfferToCartTemplate,
              id: `${action.input.cartTemplateId}-${NotificationType.AddOfferToCartTemplate}`,
              options: {
                offerName: action.offerName,
                cartTemplateName: action.cartTemplateName,
              },
            })
          } else {
            // Note: We are merely assuming that some (or all) items were not successful due to being diverse products.
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.OfferDiverseProductsCartTemplate,
              id: `${action.input.cartTemplateId}-${NotificationType.OfferDiverseProductsCartTemplate}`,
              options: {
                body: '',
                heading: '',
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.addOfferToCartTemplate
        }).pipe(
          retry(1),
          mergeMap(() => of(addOfferToCartTemplateGraphQLResult(true))),
          catchError((error) => {
            handleError(error)
            return of(addOfferToCartTemplateGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        handleError(error)
        return of(addOfferToCartTemplateGraphQLResult(false, error))
      })
    )
}

export const createAddArticleToTemplateGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<AddArticleToTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.AddArticleToTemplateGraphQL),
      concatMap((action: AddArticleToTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          // The variable is needed for the user notification
          const addedItemIds: string[] = []
          const notAddedCustomItemIds: string[] = []

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation addProductToTemplate($input: CartTemplateAddItemsInput!) {
                addTemplateItems(input: $input) {
                  responseModels {
                    success
                    errorCode
                    errorMessage
                  }
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          // Check for successfully added items
          response?.data?.addTemplateItems.responseModels.forEach((itemResult, index) => {
            const productId = action.input.itemsToAdd[index].productId
            if (itemResult.success) {
              addedItemIds.push(productId)
            } else {
              notAddedCustomItemIds.push(productId)
            }
          })

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          // trigger render of NotificationTemplateAdd if at least one item was added successfully
          if (!action.omitNotification && addedItemIds.length > 0) {
            let id = action.input.templateId[0]
            addedItemIds.forEach((addedItemId) => {
              id += `-${addedItemId}`
            })
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.TemplateAdd,
              id: id,
              options: {
                itemCount: addedItemIds.length,
                templateName: action.templateName,
              },
            })
          }

          // trigger render of notification if one or more custom items (aka 'diverser artikel') were blocked by backend
          if (notAddedCustomItemIds.length > 0) {
            let id = action.input.templateId[0]
            notAddedCustomItemIds.forEach((notAddedCustomItemId) => {
              id += `-${notAddedCustomItemId}`
            })
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.CustomProductToTemplateAdd,
              id: id,
              options: {
                itemCount: notAddedCustomItemIds.length,
                templateName: action.templateName,
              },
            })
          }

          return response.data.updateShoppingCartMetaData
        }).pipe(
          retry(1),
          mergeMap((result: CartTemplate) => of(AddArticleToTemplateGraphQLResult())),
          catchError((error) => {
            error.message =
              'error while processing ' +
              createAddArticleToTemplateGraphQLEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(AddArticleToTemplateGraphQLResult(undefined, error))
          })
        )
      ),
      catchError((error) => {
        error.message =
          'error in ' + createAddArticleToTemplateGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(AddArticleToTemplateGraphQLResult(undefined, error))
      })
    )
}

export const deleteTemplateGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DeleteCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.DeleteCartTemplateGraphQL),
      concatMap((action: DeleteCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation deleteTemplate($input: CartTemplateDeleteInput!) {
                deleteTemplate(input: $input) {
                  success
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (response?.data?.deleteTemplate.success) {
            // trigger render of NotificationTemplateDelete if template was deleted successfully
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteCartTemplateSingle,
              id: `${action.input.templateId}-${NotificationType.DeleteCartTemplateSingle}`,
              options: {
                templateName: action.templateName,
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.deleteTemplate
        }).pipe(
          retry(1),
          mergeMap(() => of(DeleteCartTemplateGraphQLResult(true))),
          catchError((error) => {
            error.message =
              'error while handling ' + deleteTemplateGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(DeleteCartTemplateGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + deleteTemplateGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(DeleteCartTemplateGraphQLResult(false, error))
      })
    )
}

//@deprecated Please use deleteCartTemplateItemsGraphQL instead
export const deleteTemplateItemsGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DeleteCartTemplateItemsGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.DeleteCartTemplateItemsGraphQL),
      concatMap((action: DeleteCartTemplateItemsGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })
          let productTitle = ''
          if (action.singleDelete) {
            const cartTemplateItem = action.cartTemplate.cartTemplateItems?.items?.find((item) => {
              return item.id === action.itemIds[0]
            })
            productTitle = (cartTemplateItem && cartTemplateItem.product.title) ?? ''
          }

          let itemIds = action.itemIds

          // 'Merkliste leeren' - delete all items from cart template
          if (action.emptyCartTemplate) {
            itemIds = []
          }

          const backupTemplate = await db.cartsv2.findOne(action.cartTemplate.id).exec()
          const optimisticallyUpdatedCartTemplate: CartTemplateForDetailsPage = {
            ...action.cartTemplate,
          }
          if (!action?.cartTemplate?.cartTemplateItems?.items?.length) {
            return
          }
          if (!optimisticallyUpdatedCartTemplate.cartTemplateItems) {
            return
          }
          const optimisticallyUpdatedItems: MaybeCompleteCartTemplateDetailsItem[] =
            action.cartTemplate?.cartTemplateItems?.items.filter(
              (item: MaybeCompleteCartTemplateDetailsItem) => !itemIds.includes(item.id)
            )
          optimisticallyUpdatedCartTemplate.cartTemplateItems.items = optimisticallyUpdatedItems
          await db.carttemplates.upsert(optimisticallyUpdatedCartTemplate)

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation deleteTemplateItems($input: CartTemplateDeleteItemsInput!) {
                deleteTemplateItems(input: $input) {
                  success
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: {
                templateId: action.cartTemplate.id,
                itemIds: itemIds,
              },
            },
          })

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          const syncedCartTemplate = await db.carttemplates.findOne(action.cartTemplate.id).exec()
          const cartTemplateEmpty = syncedCartTemplate.itemCount === 0

          if (response?.data?.deleteTemplateItems.success && action.singleDelete) {
            // trigger render of NotificationDeleteCartTemplateItemSingle if template was deleted successfully
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DeleteCartTemplateItemSingle,
              id: `${action.cartTemplate.id}-${NotificationType.DeleteCartTemplateSingle}`,
              options: {
                cartTemplateEmpty: cartTemplateEmpty,
                cartTemplateTitle: action.cartTemplate.name,
                productTitle: productTitle,
              },
            })
          } else {
            await db.carttemplates.upsert(backupTemplate)
          }

          response.data = response.data.deleteTemplateItems
          response.data.cartTemplateEmpty = cartTemplateEmpty
          return response.data
        }).pipe(
          retry(1),
          mergeMap((result: DeleteCartTemplateItemsGraphQLResultAction) =>
            of(DeleteCartTemplateItemsGraphQLResult(result.cartTemplateEmpty ?? true, true))
          ),
          catchError((error) => {
            error.message =
              'error while handling ' + deleteTemplateItemsGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(DeleteCartTemplateItemsGraphQLResult(false, false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + deleteTemplateItemsGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(DeleteCartTemplateItemsGraphQLResult(false, false, error))
      })
    )
}

export const deleteCartTemplateItemsGraphQLBatchEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DeleteCartTemplateItemsGraphQLActionBatch>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.DeleteCartTemplateItemsGraphQLBatch),
      concatMap((action: DeleteCartTemplateItemsGraphQLActionBatch) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation deleteCartTemplateItems($input: DeleteCartTemplateItemsInput!) {
                deleteCartTemplateItems(input: $input) {
                  success
                  errorCode
                  errorMessage
                  successItemCount
                }
              }
            `,
            variables: {
              input: {
                cartTemplateId: action.cartTemplateId,
                include: action.include,
                exclude: action.exclude,
                search: action.search,
              },
            },
          })

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.deleteCartTemplateItems
        }).pipe(
          retry(1),
          mergeMap((result: DeleteCartTemplateItemsGraphQLResultActionBatch) =>
            of(
              DeleteCartTemplateItemsGraphQLResultBatch(
                result.success,
                result.successItemCount,
                result.errorCode ?? '',
                result.errorMessage ?? ''
              )
            )
          ),
          catchError((error) => {
            error.message =
              'error while handling ' +
              deleteCartTemplateItemsGraphQLBatchEffect.name +
              ' ' +
              error.message
            handleError(error)
            return of(
              DeleteCartTemplateItemsGraphQLResultBatch(false, 0, error.code, error.message, error)
            )
          })
        )
      ),
      catchError((error) => {
        error.message =
          'error in ' + deleteCartTemplateItemsGraphQLBatchEffect.name + ' ' + error.message
        handleError(error)
        return of(
          DeleteCartTemplateItemsGraphQLResultBatch(false, 0, error.code, error.message, error)
        )
      })
    )
}

export const updateTemplateGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<UpdateCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.UpdateCartTemplateGraphQL),
      concatMap((action: UpdateCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation updateTemplate($input: CartTemplateUpdateInput!) {
                updateTemplate(input: $input) {
                  success
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (response?.data?.updateTemplate.success && action.input.patch.isShared !== undefined) {
            if (action.input.patch.isShared) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.CartTemplateVisibilitySetToPublic,
                id: `${action.input.cartTemplateId}-${NotificationType.CartTemplateVisibilitySetToPublic}`,
                options: {
                  templateName: action.templateName,
                },
              })
            } else if (!action.input.patch.isShared) {
              getEventSubscription().next({
                type: EventType.Toast,
                notificationType: NotificationType.CartTemplateVisibilitySetToPrivate,
                id: `${action.input.cartTemplateId}-${NotificationType.CartTemplateVisibilitySetToPrivate}`,
                options: {
                  templateName: action.templateName,
                },
              })
            }
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })
          return response.data.updateTemplate
        }).pipe(
          retry(1),
          mergeMap((result: UpdateCartTemplateGraphQLResultAction) =>
            of(
              UpdateCartTemplateGraphQLResult(result.success, result.errorCode, result.errorMessage)
            )
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + updateTemplateGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(UpdateCartTemplateGraphQLResult(false, '', '', error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + updateTemplateGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(UpdateCartTemplateGraphQLResult(false, '', '', error))
      })
    )
}

const createCreateNewTemplateGqlEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<CreateNewTemplateGqlAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.CreateNewTemplateGql),
      concatMap((action: CreateNewTemplateGqlAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate<{
            createTemplate: {
              success: boolean
              errorCode: string
              errorMessage: string
              templateId: string
            }
          }>({
            mutation: CREATE_NEW_TEMPLATE,
            variables: {
              input: {
                name: action.payload.name,
              },
            },
          })

          if (!response.data) {
            throw new Error('Response of createNewTemplateGql call is empty')
          }

          if (response.data.createTemplate.success) {
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.CartTemplateCreated,
              id: `${action.payload.name}-${NotificationType.CartTemplateCreated}`,
              options: {
                cartTemplateName: action.payload.name,
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })
          return response.data.createTemplate
        }).pipe(
          retry(1),
          mergeMap((result) =>
            of(
              createNewTemplateGqlResult(
                result.success,
                result?.templateId,
                result?.errorCode,
                result.errorMessage
              )
            )
          ),
          catchError((error) => {
            return defer(async () => {
              await changeMetaData(db, 'carttemplates', { isUpdating: false })
              error.message =
                'error in ' + createCreateNewTemplateGqlEpic.name + ' ' + error.message
              handleError(error)
              return of(createNewTemplateGqlResult(false, '', error.code ?? '', error.message))
            })
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createCreateNewTemplateGqlEpic.name + ' ' + error.message
        handleError(error)
        return of(createNewTemplateGqlResult(false, '', error.code ?? '', error.message))
      })
    )
}

export const duplicateTemplateGraphQLEffect = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<DuplicateCartTemplateGraphQLAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.DuplicateCartTemplateGraphQL),
      concatMap((action: DuplicateCartTemplateGraphQLAction) =>
        defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.mutate({
            mutation: gql`
              mutation duplicateTemplate($input: CartTemplateDuplicateInput!) {
                duplicateTemplate(input: $input) {
                  success
                  errorCode
                  errorMessage
                }
              }
            `,
            variables: {
              input: action.input,
            },
          })

          if (response?.data?.duplicateTemplate.success) {
            // trigger render of NotificationTemplateDuplicate if template was duplicated successfully
            getEventSubscription().next({
              type: EventType.Toast,
              notificationType: NotificationType.DuplicateCartTemplateSingle,
              id: `${action.input.cartTemplateId}-${NotificationType.DuplicateCartTemplateSingle}`,
              options: {
                templateName: action.templateName,
              },
            })
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })

          return response.data.duplicateTemplate
        }).pipe(
          retry(1),
          mergeMap((result: DuplicateCartTemplateGraphQLResultAction) =>
            of(DuplicateCartTemplateGraphQLResult(true))
          ),
          catchError((error) => {
            error.message =
              'error while processing ' + duplicateTemplateGraphQLEffect.name + ' ' + error.message
            handleError(error)
            return of(DuplicateCartTemplateGraphQLResult(false, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + duplicateTemplateGraphQLEffect.name + ' ' + error.message
        handleError(error)
        return of(DuplicateCartTemplateGraphQLResult(false, error))
      })
    )
}

export const getCartTemplateItemProducts = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetCartTemplateItemProductsAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.GetCartTemplateItemProductsGraphQL),
      switchMap((action: GetCartTemplateItemProductsAction) => {
        return defer(async () => {
          if (!action.items || action.items.length === 0) {
            action.handleResult()
            return
          }
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const backupCartTemplate = { ...action.cartTemplate }
          const response = await apolloClient.query<
            GetCartTemplateListItemPricesAndStocksQuery,
            GetCartTemplateListItemPricesAndStocksQueryVariables
          >({
            query: gql`
              query getCartTemplateListItemPricesAndStocks(
                $sapIds: [String!]!
                $storeIds: [String!]!
              ) {
                getProducts(sapIds: $sapIds) {
                  sapId
                  unit
                  priceDimension
                  minimumAmount
                  isTopseller
                  stock(warehouseIds: $storeIds) {
                    sapId
                    warehouseId
                    amount
                    unit
                  }
                  prices {
                    currency
                    netPrice
                    listPrice
                    strikeThroughPrice
                    tecSelect
                  }
                }
              }
            `,
            variables: {
              sapIds: action.items.map(({ productId }) => productId),
              storeIds: action.storeIds,
            },
          })

          response.data.getProducts.forEach((productFromResponse) => {
            if (!backupCartTemplate?.cartTemplateItems?.items) {
              throw new Error(
                "Something went wrong, there was an attempt to fetch product data for items, which don't exist anymore"
              )
            }

            const matchingIndexes: number[] = []

            // Note: When dealing with Schnittware (aka isCutProduct), different items can share the same sapId!
            const foundIndexes = backupCartTemplate.cartTemplateItems.items
              .map((item, index) => ({ item, index })) // Indexe beibehalten
              .filter(({ item }) => item.productId === productFromResponse.sapId)
              .map(({ index }) => index)
            matchingIndexes.push(...foundIndexes)

            matchingIndexes.forEach((itemIndex) => {
              if (itemIndex >= 0) {
                const backupItem = backupCartTemplate.cartTemplateItems.items[itemIndex]
                const backupProduct = backupItem.product
                backupCartTemplate.cartTemplateItems.items[itemIndex] = {
                  ...backupCartTemplate.cartTemplateItems.items[itemIndex],
                  product: {
                    ...backupProduct,
                    unit: productFromResponse.unit,
                    priceDimension: productFromResponse.priceDimension,
                    minimumAmount: productFromResponse.minimumAmount,
                    prices: {
                      ...((isCompleteCartTemplateDetailsItem(backupItem) &&
                        backupItem.product.prices) ||
                        {}),
                      currency: productFromResponse.prices?.currency ?? '',
                      netPrice: productFromResponse.prices?.netPrice ?? 0,
                      listPrice: productFromResponse.prices?.listPrice ?? 0,
                      strikeThroughPrice: productFromResponse.prices?.strikeThroughPrice ?? 0,
                    },
                    stock: productFromResponse.stock,
                  },
                } as MaybeCompleteCartTemplateDetailsItem
              }
            })
          })
          await db.carttemplates.upsert(backupCartTemplate)
          await changeMetaData(db, 'carttemplates', { isUpdating: false })
          action.handleResult()
        }).pipe(
          retry(1),
          switchMap(() => of(GetShoppingCartPricesGraphQLResult())),
          catchError((error) => {
            action.handleResult()
            error.message =
              'error while processing ' + getCartTemplateItemProducts.name + ' ' + error.message
            handleError(error)
            return of(GetShoppingCartPricesGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getCartTemplateItemProducts.name + ' ' + error.message
        handleError(error)
        return of(GetShoppingCartPricesGraphQLResult())
      })
    )
}

export const mapCartTemplateProductToItemImages = ({
  cartTemplate,
  cartTemplateUpdate,
}: {
  cartTemplate: CartTemplate
  cartTemplateUpdate?: CartTemplateImagesUpdate
}): CartTemplate | null => {
  if (!cartTemplateUpdate) return null
  const cartTemplateToBackup = { ...cartTemplate }

  if (cartTemplateToBackup?.cartTemplateItems?.items?.length) {
    let didSomeGetUpdated = false
    cartTemplateToBackup.cartTemplateItems?.items?.forEach((backupItem) => {
      const matchingItem = cartTemplateUpdate.cartTemplateItems.items.find(
        (cartTemplateItemUpdate) =>
          backupItem.id === cartTemplateItemUpdate.id &&
          backupItem.product.sapId === cartTemplateItemUpdate.product.sapId
      )
      // replace with correct type
      if (!matchingItem?.product.imageData || !backupItem.product.imageData) return
      didSomeGetUpdated = true
      if (backupItem.product.imageData) {
        backupItem.product.imageData.images = matchingItem.product?.imageData?.images ?? []
      }
    })
    if (didSomeGetUpdated) return cartTemplateToBackup
    return null
  } else {
    if (!cartTemplateToBackup.cartTemplateItems) {
      cartTemplateToBackup.cartTemplateItems = { items: [] }
    }
    cartTemplateToBackup.cartTemplateItems.items = cartTemplateUpdate.cartTemplateItems
      .items as CartTemplateItem[]
    return cartTemplateToBackup
  }
}

export const getCartTemplateItemImages = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$) =>
    actions$.pipe(
      ofType(TemplateActionTypes.GetCartTemplateItemImagesGraphQL),
      catchError((error) => {
        error.message =
          'error while checking type for GetCartTemplateItemImagesGraphQL in ' +
          getCartTemplateItemImages.name +
          ' ' +
          error.message
        handleError(error)
        return of(GetCartTemplateItemImagesGraphQLResult())
      }),
      switchMap((action: GetCartTemplateItemImagesAction) => {
        return defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdatingItemImages: true })

          const response = await apolloClient.query<GetCartTemplateItemImagesResponse>({
            query: gql`
              query getCartTemplatesById($input: GetCartTemplatesByIdInput!) {
                getCartTemplatesById(input: $input) {
                  id
                  cartTemplateItems(limit: 7) {
                    items {
                      id
                      product {
                        sapId
                        images {
                          url
                          width
                        }
                        imageData {
                          images {
                            large
                          }
                        }
                        oxomiId
                        supplierId
                      }
                    }
                  }
                }
              }
            `,
            variables: {
              input: {
                templateIds: action.cartTemplates.map((cartTemplate) => cartTemplate.id),
              },
            },
          })
          const cartTemplateUpserts = action.cartTemplates
            .map((cartTemplate) => {
              const cartTemplateUpdate = response.data.getCartTemplatesById.find(
                (responseTemplate) => responseTemplate.id === cartTemplate.id
              )
              return mapCartTemplateProductToItemImages({ cartTemplate, cartTemplateUpdate })
            })
            .filter((el) => !!el) as CartTemplate[]
          await db.carttemplates.bulkUpsert(cartTemplateUpserts)

          await changeMetaData(db, 'carttemplates', { isUpdatingItemImages: false })
        }).pipe(
          retry(1),
          switchMap(() => of(GetCartTemplateItemImagesGraphQLResult())),
          catchError(async (error) => {
            error.message =
              'error while processing ' + getCartTemplateItemImages.name + ' ' + error.message
            handleError(error)
            await changeMetaData(db, 'carttemplates', { isUpdatingItemImages: false })
            return of(GetCartTemplateItemImagesGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getCartTemplateItemImages.name + ' ' + error.message
        handleError(error)
        return of(GetCartTemplateItemImagesGraphQLResult())
      })
    )
}

export const getCartTemplateItems = (
  db: RxDatabase<CollectionsOfDatabase>,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return (actions$: Observable<GetCartTemplateItemsAction>) =>
    actions$.pipe(
      ofType(TemplateActionTypes.GetCartTemplateItemsGraphQL),
      catchError((error) => {
        error.message =
          'error while checking type for GetCartTemplateItemsGraphQL in ' +
          getCartTemplateItems.name +
          ' ' +
          error.message
        handleError(error)
        return of(GetCartTemplateItemsGraphQLResult())
      }),
      switchMap((action: GetCartTemplateItemsAction) => {
        return defer(async () => {
          await changeMetaData(db, 'carttemplates', { isUpdating: true })

          const response = await apolloClient.query<GetCartTemplateItemsResponse>({
            query: CART_TEMPLATE_LIST_ITEMS,
            variables: {
              input: {
                templateIds: [action.cartTemplate.id],
              },
              ...action.options,
            },
          })
          const templateData = response.data.getCartTemplatesById[0]
          const itemsToWrite =
            action.options.offset !== 0 ? action.cartTemplate.cartTemplateItems.items : []
          if (templateData) {
            await db.carttemplates.upsert({
              ...action.cartTemplate,
              cartTemplateItems: {
                resultsCount: templateData.cartTemplateItems.resultsCount,
                items: [...itemsToWrite, ...templateData.cartTemplateItems.items],
              },
            })
            action.handleResult()
          }

          await changeMetaData(db, 'carttemplates', { isUpdating: false })
        }).pipe(
          retry(1),
          switchMap(() => of(GetCartTemplateItemImagesGraphQLResult())),
          catchError(async (error) => {
            action.handleResult()
            error.message =
              'error while processing ' + getCartTemplateItems.name + ' ' + error.message
            handleError(error)
            await changeMetaData(db, 'carttemplates', { isUpdating: false })
            return of(GetCartTemplateItemsGraphQLResult())
          })
        )
      }),
      catchError((error) => {
        error.message = 'error in ' + getCartTemplateItems.name + ' ' + error.message
        handleError(error)
        return of(GetCartTemplateItemsGraphQLResult())
      })
    )
}

export const initAllTemplateEpics = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase,
  getCollection$: GetCollection$,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  return [
    addCartTemplatesToCartTemplates(db, apolloClient),
    addCartTemplateItemsToCartTemplates(db, apolloClient),
    addOrderItemsToCartTemplates(db, apolloClient),
    addOfferToCartTemplateGraphQLEffect(db, apolloClient),
    createNextAction(db, syncDataWithDatabase, getCollection$),
    createAddArticleToTemplateGraphQLEffect(db, apolloClient),
    deleteTemplateGraphQLEffect(db, apolloClient),
    deleteTemplateItemsGraphQLEffect(db, apolloClient),
    deleteCartTemplateItemsGraphQLBatchEffect(db, apolloClient),
    duplicateTemplateGraphQLEffect(db, apolloClient),
    createCreateNewTemplateGqlEpic(db, apolloClient),
    updateTemplateGraphQLEffect(db, apolloClient),
    getCartTemplateItemProducts(db, apolloClient),
    getCartTemplateItemImages(db, apolloClient),
    getCartTemplateItems(db, apolloClient),
    updateCartTemplateItemAmountGraphQLEffect(db, apolloClient),
  ]
}
