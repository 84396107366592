import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import {
  createUserAddressGraphQL,
  CreateUserAddressGraphQLResultAction,
  deleteUserAddressGraphQL,
  updateCartGraphQL,
  updateUserAddressGraphQL,
  UpdateUserAddressGraphQLResultAction,
  UserActionTypes,
} from '../../actions'
import { UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import { useShoppingCartById } from '../useShoppingCartById'
import { useUserDataV2 } from '../useUserDataV2'
import { useEntities } from '../useEntities'
import { useActionNotification } from '../useActionNotification'
import { StoreV2 } from '@obeta/models/lib/models'
import { ShippingType } from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'

export const useAddressActions = () => {
  const { user } = useUserDataV2()
  const cart = useShoppingCartById(user?.settings?.defaultCartId ?? '')
  const defaultStoreId = user?.settings?.defaultStoreId
  const selectedStoreId = cart?.shippingData.storeId ?? defaultStoreId ?? ''
  const stores = useEntities<StoreV2>('storesv2')

  let store = stores.find((store) => store.id === selectedStoreId)
  if (!store) {
    store = stores.find((store) => store.id === defaultStoreId)
  }
  const userAddresses = useEntities<UserAddressV2>('useraddressesv2')

  const [selectedDeliveryAddressId, setSelectedDeliveryAddressId] = useState(
    cart?.shippingData.addressId
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (cart?.shippingData.addressId) {
      setSelectedDeliveryAddressId(cart?.shippingData.addressId)
    }
  }, [cart])

  const waitForCreateUserAddressResultAction = useActionNotification(
    UserActionTypes.CreateUserAddressGraphQLResult
  )
  const waitForUpdateUserAddressResultAction = useActionNotification(
    UserActionTypes.UpdateUserAddressGraphQLResult
  )
  const createUserAddress = useCallback(
    (address: AddressV2, customName: string) => {
      dispatch(createUserAddressGraphQL(address, customName))
      waitForCreateUserAddressResultAction((action: CreateUserAddressGraphQLResultAction) => {
        if (action.success && action.addressId) {
          setSelectedDeliveryAddressId(action.addressId)
        }
      })
    },
    [dispatch, waitForCreateUserAddressResultAction]
  )

  const deleteUserAddress = useCallback(
    (addressId: string) => {
      dispatch(deleteUserAddressGraphQL(addressId))
    },
    [dispatch]
  )

  const updateUserAddress = useCallback(
    (addressId: string, address: AddressV2, customName: string) => {
      dispatch(updateUserAddressGraphQL(addressId, address, customName))
      waitForUpdateUserAddressResultAction((action: UpdateUserAddressGraphQLResultAction) => {
        if (action.success) {
          setSelectedDeliveryAddressId(addressId)
        }
      })
    },
    [dispatch, waitForUpdateUserAddressResultAction]
  )

  const updateDeliveryAddress = useCallback(
    (deliveryAddress: DeliveryAddressV2, addressId?: string | undefined) => {
      if (cart) {
        cart.shippingData.deliveryAddress = deliveryAddress
        // Note that userAddresses all have addressIds, while addresses edited via ShoppingCartShippingForm don't
        if (addressId !== undefined) {
          cart.shippingData.addressId = addressId
          cart.shippingData.storeId = ''
          setSelectedDeliveryAddressId(addressId)
        }
        dispatch(updateCartGraphQL(cart))
      }
    },
    [cart, dispatch]
  )
  const onSelectUserAddressId = useCallback(
    (addressId: string) => {
      const selectedDeliveryAddress = userAddresses.find(
        (userAddress) => userAddress.addressId === addressId
      )
      if (selectedDeliveryAddress) {
        updateDeliveryAddress(selectedDeliveryAddress.address, selectedDeliveryAddress.addressId)
      }
    },
    [updateDeliveryAddress, userAddresses]
  )

  const selectStoreId = (storeId: string) => {
    if (cart) {
      cart.shippingData.storeId = storeId
      cart.shippingData.shippingType = ShippingType.DefaultPickup
      dispatch(updateCartGraphQL(cart))
    }
  }
  return {
    createUserAddress,
    updateUserAddress,
    updateDeliveryAddress,
    deleteUserAddress,
    onSelectUserAddressId,
    selectedDeliveryAddressId,
    store,
    selectedStoreId,
    selectStoreId,
  }
}
