import { SimpleShoppingCart } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'

export enum SimpleCartActionTypes {
  LoadSimpleCarts = '[SimpleCarts] Load SimpleCarts',
  LoadSimpleCartsResult = '[SimpleCarts] Load SimpleCart Result',
}

interface SimpleCartAction {
  type: SimpleCartActionTypes
}

export interface LoadSimpleCartsAction extends SimpleCartAction {
  readonly type: SimpleCartActionTypes.LoadSimpleCarts
}

export const loadSimpleCarts = (): LoadSimpleCartsAction => ({
  type: SimpleCartActionTypes.LoadSimpleCarts,
})

export interface LoadSimpleCartsResultAction extends SimpleCartAction {
  readonly type: SimpleCartActionTypes.LoadSimpleCartsResult
  simpleCarts?: SimpleShoppingCart[]
  error?: Error
}

export const loadSimpleCartsResult = (
  simpleCarts?: SimpleShoppingCart[],
  error?: Error
): LoadSimpleCartsResultAction => ({
  type: SimpleCartActionTypes.LoadSimpleCartsResult,
  simpleCarts,
  error,
})

export type SimpleCartActionsUnion = LoadSimpleCartsAction | LoadSimpleCartsResultAction
