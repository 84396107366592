import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'
import { ProductAggregate } from '@obeta/schema'

export const productsEntity: EntityDescriptor<
  Omit<RemoteSortedItem<ProductAggregate>, 'filtered'>
> = {
  name: 'products',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'products',
    version: 2,
    description: 'a document representing a product',
    type: 'object',
    indexes: ['sortOrder'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          sapId: {
            type: 'string',
          },
          description: {
            type: 'string',
          },
          prices: {
            type: 'object',
          },
          stock: {
            type: 'object',
          },
          eeValue: {
            type: 'string',
          },
          imageData: {
            type: 'object',
          },
          supplierImageData: {
            type: 'object',
          },
          minimumAmount: {
            type: 'number',
          },
          ring: {
            type: 'string',
          },
          statusText: {
            type: 'string',
          },
          isBlackListed: {
            type: 'boolean',
          },
          packagingUnit: {
            type: 'number',
          },
          shoppingCart: {
            type: ['object', 'null'],
          },
        },
      },
    },
  },
  subscription: null,
}
