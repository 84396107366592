import { ShoppingCart } from '@obeta/models/lib/models'
import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

export const cartsEntity: EntityDescriptor<ShoppingCart> = {
  name: 'carts',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'carts',
    version: 2,
    description: 'a document representing a cart',
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        maxLength: 100,
        type: 'string',
      },
      name: {
        type: 'string',
      },
      orderNumber: {
        type: 'number',
      },
      active: {
        type: 'object',
      },
      flags: {
        type: 'object',
      },
      prices: {
        type: 'object',
      },
      deliveryDates: {
        type: 'array',
      },
      articleList: {
        type: 'array',
      },
      backLogs: {
        type: 'number',
      },
      messages: {
        type: 'object',
      },
      tecselectValue: {
        type: ['string', 'null'],
      },
    },
  },
  subscription: null,
}
