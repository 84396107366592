import { Order } from '@obeta/models/lib/models'
import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'

export const ordersEntity: EntityDescriptor<Omit<RemoteSortedItem<Order>, 'filtered'>> = {
  name: 'orders',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'orders',
    version: 2,
    description: 'a document representing a order',
    type: 'object',
    indexes: ['sortOrder'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          orderNo: {
            type: 'string',
          },
          offerNo: {
            type: ['string', 'null'],
          },
          deliveryTypeText: {
            type: 'string',
          },
          deliveryDate: {
            type: 'string',
          },
          address: {
            type: 'object',
          },
          additionalText: {
            type: ['string', 'null'],
          },
          commission: {
            type: 'string',
          },
          prices: {
            type: 'object',
          },
          orderDate: {
            type: 'string',
          },
          orderStatus: {
            type: 'string',
          },
          clerkName: {
            type: ['string', 'null'],
          },
          positions: {
            type: 'array',
          },
        },
      },
    },
  },
  subscription: null,
}
