import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

import { UserNotification } from '@obeta/schema'

interface ICheckpoint {
  since?: string
  lastId?: string
}

const emptyCheckpoint: ICheckpoint = {
  since: undefined,
  lastId: undefined,
}

const createCheckpoint = (doc: UserNotification): ICheckpoint => ({
  lastId: doc.id,
  since: doc.updatedAt,
})

export const messageEntity: EntityDescriptor<
  Omit<
    UserNotification & { updatedAtNumber: number; createdAtNumber: number },
    '__typename' | 'userId'
  >,
  ICheckpoint
> = {
  name: 'message',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'message',
    version: 2,
    description: 'a document representing a message',
    type: 'object',
    indexes: ['updatedAt', 'updatedAtNumber', 'status', 'createdAt', 'createdAtNumber'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      event: {
        type: 'string',
      },
      data: {
        type: 'object',
      },
      status: {
        type: 'string',
        maxLength: 100,
      },
      updatedAt: {
        type: 'string',
        maxLength: 100,
      },
      updatedAtNumber: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      createdAt: {
        type: 'string',
        maxLength: 100,
      },
      createdAtNumber: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
    },
  },
  subscription: null,
  pullSync: {
    modifier: (doc) => {
      doc.deleted = doc.status === 'deleted'
      doc.updatedAtNumber = Date.parse(doc.updatedAt)
      doc.createdAtNumber = Date.parse(doc.createdAt)
      return doc
    },
    queryBuilder: (checkpoint) => {
      checkpoint = checkpoint || emptyCheckpoint

      const query = `query GetNotifications($since: String, $lastId: String) {
        getUserNotifications(since: $since, lastId: $lastId) {
          id
          event
          data {
            ... on OrderCreated {
              cartId
              customerId
              subCustomerId
              sapCompanyId
              orderId
              offerId
              legacyProjectId
              type
            }
            ... on OrderFailed {
              cartId
              customerId
              subCustomerId
              sapCompanyId
              legacyProjectId
              type
            }
            ... on OrderIdMissingInResponse {
              cartId
              customerId
              subCustomerId
              sapCompanyId
              reason
              legacyProjectId
              type
            }
            ... on CartNotified {
              cartId
              customerId
              subCustomerId
              sapCompanyId
              legacyProjectId
              type
            }
          }
          status
          updatedAt
          createdAt
        }
      }`

      return {
        query,
        variables: { ...checkpoint },
      }
    },
    responseModifier: (
      documents: (UserNotification & {
        updatedAtNumber: number
        createdAtNumber: number
        _deleted: boolean
      })[],
      _,
      requestCheckpoint = emptyCheckpoint
    ) => ({
      checkpoint:
        documents.length === 0
          ? requestCheckpoint
          : createCheckpoint(documents[documents.length - 1]),
      documents: documents,
    }),
  },
  authentication: true,
}
