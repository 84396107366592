import { ReturnShipment } from '@obeta/models/lib/models'
import { EntityDescriptor, RemoteSortedItem } from '@obeta/models/lib/models/Db/index'

export const returnShipmentsEntity: EntityDescriptor<
  Omit<RemoteSortedItem<ReturnShipment>, 'filtered'>
> = {
  name: 'returnshipments',
  migrationStrategies: {
    1: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    2: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  schema: {
    title: 'returnshipments',
    version: 2,
    description: 'a document representing a returnShipment',
    type: 'object',
    indexes: ['sortOrder'],
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100,
      },
      sortOrder: {
        type: 'number',
        multipleOf: 1,
        minimum: 0,
        maximum: 100000,
      },
      item: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          clerkName: {
            type: 'string',
          },
          returnNo: {
            type: 'string',
          },
          returnDate: {
            type: 'string',
          },
          returnPos: {
            type: 'string',
          },
          returnVal: {
            type: 'object',
          },
          returnStatusText: {
            type: 'string',
          },
          returnStatusId: {
            type: 'number',
          },
          articleData: {
            type: 'array',
          },
        },
      },
    },
  },
  subscription: null,
}
