import { useState, useEffect, useMemo } from 'react'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'

import { ArticleRing, ArticleRingShort } from '@obeta/models/lib/models/Article/ArticleListItem'
import { ArticleSearchParams } from '@obeta/models/lib/models/Search'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'
import { ProductAggregate, TypeToRingMap } from '@obeta/models/lib/models/Article/Shop/Product'

import { PriceResponse } from './useSellPrice'
import { gql, useApolloClient } from '@apollo/client'
import { buildFilterParams } from '@obeta/utils/lib/search/buildSearchFilter'
import { useWarehouseContext } from '../stores/useWarehouseContext'

interface ArticleData {
  articleId: string
  amount: number
  price?: number
  priceIsFetching?: boolean
  article?: MaybeCompleteSearchProduct
}

const searchQuery = gql`
  query searchProductsToImportIntoCart(
    $searchString: String
    $hitsPerPage: Int
    $filter: String
    $page: Int
  ) {
    searchProducts(
      searchString: $searchString
      requestOptions: { hitsPerPage: $hitsPerPage, filters: $filter, page: $page }
    ) {
      nbHits
      queryId
      products {
        title
        sapId
        articleDescription
        imageData {
          sapId
          images {
            large
          }
        }
        images {
          width
          url
        }
        supplierImageData {
          large
        }
        isDeleted
        isForSale
        isSendable
        isDiscontinued
        priceDimension
        minimumAmount
        oxomiId
        obetaId
        dehaId
        unit
        type
        stock {
          amount
          unit
        }
        isTopseller
        prices {
          strikeThroughPrice
          catalogPrice
          netPrice
          tecSelect
          currency
        }
      }
    }
  }
`

export const useArticleImport = (articleIdsRaw: string, amountsRaw: string): ArticleData[] => {
  const articleIds = articleIdsRaw ? articleIdsRaw.split(',') : []
  const amounts = useMemo(() => (amountsRaw ? amountsRaw.split(',') : []), [amountsRaw])
  const data: ArticleData[] = articleIds.map(
    (id, idx): ArticleData => ({
      articleId: id,
      amount: parseInt(amounts[idx], 10),
    })
  )

  const client = useApolloClient()

  const [articleData, setArticleData] = useState<ArticleData[]>(data)
  const { warehouseId } = useWarehouseContext()

  useEffect(() => {
    if (!articleIdsRaw || !amountsRaw) {
      setArticleData([])
      return
    }

    const articleIds = articleIdsRaw.split(',')
    const amounts = amountsRaw.split(',')
    const searchString = articleIds.join('+or+')
    const searchParams: ArticleSearchParams = {
      searchString,
      obetaCategory: { id: '0', name: '' },
    }

    const data: ArticleData[] = articleIds.map(
      (id, idx): ArticleData => ({
        articleId: id,
        amount: parseInt(amounts[idx], 10),
      })
    )
    setArticleData(data)

    const doSearch = async () => {
      /*Sentry.addBreadcrumb({
        category: 'cart import',
        message: 'Start to search articles',
        data: { articleIds, amounts },
      })*/ // TODO replace former Sentry-Code with DataDog

      const response = await client.query({
        query: searchQuery,
        variables: {
          searchString: searchParams.searchString,
          hitsPerPage: 100,
          filter: buildFilterParams(searchParams, warehouseId),
          page: 0,
        },
      })

      const result = response.data.searchProducts

      if (result.nbHits > 60) {
        /*Sentry.addBreadcrumb({
          category: 'cart import',
          message: 'More than 60 results found. Might skip articles due to paging',
          data: { hitCount: result.nbHits },
        })*/
        // TODO replace former Sentry-Code with DataDog
      }

      const targetArticles: MaybeCompleteSearchProduct[] = result.products.filter(
        (e: ProductAggregate) => articleIds.findIndex((a: string) => a === e.obetaId) !== -1
      )
      let matches = 0
      targetArticles.forEach((article) => {
        const art = data.find((artData) => artData.articleId === article.obetaId)
        if (art) {
          // FIXME: data was used to update react sate. you cannot mutate data!
          art.article = article
          matches++
        }
      })

      /*Sentry.addBreadcrumb({
        category: 'cart import',
        message: `Found ${matches} article with requested IDs`,
      })*/ // TODO replace former Sentry-Code with DataDog

      if (matches > 0) {
        setArticleData(([] as ArticleData[]).concat(data))
      }
    }
    doSearch()
  }, [articleIdsRaw, amountsRaw, client, warehouseId])

  useEffect(() => {
    let pricesRequested = false

    articleData.forEach((data, idx) => {
      if (data.article && !data.price && !data.priceIsFetching) {
        pricesRequested = true
        data.priceIsFetching = true
        const article = data.article
        let ringShort: ArticleRingShort = ArticleRingShort.Lagerartikel

        if (TypeToRingMap[article.type] === ArticleRing.Lagerartikel) {
          ringShort = ArticleRingShort.Lagerartikel
        }
        if (TypeToRingMap[article.type] === ArticleRing.Sonderbestellung) {
          ringShort = ArticleRingShort.Sonderbestellung
        }
        const sub = Axios.request({
          url:
            'article/' + article.obetaId + '/price?amount=' + amounts[idx] + '&ring=' + ringShort,
        }).subscribe((response: AxiosResponse<PriceResponse>) => {
          const price = parseFloat(
            response.data.data.price.toString().replace('.', '').replace(',', '.')
          )
          data.price = price
          data.priceIsFetching = false
          setArticleData(([] as ArticleData[]).concat(articleData))
        })

        return () => {
          sub.unsubscribe()
        }
      }
    })

    if (pricesRequested) {
      setArticleData(([] as ArticleData[]).concat(articleData))
    }
  }, [articleData, amounts])

  return articleData
}
