import { bootstrapDatabase, getCollection$, syncDataWithDatabase } from '../bootstrap'
import { boostrapRootEpic } from '../bootstrapEpics'
import { createEpicMiddleware } from 'redux-observable'
import { applyMiddleware, createStore } from 'redux'
import { CollectionsOfDatabase, RxDatabase, RxError } from 'rxdb'
import { IDbUtils } from '@obeta/models/lib/models/Db'
import { useEffect, useState } from 'react'
import { getCollectionSync, replication$, getCollectionSync$ } from '../bootstrap'
import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import { hasWindow } from '@obeta/utils/lib/ssr'
import { initAxios } from '../axiosClient'
import { isAuthenticationErrorSync } from '@obeta/utils/lib/isAuthenticationError'
import { initAppActions } from '../bootstrapAppActions'
import { AppActions, Tokens } from '@obeta/models/lib/models/BusinessLayer/AppActions'
import { initApollo } from '../apolloClient'
import { SecureStoragePlugin } from '@atroo/capacitor-secure-storage-plugin'
import { Subject } from 'rxjs'
import { GraphQLError } from 'graphql'
import { datadogRum } from '@datadog/browser-rum'

export const useShopBootstrap = (
  multiInstance: boolean,
  graphqlUrl?: string,
  ignoreDuplicate?: boolean
) => {
  const [db, setDb] = useState<RxDatabase<CollectionsOfDatabase> | null>(null)
  const [dbUtils, setDbUtils] = useState<IDbUtils | null>(null)
  const [store, setStore] = useState<ReturnType<typeof createStore> | null>(null)
  const [appActions, setAppActions] = useState<AppActions | null>(null)
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)

  useEffect(() => {
    if (!hasWindow()) {
      return
    }

    const epicMiddleware = createEpicMiddleware()
    const store = createStore(() => {
      //
    }, applyMiddleware(epicMiddleware))
    setStore(store)

    Promise.all([
      bootstrapDatabase(multiInstance, ignoreDuplicate),
      SecureStoragePlugin.get({ key: 'auth' })
        .catch((err) => {
          return Promise.resolve({ value: null })
        })
        .then(({ value }) => {
          let authObj: Tokens | null = null
          if (value) {
            authObj = JSON.parse(value)
          }

          return authObj
        }),
    ]).then(async ([dbInst, tokens]) => {
      // TODO: batch state
      const userDoc = await dbInst.getLocal('userv2')
      if (!userDoc) {
        await dbInst.insertLocal('userv2', {})
      }
      const actions = initAppActions(dbInst, tokens, store)
      setAppActions(actions)
      const apollo = initApollo(
        process.env.NEXT_PUBLIC_GRAPHQL_HOST as string,
        actions,
        false,
        dbInst
      )
      setApolloClient(apollo)

      setDb(dbInst)
      const errorHandler = (err: RxError) => {
        if (!Array.isArray(err?.parameters?.errors)) {
          datadogRum.addError(err)
          return
        }

        const accessDeniedError = err.parameters.errors.find((errObj) => {
          /** errObj can actually be a GraphQLError */
          return isAuthenticationErrorSync(errObj as unknown as GraphQLError)
        })
        if (accessDeniedError) {
          actions.requestNewToken()
        } else {
          datadogRum.addError(err)
        }
      }

      actions.tokens$.subscribe((tokens) => {
        replication$.next({
          type: 'start',
          baseUrl: graphqlUrl,
          token: tokens?.accessToken,
          entities: [
            'cartsv2',
            'carttemplates',
            'storesv2',
            'offersv2',
            'useraddressesv2',
            'openpositions',
          ],
          errorHandler,
        })
      })

      const { rootEpic, epic$ } = boostrapRootEpic(dbInst, apollo, getCollectionSync)
      const dbUtils = {
        getCollection$,
        epicInit: epic$,
        syncDataWithDatabase,
        getCollectionSync,
        getCollectionSync$,
      }
      epicMiddleware.run(rootEpic)
      setDbUtils(dbUtils)
      const maintenanceModeSubject: Subject<string> = new Subject()
      initAxios(dbInst, maintenanceModeSubject, actions)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiInstance, ignoreDuplicate, graphqlUrl])

  return {
    db,
    dbUtils,
    store,
    appActions,
    apolloClient,
  }
}
