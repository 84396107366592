import { EventType, NotificationType, getEventSubscription } from './pubSub'
import { ArticleAddOptions } from '@obeta/models/lib/models/Article/ArticleListItem'

export const toastNotifier = async (msg: string, opts?: ArticleAddOptions) => {
  getEventSubscription().next({
    type: EventType.Toast,
    notificationType: NotificationType.Toast,
    id: 'generic toast notification',
    options: {
      message: msg,
      opts,
    },
  })
}
