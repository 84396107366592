import { CollectionsOfDatabase, RxDatabase, RxDocument } from 'rxdb'
import { EntityNames } from '@obeta/models/lib/models/Db/index'
import { MetaDataInput, MetaData, MetaDataWithId } from '@obeta/models/lib/models/Meta/Meta'

export const initialMeta: MetaData = {
  id: '',
  isStub: true,
  isFetching: false,
  isUpdating: false,
  mustRefetch: false,
  lastUpdated: undefined,
  hitCount: -1,
  pagination: {
    currentPage: 1,
    maxPage: -1,
    itemsPerPage: 10,
  },
  searchParams: {
    searchString: '',
    startDate: undefined,
    endDate: undefined,
    sortOption: '',
    supplier: undefined,
  },
  affectedItem: undefined,
}

export const getMetaDataStub = () => {
  const nextMeta = { ...initialMeta }
  delete nextMeta.isStub

  return nextMeta
}

export const changeMetaData = async (
  db: RxDatabase,
  name: string,
  toChange: Partial<MetaDataInput>
): Promise<MetaData> => {
  const collection = db.entitymeta
  const doc: RxDocument<MetaData> = await collection
    .findOne({
      selector: {
        id: name,
      },
    })
    .exec()

  if (!doc) {
    throw new Error(`Meta doc is not found! ${name}`)
  }

  /**
   * incrementalModify can introduce race conditions
   * incrementalModify always works as expected and ensures you always receive fresh data
   * Make sure to sure incrementalModify whenever possible
   */
  const updatedData = await doc.incrementalModify((oldData) => {
    /**
     * Make sure upserted data awlays has id
     */
    const data: MetaData = { ...oldData, id: name }

    const keys = Object.keys(toChange) as Array<keyof MetaDataInput>
    for (const key of keys) {
      // TODO: integrate a safeguard here to filter out any potential isStub keys
      // isStub is currently used to mark situations where not the real data is returned
      // from useMetaData, but stubdata
      // this must be refactored and until then isStub is the way to detect those stub objects
      // nevertheless we must make sure to never save this as it will break rxdb schema validation
      if (key === 'isStub') {
        continue
      }
      if (typeof toChange[key] === 'object' && !Array.isArray(toChange[key])) {
        data[key as string] = Object.assign({}, data[key], toChange[key])
      } else {
        data[key as string] = toChange[key]
      }
    }

    return data
  })

  return updatedData.toMutableJSON()
}

export const getMetaData = async (db: RxDatabase<CollectionsOfDatabase>, name: string) => {
  const collection = db.entitymeta
  const doc = await collection
    .findOne({
      selector: {
        id: name,
      },
    })
    .exec()
  return doc && doc.toMutableJSON()
}

export const bootstrapInitialMetaDataDocs = async (
  db: RxDatabase<CollectionsOfDatabase>,
  entityNames: EntityNames[],
  forceNew?: boolean
) => {
  const metaDocs = (await db.entitymeta.find().exec()) as RxDocument<MetaData>[]
  const changes: MetaDataWithId[] = []
  entityNames.forEach((name: EntityNames) => {
    const metaData = metaDocs.find((doc) => doc.id === name)
    if (!metaData || forceNew) {
      const initialMeta = getMetaDataStub()
      if (name === 'articles') {
        initialMeta.pagination = { ...initialMeta.pagination, itemsPerPage: 32 }
      }
      changes.push({ ...initialMeta, id: name })
    } else {
      const json = metaData.toMutableJSON(true)
      json.isFetching = false
      json.isUpdating = false
      changes.push(json)
    }
  })
  const proms: Promise<unknown>[] = []
  if (changes.length > 0) {
    proms.push(db.entitymeta.bulkUpsert(changes))
  }
  return Promise.all(proms)
}
