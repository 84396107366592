import { Store } from '@obeta/models/lib/models'
import { EntityDescriptor } from '@obeta/models/lib/models/Db/index'

export const stores: EntityDescriptor<Omit<Store, 'distance'>> = {
  name: 'stores',
  schema: {
    title: 'stores',
    version: 3,
    description: 'a document representing a store',
    type: 'object',
    primaryKey: 'lagernr',
    properties: {
      lagernr: {
        type: 'string',
        maxLength: 100,
      },
      versandnr: {
        type: 'string',
      },
      name1: {
        type: 'string',
      },
      name2: {
        type: 'string',
      },
      company: {
        type: ['number', 'string'],
      },
      street: {
        type: 'string',
      },
      postcode: {
        type: 'string',
      },
      city: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      fax: {
        type: 'string',
      },
      whatsapp: {
        type: 'string',
      },
      lat: {
        type: 'string',
      },
      long: {
        type: 'string',
      },
      opening_hours: {
        type: 'array',
      },
      opening_hours_formatted: {
        type: 'array',
      },
      isRenzBox: {
        type: 'boolean',
      },
    },
  },
  migrationStrategies: {
    1: function (oldDoc) {
      oldDoc.isRenzBox = false
      return oldDoc
    },
    2: function (oldDoc) {
      // migration to rxdb 12
      return oldDoc
    },
    3: function (oldDoc) {
      // RxDB v12 -> v13 migration
      return oldDoc
    },
  },
  subscription: null,
}
