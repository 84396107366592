import { Store } from '@obeta/models/lib/models/Stores/store'

export enum StoreActionTypes {
  LoadStores = '[Stores] Load Stores',
  LoadStoresResult = '[Stores] Load Stores Result',
}

interface StoreAction {
  type: StoreActionTypes
}

export interface LoadStoresAction extends StoreAction {
  readonly type: StoreActionTypes.LoadStores
}

export const loadStores = (): LoadStoresAction => ({
  type: StoreActionTypes.LoadStores,
})

export interface LoadStoresResultAction extends StoreAction {
  readonly type: StoreActionTypes.LoadStoresResult
  stores?: Store[]
  error?: Error
}

export const loadStoresResult = (stores?: Store[], error?: Error): LoadStoresResultAction => ({
  type: StoreActionTypes.LoadStoresResult,
  stores,
})

export type StoreActionsUnion = LoadStoresAction | LoadStoresResultAction
