import { useEffect, useRef } from 'react'

export function useIntervalInActiveTab<P extends () => void>(
  callback: P,
  { interval, lead }: { interval: number; lead?: boolean }
): void {
  const savedCallback = useRef<P | null>(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) savedCallback.current()
    }

    lead && tick()

    if (interval !== null) {
      let id = setInterval(tick, interval)
      const onVisibilitychange = () => {
        if (!document.hidden) {
          clearInterval(id)
          if (savedCallback.current) savedCallback.current()
          id = setInterval(tick, interval)
        } else {
          clearInterval(id)
        }
      }

      document.addEventListener('visibilitychange', onVisibilitychange)

      return () => {
        clearInterval(id)
        document.removeEventListener('visibilitychange', onVisibilitychange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval])
}
