export const WWW_URL =
  process.env.REACT_APP_OBETA_BASE_URL || process.env.NEXT_PUBLIC_OBETA_HOST || ''

export const API_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://staging-app-obeta-de.obeta.io/'

export const PRINT_SERVICE_URL =
  process.env.NEXT_PUBLIC_PRINT_SERVICE || process.env.REACT_APP_PUBLIC_PRINT_SERVICE

/**
 * stores overview contains a link to download the "filialnetz"
 */
export const STORENET_URL =
  process.env.REACT_APP_STORENET_URL || process.env.NEXT_PUBLIC_STORENET_URL || ''
/**
 * temporary constants to integrate the classic shop for pages, that
 * are not available in the new stack yet
 */
export const OBETA_IFRAME_URL = process.env.NEXT_PUBLIC_OBETA_IFRAME_URL as string
export const ELDIS_IFRAME_URL = process.env.NEXT_PUBLIC_ELDIS_IFRAME_URL as string
export const HAEUSLER_IFRAME_URL = process.env.NEXT_PUBLIC_HAEUSLER_IFRAME_URL as string

export const AUTH_BASE_URL =
  process.env.REACT_APP_HYDRA_BASE_URL || process.env.NEXT_PUBLIC_HYDRA_BASE_URL
export const APP_ID =
  process.env.REACT_APP_HYDRA_CLIENT_ID || process.env.NEXT_PUBLIC_HYDRA_CLIENT_ID
export const ANDROID_REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL_ANDROID || process.env.NEXT_PUBLIC_REDIRECT_URL_ANDROID
export const IOS_REDIRECTURL =
  process.env.REACT_APP_REDIRECT_URL_IOS || process.env.NEXT_PUBLIC_REDIRECT_URL_IOS

/**
 * websocket configuration
 */
export const NOTIFICATION_HOST =
  process.env.NEXT_PUBLIC_NOTIFICATION_HOST || process.env.REACT_APP_NOTIFICATION_HOST
export const NOTIFICATION_PATH =
  process.env.NEXT_PUBLIC_NOTIFICATION_PATH || process.env.REACT_APP_NOTIFICATION_PATH

/**
 * algolia configuration
 */
export const ALGOLIA_INDEX_NAME =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || process.env.REACT_APP_ALGOLIA_INDEX_NAME
export const ALGOLIA_REPLICA_INDEX_NAME =
  process.env.NEXT_PUBLIC_ALGOLIA_REPLICA_INDEX_NAME ||
  process.env.REACT_APP_ALGOLIA_REPLICA_INDEX_NAME
export const ALGOLIA_APPLICATION_ID =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || process.env.REACT_APP_ALGOLIA_APPLICATION_ID
export const ALGOLIA_SEARCH_API_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY

export const IMGPROXY_URL =
  process.env.NEXT_PUBLIC_IMGPROXY_URL ||
  process.env.REACT_APP_IMGPROXY_URL ||
  process.env.IMGPROXY_URL

export function OBETA_WWW_URL(path: string) {
  return OBETA_URL(path, WWW_URL)
}

export function OBETA_API_URL(path: string) {
  return OBETA_URL(path, API_URL)
}
function OBETA_URL(path: string, baseurl: string) {
  // remove leading slash from string
  if (path.startsWith('/')) {
    path = path.substring(1)
  }
  return `${baseurl}${path}`
}

export const STRAPI_GRAPHQL_URL =
  process.env.REACT_APP_STRAPI_GRAPHQL_HOST ||
  (process.env.NEXT_PUBLIC_STRAPI_GRAPHQL_HOST as string)
