export interface Metal {
  name: string
  price: string
}

export interface MetalV2 {
  metalType: MetalTypes
  price: number
}

export enum MetalTypes {
  Lead = 'Lead',
  Brass = 'Brass',
  Silver = 'Silver',
  Aluminium = 'Aluminium',
  Copper = 'Copper',
  MKCopper = 'MKCopper',
}
