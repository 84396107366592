import { articlesEntity } from './articles'
import { cartsEntity } from './carts'
import { categoriesEntity } from './categories'
import { offersEntity } from './offers'
import { ordersEntity } from './orders'
import { remainingOrdersEntity } from './remainingOrders'
import { returnShipmentsEntity } from './returnShipments'
import { simpleCartsEntity } from './simpleCarts'
import { simpleOffersEntity } from './simpleOffers'
import { stores } from './stores'
import { messageEntity } from './message'
import { markedCartsEntity } from './markedForNotificationCarts'
import { cartsv2Entity } from './cartsv2'
import { productsEntity } from './products'
import { offersv2Entity } from './offersv2'
import { cartTemplatesEntity } from './cartTemplates'
import { storesv2Entity } from './storesv2'
import { EntityDescriptor, ObetaModels } from '@obeta/models/lib/models/Db/index'
import { stocksEntity } from './stocks'
import { userAddressesV2Entity } from './userAddressesv2'
import { cartsv2PricesEntity } from './cartsv2Prices'
import { cartTemplateProductsEntity } from './cartTemplateProducts'
import { openPositionsEntity } from './openPositions'
import { clientEntity } from './client'

export * from './articles'
export * from './carts'
export * from './categories'
export * from './client'
export * from './entityMetaData'
export * from './offers'
export * from './orders'
export * from './remainingOrders'
export * from './returnShipments'
export * from './simpleCarts'
export * from './simpleOffers'
export * from './stores'
export * from './message'
export * from './markedForNotificationCarts'
export * from './cartsv2'
export * from './cartTemplates'
export * from './cartTemplateProducts'
export * from './products'
export * from './offersv2'
export * from './openPositions'
export * from './storesv2'
export * from './stocks'
export * from './userAddressesv2'
export * from './cartsv2Prices'

export const syncEntities: EntityDescriptor[] = [
  cartsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  ordersEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  returnShipmentsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  remainingOrdersEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  articlesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  simpleCartsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  stores as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  categoriesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  simpleOffersEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  offersEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  messageEntity as unknown as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  markedCartsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartsv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartTemplatesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartTemplateProductsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  productsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  offersv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  storesv2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  stocksEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  userAddressesV2Entity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen,
  cartsv2PricesEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
  openPositionsEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
  clientEntity as EntityDescriptor<ObetaModels>, //todo this thing is oddly type, the interface is missing in the graph gen
]
