import { gql, useQuery } from '@apollo/client'
import { ProductAggregate, Stock } from '@obeta/models/lib/models/Article/Shop/Product'
import type { IProductIds } from './useCurrentProductIds'
import { useWarehouseContext } from '../stores/useWarehouseContext'

const PRODUCT_AGGREGATE = gql`
  query getStock($sapIds: [String!], $obetaIds: [String!], $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds, obetaIds: $obetaIds) {
      id
      sapId
      stock(warehouseIds: $warehouseIds) {
        sapId
        warehouseId
        amount
        unit
      }
    }
  }
`

const defaultStocks: Stock[] = []

export const useWarehouse = (
  productIds: IProductIds,
  ids: ('main' | string | undefined)[] = [],
  isLoggedIn: boolean,
  skip?: boolean
): Stock[] => {
  const { warehouseId } = useWarehouseContext()
  const warehouseIds = ids
    .filter((id) => id)
    .map((id) => {
      return id === 'main' ? warehouseId : id
    })

  const { data } = useQuery<{ getProducts: ProductAggregate[] }>(PRODUCT_AGGREGATE, {
    variables: { ...productIds, warehouseIds: warehouseIds },
    skip: skip || !warehouseIds.length || !isLoggedIn,
  })

  return (
    data?.getProducts?.[0]?.stock?.sort((a, b) => (a.warehouseId === '10' ? -1 : 1)) ||
    defaultStocks
  )
}
