import { map, switchMap, catchError, retry, concatMap, Observable } from 'rxjs'
import { StoreActionTypes, LoadStoresAction, loadStoresResult } from '../actions'
import { handleError } from '@obeta/utils/lib/datadog.errors'
import { changeMetaData } from '@obeta/utils/lib/epics-helpers'
import { of, from, defer } from 'rxjs'
import { SyncDataWithDatabase } from '@obeta/models/lib/models/Db/index'
import { Store } from '@obeta/models/lib/models/Stores/store'
import { ofType } from 'redux-observable'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'
import { CollectionsOfDatabase, RxDatabase } from 'rxdb'

export interface StoresResponse {
  data: Store[]
  messages: [
    {
      type: string
      message: string
    }
  ]
}

export const createLoadStoresEpic = (
  db: RxDatabase<CollectionsOfDatabase>,
  syncDataWithDatabase: SyncDataWithDatabase
) => {
  return (actions$: Observable<LoadStoresAction>) =>
    actions$.pipe(
      ofType(StoreActionTypes.LoadStores),
      switchMap((action: LoadStoresAction) =>
        from(changeMetaData(db, 'stores', { isFetching: true })).pipe(
          switchMap((res) =>
            Axios.request({
              url: 'v2/stores/',
            }).pipe(
              retry(1),
              concatMap((response: AxiosResponse<StoresResponse>) => {
                const newStores: Store[] = response.data.data

                return defer(async () => {
                  await syncDataWithDatabase('stores', newStores, 'lagernr')
                  await changeMetaData(db, 'stores', { isFetching: false })
                }).pipe(map(() => loadStoresResult(response.data.data)))
              })
            )
          ),
          catchError((error) => {
            error.message =
              'error while handling ' + createLoadStoresEpic.name + ' ' + error.message
            handleError(error)
            return of(loadStoresResult(undefined, error))
          })
        )
      ),
      catchError((error) => {
        error.message = 'error in ' + createLoadStoresEpic.name + ' ' + error.message
        handleError(error)
        return of(loadStoresResult(undefined, error))
      })
    )
}
